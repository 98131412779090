import React from "react";

import SimpleInput from "../../../../inputs/simpleInput/simpleInput";
import MainDropList from "../../../../inputs/mainDropList/mainDropList";

import * as Style from '../style'

const Question = ({connect, data, handleChange, handleChangeResult, onDelete, deleteDisabled}) => {
  return (
    <Style.Question>
      {
        connect
          ? (
            <SimpleInput
              className={'task-input_head'}
              type={'text'}
              name={'start'}
              required={true}
              defaultValue={data.start}
            />
          )
          : (
            <SimpleInput
              className={'task-input_head'}
              handleChange={handleChange}
              type={'text'}
              name={'start'}
              required={true}
              defaultValue={data.question}
            />
          )
      }
      {
        connect
          ? (
            <SimpleInput
              className={'task-input_head'}
              type={'text'}
              name={'end'}
              required={true}
              defaultValue={data.end}
            />
          )
          : (
            <MainDropList
              classes={'task-input_head'}
              name={'result'}
              required={true}
              width={170}
              onChange={handleChangeResult}
              defaultId = {data.result}
              options={
                [
                  {value: 'TRUE', name: 'True'},
                  {value: 'FALSE', name: 'False'},
                  {value: 'NOT STATED', name: 'Not Stated'}
                ]
              }
            />
          )
      }
      <div onClick={!deleteDisabled ? onDelete : () => {}} className={`delete ${deleteDisabled ? 'delete_disabled' : ''}`}/>
    </Style.Question>
  )
}

export default Question;
