import React, { useState } from 'react';

import Popup from '../popupContainer/popupContainer';
import Button from "../../buttons/button/button";
import InputText from "../../inputs/inputsAdmin/inputText/inputText";

import { PopupTitle } from '../popupContainer/popupStyled';

const PopupLesson = ({type, lessonName, func, close}) => {
  const [ name, setName ] = useState( lessonName || "" );
  const sendData = (e) => {
    e.preventDefault();
    func(name);
  }

  return (
    <Popup onClose={close}>
      <PopupTitle>
        {`${type === 'add' ? 'Создать' : 'Переименовать'} тему`}
      </PopupTitle>
      <form>
        <InputText
          placeholder = "Название темы"
          value = { name }
          set = { setName }
        />
        <Button
          type="add-popup"
          text={`${type === "add" ? 'Создать' : 'Переименовать'} тему`}
          func={sendData}
        />
      </form>
    </Popup>
  );
}

export default PopupLesson;
