import React from 'react';
import {connect} from "react-redux";
import PopupLesson from '../popupLesson';

import axiosInstance from "../../../../service/iTeacherApi";

const PopupAddLesson = ({user, course, updateCourse, close}) => {
  const addLesson = async (name) => {
    try {
      const data = {name, course}
      await axiosInstance.post(`/${user.type.toLowerCase()}/lessons/`, data);
      updateCourse(course)
      close();
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <PopupLesson
      type={'add'}
      func={addLesson}
      close={close}
    />
  );
}

const mapStateToProps = ({users}) => {
  return {
    user: users.user
  }
};

export default connect(mapStateToProps)(PopupAddLesson);
