import React from "react";

import {CheckedWrap} from "./styled";

import successIcon from '../../../assets/media/icon/succes_task.svg';
import failureIcon from '../../../assets/media/icon/failure_task.svg';
import closed from "../../../assets/media/icon/close.svg";

const CheckedBlock = ({userType, completed, handleCheck, closeFalseChecked, children, isSimple}) => {
  return (
    <CheckedWrap status={completed}>
      {completed ? (
        <>
          {completed === '#24A1481A'  ? (
            <>
              <img className={'icon'} src={successIcon} alt="иконка успешной проверки"/>
              <p className={'text'}>Задание выполнено{isSimple ? '' : ' правильно'}!</p>
            </>
          ) : (
            <>
              <img className={'icon'} src={failureIcon} alt="иконка не успешной проверки"/>
              <p className={'text'}>Задание выполнено неправильно!</p>
              <img onClick={closeFalseChecked} className={'closed'} src={closed} alt="icon"/>
            </>
          )}
        </>
      ) : (
        userType !== 'teacher' ? (
          <>
            {children}
            <button className={'btn__check'} onClick={handleCheck}>{isSimple ? 'Отметить как выполненное' : 'Проверить задание'}</button>
          </>
        ) : (
          <p>Задание ожидает выполнения</p>
        )
      )}
    </CheckedWrap>
  )
}

export default CheckedBlock
