import styled from "styled-components";
import copyIcon from "../../../assets/media/icon/copy.svg"
import {baseTheme} from "../../../baseTheme";

export const LeftSideBarWrap = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-right: 24px;
  width: 318px;
  max-width: 318px;
  border-radius: 8px;
  scrollbar-width: none;
  height: 100%;

  &::-webkit-scrollbar {
    display: none;
  }

  .lesson__info-container {
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    overflow-y: auto;
    width: 100%;
    margin-top: 24px;
  }

  button {
    display: none;
  }

  @media ${baseTheme.media.large}, ${baseTheme.media.heightMedium} {
    height: 238px;
    width: 100%;
    max-width: unset;
    flex-direction: row;
    align-items: start;
    margin-right: 0;
    margin-bottom: 16px;

    .lesson__info-container {
      height: 238px;
      margin-top: 0;
      margin-left: 16px;
      justify-content: flex-end;
    }
  }

  @media ${baseTheme.media.medium}, ${baseTheme.media.heightMedium} {
    position: absolute;
    top: 0;
    left: 0;
    flex-direction: column;
    z-index: 100000;
    border-radius: 8px;
    width: 72px;
    height: 96px;
    min-height: 96px;
    transition-duration: 0ms;
    cursor: pointer;
    touch-action: none;

    @media ${baseTheme.media.heightSmall} {
      flex-direction: row;
    }

    &.drag {
      box-shadow: 0 0 5px 5px #697077;
    }

    & .video-container {
      width: 72px;
      height: 96px;
      min-height: 96px;
    }

    .lesson__info-container {
      display: none;
    }

    .video-btn {
      display: none;
    }

    .your_video {
      display: none;
    }

    &.open {
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: auto;
      height: auto;
      margin: 0;
      padding: 24px 16px;
      border-radius: 0;
      background: ${baseTheme.colors.bgLightGrey};

      & .lesson__info-container {
        display: flex;
        flex-grow: 1;
        height: 100%;
        overflow: hidden;
        margin-left: 0;
        margin-top: 16px;

        @media ${baseTheme.media.heightSmall} {
          margin-left: 16px;
          margin-top: 0;
        }

        button {
          display: block;
          margin-top: 8px;
        }
      }

      & .video-container {
        width: 100%;
        height: 100%;
        min-height: 60vh;

        @media ${baseTheme.media.heightSmall} {
          width: 318px;
          min-width:  318px;
        }
      }

      & .video-btn {
        display: flex;
      }

      & .your_video {
        display: block;
      }
    }
  }
`

export const CompanionWrap = styled.div`
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 0px 2px rgba(105, 112, 119, 0.06), 0px 2px 2px rgba(105, 112, 119, 0.12);
  border-radius: 8px;
  padding: 10px 16px;
  margin-bottom: 24px;

  @media ${baseTheme.media.large} {
    margin-bottom: 8px;
  }
`
