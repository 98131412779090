import styled from "styled-components";
import {baseTheme} from "../../../baseTheme";

const AddCourse = styled.div`
  width: 100%;

  .addSquare {
    display: flex;
    width: 204px;
    height: 204px;
    border: 2px solid #4F7FFF;
    box-sizing: border-box;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    img {
      width: 48px;
      height: 48px;
      min-width: 48px;
      object-fit: contain;
      object-position: center;
    }
    
  }

  .textAddCourse {
    margin-top: 16px;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: #4F7FFF;
    cursor: pointer;
  }

  @media ${baseTheme.media.medium} {
    .addSquare {
      width: 156px;
      height: 156px;
    }
  }

  @media ${baseTheme.media.extraSmall} {
    .addSquare {
      width: 100%;
    }
  }
`

export default AddCourse;
