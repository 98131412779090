import React, {useState} from "react";
import {connect} from "react-redux";
import MediaPlayer from "../../../../components/mediaPlayer/mediaPlayer";
import {Video} from "./styled";
import micro from "../../../../assets/media/image/voice.svg";
import cam from "../../../../assets/media/image/cam.svg";


const VideoBlock = ({ localVideoTrack, remoteUsers, toggleLocalTracksMute, onOpenModal}) => {

  const [isAudioMuted, setAudioMute] = useState(false);
  const [isVideoMuted, setVideoMute] = useState(false);

  const toggleAudioMute = () => {
    toggleLocalTracksMute(isAudioMuted, setAudioMute, false);
  }

  const toggleVideoMute = () => {
    toggleLocalTracksMute(isVideoMuted, setVideoMute, true);
  }

  return (
    <Video onClick={onOpenModal} className={"video-container"}>
      <MediaPlayer videoTrack={localVideoTrack} audioTrack={undefined} your={true}></MediaPlayer>
      {remoteUsers.map(user => (<div className='remote-player-wrapper' key={user.uid}>
        <MediaPlayer videoTrack={user.videoTrack} audioTrack={user.audioTrack}></MediaPlayer>
      </div>))}
      <div className="video-btn">
        <div
          className="micro"
          onClick={toggleAudioMute}
          style={{opacity: isAudioMuted ? 1 : 0.5}}
        >
          <img src={micro} alt="icon"/>
        </div>
        <div
          className="cam"
          onClick={toggleVideoMute}
          style={{opacity: isVideoMuted ? 1 : 0.5}}
        >
          <img src={cam} alt="icon"/>
        </div>
      </div>
    </Video>
  )
}


const mapStateToProps = (state) => {
  return {
    user: state.users.user,
  };
};

export default connect(mapStateToProps)(VideoBlock);
