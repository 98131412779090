const initState = {
  tasks: []
}

export const ADD_CHECKED_TASK = 'ADD_CHECKED_TASK';

const tasksCheckedReducer = (state = initState, action) => {
  switch (action.type) {
    case ADD_CHECKED_TASK:
      return {
        ...state,
        tasks: [...state.tasks.filter(i => i.task_id !== action.payload.task_id), action.payload],
      }

    default:
      return state
  }
}

export default tasksCheckedReducer;
