import React, {useState} from 'react';
import {InputWrap} from "../personalDataStyled";


const InputDay =  ({defaultDay}) => {
  const [day, setDay] = useState(defaultDay)
  const changeInput = (e) => {
    let { value, min, max } = e.target;
    value = Math.max(Number(min), Math.min(Number(max), Number(value)));
    setDay(value);
  };

    return (
      <InputWrap className={'day'}>
        <label className={'labelDay active'} >Число</label>
        <input className={'input'} name={'day'} value={day} min={0} max={31}  type="number"  onChange={changeInput}/>
      </InputWrap>
    )
}

export default InputDay
