import React, {Component} from "react";
import Dropzone from 'react-dropzone';

import Button from "../../../buttons/button/button";
import MainButton from "../../../buttons/mainButton/mainButton";
import AdminModalTask from "../../adminModalTask/adminModalTask";
import MainInput from "../../../inputs/mainInput/mainInput";

import {AudioWrap} from './styled';
import {ImageZone} from "../image/styled";
import {FormBtnContainer, FormHeadContainer, FormMainContainer, FormWrap} from "../../styled";

import dlt from '../../../../assets/media/icon/trash_basket.svg'


export default class AddAudioModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      text: '',
      name: '',
      file: '',
      audioPreview: '',
      deleteAudio: false,
      type: 'add',
      taskId: '',
      file_size: '',
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.file = React.createRef();
    }, 1000)
    this.setTypeModal();
  }

  // проверяем модалка на создания или редактирование
  setTypeModal = () => {
    const {edit} = this.props;
    if (!!edit.id) {
      this.setState({
        type: 'edit',
        text: edit.title,
        file: edit.file,
        file_size: edit.file_size,
        name: edit.name_video,
        taskId: edit.id,
      });
    }
  }

  createTask = (e) => {
    e.preventDefault();
    const file_size = this.state.file.size < 1000000 ? Math.round(this.state.file.size / 1024) + " kb" : Math.round(this.state.file.size / 1024 / 1024) + " mb";

    const data = new FormData();
    data.set("task_type", "AUDIO");
    data.set("title", this.state.text);
    data.set("file", this.state.file);
    data.set("name_video", this.state.name);
    data.set("file_size", file_size);

    this.props.addTask(data);
  }

  // изминения задания
  editTask = (e) => {
    e.preventDefault();
    const file_size = this.state.file.size < 1000000 ? Math.round(this.state.file.size / 1024) + " kb" : Math.round(this.state.file.size / 1024 / 1024) + " mb";

    const data = new FormData();
    data.set("task_type", "AUDIO");
    data.set("title", this.state.text);

    if (this.props.edit.file !== this.state.file) data.set("file", this.state.file);
    data.set("name_video", this.state.name);
    if (this.props.edit.file !== this.state.file) data.set("file_size", file_size);

    this.props.editTask(this.state.taskId, data);
  }

  DeleteAudioItem = () => {
    this.setState(() => {
      return {
        ...this.state,
        deleteAudio: true
      }
    });
    setTimeout(() => {
      this.setState(() => {
        return {
          ...this.state,
          deleteAudio: false,
          file: '',
          audioPreview: '',
        }
      })
    }, 500)
  }

  // add audio file

  _handleAudioChange(e) {
    e.preventDefault();

    let reader = new FileReader();

    let file = e.target.files[0];

    reader.onloadend = () => {

      this.setState({

        file: file,

        audioPreview: reader.result

      });

    }

    reader.readAsDataURL(file)

  }

  uploadAudio(audio) {
    let reader = new FileReader();
    let file = audio[0];
    reader.onloadend = () => {
      this.setState({
        file: file,
        audioPreview: reader.result
      });
    }
    reader.readAsDataURL(file);
  }

  render() {
    const {back, close} = this.props;

    const {audioPreview, file, deleteAudio} = this.state;

    // code for download audio file
    let $audioPreview = null;

    if (audioPreview || file) {
      $audioPreview = (
        <ImageZone>

          {/*<audio ref="audio_tag" src={audioPreview} controls />*/}

          <AudioWrap>
            <div className="video__container">
              <span className="video__play"/>
            </div>

            <div className="video__wrap video__wrap_1">
              <span
                className="video__text">{audioPreview ? file.name.split(".mp3")[0] : decodeURI(file.split('/')[file.split('/').length - 1])}</span>
              <span className="video__subtext">
                {
                  audioPreview ? file.type
                    : file.split('/')[file.split('/').length - 1].split('.')[1]
                },
                {
                  audioPreview ? file.size < 1000000 ? Math.round(file.size / 1024) + " kb" : Math.round(file.size / 1024 / 1024) + " mb"
                    : this.state.file_size
                }
              </span>
            </div>

            <button
              onClick={this.DeleteAudioItem}
              className={'delete_btn'}
            >
              <img src={dlt} alt="icon"/>
            </button>
          </AudioWrap>

          {/*<input*/}

          {/*  accept=".mp3,audio/*"*/}
          {/*  className={'hidden-input'}*/}
          {/*  id="contained-button-file"*/}
          {/*  name={'banner'}*/}
          {/*  onChange={(e) => this._handleAudioChange(e)}*/}
          {/*  type={'file'}*/}

          {/*/>*/}

        </ImageZone>

      );

    } else {

      $audioPreview = (

        <Dropzone onDrop={acceptedFile => this.uploadAudio(acceptedFile)}>

          {({getRootProps, getInputProps, isDragActive}) => (

            <div {...getRootProps()} className={`cover__row cover__row_3`}>

              {isDragActive

                ? <span className="cover__title">Загрузить!</span>

                : <>

                  <span className="cover__title">Перетащите файл сюда</span>

                  <span className="cover__subtitle">или</span>

                  <Button
                    text="Выбрать файл на устройстве"
                    type="cover"
                  />

                </>}

              <input

                {...getInputProps()}
                accept=".mp3,audio/*"
                className={'hidden-input'}
                id="contained-button-file"
                name={'banner'}
                onChange={(e) => this._handleAudioChange(e)}

              />

            </div>

          )}

        </Dropzone>

      );

    }

    return (
      <AdminModalTask
        type={this.state.type}
        back={back}
        title={'Аудиофайл'}
        close={close}
      >
        <FormWrap onSubmit={(e) => {
          this.state.type === 'add' ? this.createTask(e) : this.editTask(e)
        }}>
          <FormHeadContainer>
            <MainInput
              className={'task-input_head'}
              type={'text'}
              label={'Условия упражнения'}
              name={'title'}
              required={true}
              defaultValue={this.state.text}
              updateData={e => this.setState({text: e.target.value})}
            />
            <MainInput
              className={'task-input_name'}
              type={'text'}
              label={'Название аудио'}
              name={'name'}
              required={true}
              defaultValue={this.state.name}
              updateData={e => this.setState({name: e.target.value})}
            />
          </FormHeadContainer>
          <FormMainContainer className={'padding noTopBotPadding'}>
            {
              deleteAudio
                ? <Dropzone onDrop={acceptedFile => this.uploadAudio(acceptedFile)}>
                  {({getRootProps, getInputProps, isDragActive}) => (
                    <div {...getRootProps()} className={`cover__row cover__row_3`}>
                      {isDragActive
                        ? <span className="cover__title">Загрузить!</span>
                        : <>
                          <span className="cover__title">Перетащите файл сюда</span>
                          <span className="cover__subtitle">или</span>
                          <Button
                            text="Выбрать файл на устройстве"
                            type="cover"
                          />
                        </>}
                      <input
                        {...getInputProps()}
                        accept=".mp3,audio/*"
                        className={'hidden-input'}
                        id="contained-button-file"
                        name={'banner'}
                        onChange={(e) => this._handleAudioChange(e)}
                      />
                    </div>
                  )}
                </Dropzone>
                : $audioPreview
            }
          </FormMainContainer>
          <FormBtnContainer>
            <MainButton
              text={this.state.type === 'add' ? 'Добавить' : 'Сохранить'}
              width={'full'}
              type={'submit'}
            />
          </FormBtnContainer>
        </FormWrap>
      </AdminModalTask>
    )
  }
}
