import styled from "styled-components";
import {baseTheme} from "../../../baseTheme";

export const ModalWrapper = styled.div`
  background-color: rgba(1, 1, 1, .2);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${props => props.zIndex ? props.zIndex : 101};
`

export const ModalContainer = styled.div`
  width: 100%;
  max-width: 660px;
  background: #FFFFFF;
  box-shadow: 0 0 16px rgba(105, 112, 119, 0.12), 0 8px 16px rgba(105, 112, 119, 0.16);
  border-radius: 8px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  position: relative;
  max-height: 95%;

  .closed {
    width: 24px;
    height: 24px;
    object-fit: contain;
    cursor: pointer;
    position: absolute;
    top: 24px;
    right: 24px;
  }

  .caption {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 24px;

    .title {
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 24px;
      letter-spacing: -0.01em;
      color: #000000;
    }

    .arrow_back {
      border: none;
      background-color: transparent;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 16px;

      img {
        width: 24px;
        height: 24px;
        object-fit: contain;
        background-color: transparent;
      }
    }
  }


  @media ${baseTheme.media.medium} {
    max-height: unset;
    max-width: unset;
    width: 100%;
    height: 100%;
    padding: 0;
    border-radius: 0;
    overflow: auto;

    .closed {
      position: fixed;
      top: 16px;
      right: 16px;
      z-index: 10;
    }

    .caption {
      background-color: #FFFFFF;
      z-index: 9;
      padding: 16px;
      margin-bottom: 0;

      .title {
        font-size: ${baseTheme.fz.titlePopupSmall};
        line-height: ${baseTheme.lh.titlePopupSmall};
      }
    }
  }
`

export const ModalLoader = styled.div`
  border-radius: 8px;
  background-color: rgba(1, 1, 1, .25);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 200;
`


export const ModalForm = styled.form`
  width: 100%;
  padding: 0 24px 24px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  
  @media ${baseTheme.media.medium} {
    padding:0 16px 16px;
  }
`

export const ModalFieldset = styled.fieldset`
  width: 100%;
  flex-grow: 1;
  border: 0;
`
