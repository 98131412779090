import React, {useEffect, useState} from "react";
import {useHistory} from "react-router";
import {connect} from "react-redux";

import axiosInstance from "../../service/iTeacherApi";
import CaptionBlock from "./captionBlock/captionBlock";
import LeftSidebar from "./leftSidebar/leftSidebar";
import PlanList from "./planList/planList";
import ConfirmPopup from "../../components/confirmPopup/confirmPopup";
import CalendarModal from "./calendarModal/CalendarModal";
import RightSidebar from "./rightSidebar/rightSidebar";
import AddStudentModal from "./addStudentModal/addStudentModal";
import ProgramModal from "../../components/programModal/programModal";

import {loginUser, setTopAlertText} from "../../store/actions/usersAction";
import {getAllCourses, getCourse, getStudentCourse} from "../../store/actions/coursesAction";
import {getAllStudents} from "../../store/actions/studentsAction";
import {openInfoPopup, setErrorInfoText, setSuccessInfoText} from "../../store/actions/infoPopupAction";
import {getPaymentLink, payCourse} from "../../store/actions/orderAction";
import {clearFilteredHomeworks, deleteHomework, getFilteredHomeworks} from "../../store/actions/homeworksAction";

import * as Style from "./styled";
import {PageWrap} from "../../components/ui/page/styled";

const Course = (
  {
    getCourse,
    courseId,
    courses,
    course,
    isInactiveCourse,
    user,
    userType,
    setTopAlertText,
    studentId,
    isStudentCourse,
    getStudentCourse,
    getAllStudents,
    setSuccessInfoText,
    setErrorInfoText,
    openInfoPopup,
    payCourse,
    order,
    getPaymentLink,
    getAllCourses,
    getFilteredHomeworks,
    deleteHomework,
    clearFilteredHomeworks
  }
) => {
  const [isInvite, setInvite] = useState(false);
  const [isCalendar, setCalendar] = useState(false);
  const [dataCalendar, setDataCalendar] = useState(null);
  const [programModal, setProgramModal] = useState(null);
  const [courseDeleteModal, setDeleteModal] = useState(null);
  const history = useHistory();

  useEffect(() => {
    if (isStudentCourse) {
      clearFilteredHomeworks();
      getFilteredHomeworks(userType, `course=${courseId}&student=${studentId}`);
    }
    return () => {
      if (isStudentCourse) {
        clearFilteredHomeworks();
      }
    }
  }, [])

  useEffect(() => {
    if (!dataCalendar && studentId) {
      getStudentCourse(courseId, studentId)
    } else if (!dataCalendar) {
      getNeedCourse();
    }
  }, [user, dataCalendar]);

  useEffect(() => {
    if (user && courses.length >= 1 && course.id !== undefined) {
      setTopAlertText(
        isInactiveCourse && user.type === 'TEACHER'
          ? (
            <div>
              <p>Необходимо оплатить аренду курса.</p>
              <span onClick={courseSubscribePay}>Купить</span>
            </div>
          )
          : false
      )
    }
  }, [course, user])
  useEffect(() => {
    return () => setTopAlertText(false);
  }, [isInactiveCourse])

  useEffect(() => {
    if (order.id !== null) {
      getPaymentLink(order.id)
    }
  }, [order])


  const handleBackHistory = () => history.goBack();

  const handleCourseEditHistory = () => history.push(`/course-edit/${course.course_template.id}`);

  const handleInviteModal = (value) => {
    setInvite(value)
  }
  const handleCalendar = (value) => {
    setCalendar(value)
  }

  const handleOpenDeleteModal = () => setDeleteModal(true);

  const openCalendar = (lesson) => {
    const data = {
      studentId: studentId,
      courseId: courseId,
      lesson: lesson
    }
    setDataCalendar(data);
    handleCalendar(true);
  }

  const handleProgramModal = (lesson) => {
    setProgramModal(lesson)
  }

  const getNeedCourse = () => {
    getCourse(user.type.toLowerCase(), courseId)
  }

  const createNewStudent = async (email) => {
    if (user.type === 'STUDENT') return;

    const newEmail = email.toLowerCase();
    try {
      const {data} = await axiosInstance.post(`/teacher/invite-course/`, {
        "email": newEmail,
        "course_id": courseId
      })
      getNeedCourse();
      getAllStudents();
      handleInviteModal(false);
      if (data.info === "Клиент добавлен на курс и в список тренера") {
        setSuccessInfoText('Клиент уже зарегистрирован в системе и будет добавлен в список ваших клиентов')
        openInfoPopup();
      } else if (data.info === "Клиент зарегистрирован и добавлен на курс") {
        setSuccessInfoText(`Клиент не зарегистрирован в системе, на почту ${newEmail} было выслано приглашение`)
        openInfoPopup();
      }
    } catch (err) {
      if (err.response.data.email) {
        setErrorInfoText(err.response.data.email[0])
      } else if (err.response.data.info) {
        setErrorInfoText(err.response.data.info)
      } else {
        setErrorInfoText('Произошла ошибка, попробуйте добавить клиента на курс позже.')
      }
      openInfoPopup();
    }
  }

  const openActiveSidebar = async () => {
    let updateCourse = {
      name: course.name,
      count_lessons: course.count_lessons,
      average_lessons_time: course.average_lessons_time,
      description: course.description,
      status: 'active'
    }

    await axiosInstance.put(`/courses/${course.id}/update/`, updateCourse)
      .then((res) => {
        // обновляем данные пользователя в сторе
        axiosInstance.get(`/users/${user.id}/`, {
          validateStatus: (status) => {
            return true; // I'm always returning true, you may want to do it depending on the status received
          },
        })
          .then(res => {
            this.props.loginUser(res.data);
          }).catch(error => {
          console.error(error);
        });

        // обвновляем данные в стейте
      })
      .catch(error => console.error(error))
  }

  const courseSubscribePay = () => {
    payCourse(course.course_template.id)
  }

  const closeModal = () => {
    handleProgramModal(null);
    handleInviteModal(false);
    handleCalendar(false);
    setDeleteModal(false);
  }
  const finish = () => {
    setDataCalendar(null);
    handleCalendar(false);
  }

  const handleDeleteCourse = async () => {
    try {
      await axiosInstance.delete(`/teacher/courses/${courseId}/`);
      getAllCourses("teacher")
      handleBackHistory();
      setSuccessInfoText(`Курс успешно удален`);
      openInfoPopup();
    } catch (e) {
      console.log(e);
      setErrorInfoText('Произошла ошибка, попробуйте удалить курс позже.')
    } finally {
      closeModal();
      openInfoPopup();
    }
  }

  const handleDeleteHomework = async (homeworkId, closeFunc) => {
    try {
      await deleteHomework(homeworkId);
      getFilteredHomeworks(userType, `course=${courseId}&student=${studentId}`);
      closeFunc();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <PageWrap>
      <div className='container'>
        <CaptionBlock
          course={course}
          isStudentCourse={isStudentCourse}
          onOpenDeleteModal={handleOpenDeleteModal}
          onBack={handleBackHistory}
          onEditCourse={handleCourseEditHistory}
        />

        <Style.CourseEmptyWrap>

          {/*<LeftSidebar course={course}/>*/}

          <Style.MainContent>
            <div className='title'>План курса</div>
            <Style.Plans>
              <PlanList
                isInactiveCourse={isInactiveCourse}
                isStudentCourse={isStudentCourse}
                openCalendar={openCalendar}
                course={course}
                openModal={handleProgramModal}
              />
            </Style.Plans>
          </Style.MainContent>

          {isStudentCourse || !isStudentCourse && !!course
            ? <RightSidebar
              isInactiveCourse={isInactiveCourse}
              isStudentCourse={isStudentCourse}
              courseId={courseId}
              info={course}
              showAddStudent={() => handleInviteModal(true)}
              showAddEvent={openCalendar}
              onDeleteHomework={handleDeleteHomework}
            />
            : null
          }
        </Style.CourseEmptyWrap>
        {
          isInvite
            ? <AddStudentModal
              close={closeModal}
              func={createNewStudent}
            />
            : null
        }
        {
          isCalendar
          && (
            <CalendarModal
              data={dataCalendar ? dataCalendar : isCalendar}
              course={course}
              close={closeModal}
              finish={finish}
              active={openActiveSidebar}
            />
          )
        }
        {
          programModal &&
          <ProgramModal
            title={programModal.name}
            sections={programModal.sections}
            closed={closeModal}
          />
        }

        {courseDeleteModal && user.type === 'TEACHER' && (
          <ConfirmPopup
            onConfirm={handleDeleteCourse}
            onClose={closeModal}
            text={'Вы действительно хотите удалить курс?'}
            successText={'Да, удалить'}
          />
        )}
      </div>
    </PageWrap>
  )
}

const mapStateToProps = ({users, courses, order}) => ({
  user: users.user,
  userType: users.userType,
  courses: courses.courses,
  course: courses.course,
  isInactiveCourse: courses.course?.status !== 'Active',
  order: order.order
});

const mapDispatchToProps = (dispatch) => ({
  loginUser: (user) => dispatch(loginUser(user)),
  setTopAlertText: (text) => dispatch(setTopAlertText(text)),
  getCourse: (userType, courseId) => dispatch(getCourse(userType, courseId)),
  getAllStudents: () => dispatch(getAllStudents()),
  setSuccessInfoText: (data) => dispatch(setSuccessInfoText(data)),
  setErrorInfoText: (data) => dispatch(setErrorInfoText(data)),
  openInfoPopup: () => dispatch(openInfoPopup()),
  getStudentCourse: (courseId, studentId) => dispatch(getStudentCourse(courseId, studentId)),
  payCourse: (id) => dispatch(payCourse(id)),
  getPaymentLink: (id) => dispatch(getPaymentLink(id)),
  getAllCourses: (userType) => dispatch(getAllCourses(userType)),
  getFilteredHomeworks: (userType, query) => dispatch(getFilteredHomeworks(userType, query)),
  deleteHomework: (homeworkId) => dispatch(deleteHomework(homeworkId)),
  clearFilteredHomeworks: () => dispatch(clearFilteredHomeworks()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Course);
