import React, {useState, useEffect} from 'react';
import {connect} from "react-redux";

import CheckedBlock from "../checkedBlock/checkedBlock";
import ResetProgress from "../resetProgress/resetProgress";

import {DragWordsWrap, WordsSection, Word, EmptyWord} from '../mediaDragWords/dragWordsStyled';
import * as Style from './style'

const MediaWordColl = ({data, handleCheckTask, userType, tasks}) => {
  const [listData, setData] = useState([]);
  const [emptiesList, setEmptiesList] = useState([]);
  const [active, setActive] = useState(null);
  const [completed, setCompleted] = useState(data.completed?.completed ? '#24A1481A' : false);

  useEffect(() => {
    if (!data.id) return
    if (data.completed?.completed) {
      displayingTask(data.completed?.task, true);
    } else if (data.completed?.have_answer) {
      displayingTask(data.completed?.answer, true);
      if (!!data.completed?.additional_info) setEmptiesList(JSON.parse(data.completed?.additional_info));
    } else {
      displayingTask(data.completed?.task || data.list_column || data.task, false)
    }
  }, [data])

  useEffect(() => {
    const taskChecked = tasks.find(i => i.task_id === data.id)
    if (taskChecked) {
      setCompleted(taskChecked.completed ? '#24A1481A' : '#DA1E281A');
      if (JSON.stringify(listData) !== JSON.stringify(taskChecked.answer)) {
        setData(taskChecked.answer);
        if (!!taskChecked.additional_info) setEmptiesList(JSON.parse(taskChecked.additional_info));
      }
    }
  }, [tasks]);

  const displayingTask = (task, isCompleted) => {
    let newEmptiesList = [];
    const list = (task).map(item => {
      if (isCompleted) {
        return item
      } else {
        newEmptiesList.push(...item.words)
        return {...item, words: []}
      }
    });
    newEmptiesList.sort(() => Math.random() - .5);
    setData(list);
    setEmptiesList(newEmptiesList);
  }

  const setActiveWord = (word) => setActive(word);

  const dndStart = (e, word) => {
    e.target.style.opacity = '0.4';
    setTimeout(() => {
      setActive(word)

      const classNameDrag = userType === 'student' ? 'drag-student' : 'drag-teacher';
      const allDnDWord = document.querySelectorAll('.' + classNameDrag);
      allDnDWord.forEach(word => {
        word.classList.remove(classNameDrag)
      })
      e.target.classList.add(classNameDrag)
    })
  }

  const dndEnd = (e) => {
    e.target.style.opacity = '1';
    const classNameDrag = userType === 'student' ? 'drag-student' : 'drag-teacher';
    e.target.classList.remove(classNameDrag)
  }

  const dndEnter = (e) => {
    e.target.classList.add('dnd-hovered');
  }

  const dndOver = (e) => {
    e.preventDefault();
  }

  const dndLeave = (e) => {
    e.target.classList.remove('dnd-hovered');
  }

  const dndDrop = (e, key) => {
    e.target.classList.add('dnd-hovered');
    document.querySelectorAll('.dnd-hovered').forEach(block => {
      block.classList.remove('dnd-hovered')
    })
    setWordToPlace(key);
  }

  const setWordToPlace = (key) => {
    if (!active) return
    const newList = listData.map((item, index) => index === key ? {...item, words: [...item.words, active]} : item)
    const newEmptiesList = emptiesList.filter(item => item !== active);
    setData(newList);
    setEmptiesList(newEmptiesList);
    setActive(false);
    handleFalseCloseChecked();
  }

  const handleCheck = () => {
    handleCheckTask(listData, data.id, JSON.stringify(emptiesList))
  };

  const handleFalseCloseChecked = () => setCompleted(false);

  const handleResetProgress = () => displayingTask(data.completed?.task || data.list_column || data.task, false);

  let columnsRender = listData.map((col, key) => {
    const setList = col.words.map((wordItem, key) => {
      return <Word key={wordItem + key}>{wordItem.split(',')[0]}</Word>
    })

    return (
      <Style.Column
        task={data.id}
        empty={key}
        userType={userType}
        key={col.name + key}
        onDragEnter={(e) => {
          dndEnter(e, key)
        }}
        onClick={() => setWordToPlace(key)}
        onDragLeave={dndLeave}
        onDragOver={dndOver}
        onDrop={(e) => dndDrop(e, key)}
      >
        <div className="title-block">{col.name}</div>
        <div className="words-block" onClick={() => completed !== '#24A1481A' && setWordToPlace(col.name)}>
          {setList}
        </div>
      </Style.Column>
    )
  });

  return (
    <>
      <DragWordsWrap>
        <WordsSection className={!!handleCheckTask ? "words_classroom" : ""}>
          {emptiesList.length > 0 ? emptiesList.map((word, key) => (
            <Word
              key={word + key}
              onClick={() => setActiveWord(word)}
              isSelect={active === word}
              userType={userType}
              word={word}
              task={data.id}
              draggable="true"
              onDragStart={(e) => {
                dndStart(e, word)
              }}
              onDragEnd={dndEnd}
            >
              {word.split(',')[0]}
            </Word>
          )) : <EmptyWord/>}
        </WordsSection>

        <Style.Columns>
          {columnsRender}
        </Style.Columns>

      </DragWordsWrap>
      {handleCheckTask &&
        <CheckedBlock
          userType={userType}
          completed={completed}
          handleCheck={handleCheck}
          closeFalseChecked={handleFalseCloseChecked}
        >
          {handleCheckTask && completed !== '#24A1481A' && <ResetProgress handleReset={handleResetProgress}/>}
        </CheckedBlock>
      }
    </>
  )
};

const mapStateToProps = ({users, tasksChecked}) => {
  return {
    userType: users.userType,
    tasks: tasksChecked.tasks
  }
};


export default connect(mapStateToProps)(MediaWordColl);
