import React, {Component} from 'react';
import {Link, Redirect} from "react-router-dom";
import {connect} from "react-redux";

import ProgramItem from "./programItem/programItem";
import ProgramModal from "../../components/programModal/programModal";
import CourseCover from "../../components/courseCover/courseCover";

import {
  SingleCoursesWrap,
  ProgramSection,
  Banner,
  InfoBlock,
  WithOutHeaderContainer,
  MobileBlock
} from './styled';

import arrow from '../../assets/media/icon/arrow-left.svg';
import close from '../../assets/media/icon/close.svg';

import axiosInstance from "../../service/iTeacherApi";
import {getPaymentLink, payCourse} from "../../store/actions/orderAction";
import {getAllCourses} from "../../store/actions/coursesAction";

class SingleCourse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lastProgram: true,
      showMore: false,
      title: 'Courses Name',
      programModal: false,
      template: {
        name: ''
      },
      program: [],
      sections: [],
      taskModalTitle: '',
      redirect: false,
      redirectLibrary: false,
      creating: false
    }
    document.body.addEventListener('click', (e) => this.closeBody(e));
  }

  componentDidMount() {
    this.getDataTemplate();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.order.id !== prevProps.order.id && this.props.order.id !== null) {
      this.props.getPaymentLink(this.props.order.id)
    }
  }

  getDataTemplate = async () => {
    try {
      const response = await axiosInstance.get(`/teacher/courses-library/${this.props.courseId}/`);
      const template = response.data;
      this.setState({
        template: template,
        program: template.lessons
      });
    } catch (e) {
      this.setState({
        redirectLibrary: true
      });
    }
  }

  //  btn read more
  showMore = () => {
    this.setState(() => {
      return {
        ...this.state,
        showMore: true
      }
    })
  }

  //  show program item modal
  ProgramModal = (lesson) => {
    this.setState({
      sections: lesson.sections,
      taskModalTitle: lesson.name,
      programModal: true
    });
  }

  closeModal = () => {
    this.setState(() => {
      return {
        ...this.state,
        programModal: false
      }
    })
  }

  // close modal on click body
  closeBody = (e) => {
    return
    const block = document.querySelector('.modalBody');
    if (block === null) {
      return
    }

    const button = document.querySelector('.itemBody');
    const status = e.target === block || block.contains(e.target);
    const statusModal = e.target === button;
    if (!status && !statusModal) {
      this.setState(() => {
        return {
          ...this.state,
          programModal: false
        }
      });
    }

  }

  // создание нового курса
  coursePay = async () => {
    try {
      if (this.state.template.free) {
        await axiosInstance.get(`/teacher/courses-library/${this.props.courseId}/add-course/`);
        this.props.getAllCourses(this.props.user.type.toLowerCase());
        this.setState({
          redirect: true
        });
      } else {
        this.props.payCourse(this.props.courseId);
      }
    } catch (e) {
      console.log(e)
    }
  }

  // рендре в зависимости от статуса
  render() {
    const {showMore, programModal, template, program} = this.state;
    // количество тренировок
    let countLesson = 0;
    // список тренировок
    const programList = program.map((lesson, key) => {
      countLesson++;
      return <ProgramItem open={this.ProgramModal} key={key} number={key} lesson={lesson}/>
    });
    const countLessonString = `${(countLesson === 0 || countLesson >= 5) ? ' тренировок' : countLesson === 1 ? ' тренировка' : ' тренировки'}`

    return (
      <WithOutHeaderContainer>
        {this.state.redirectLibrary ? <Redirect to={`/courses-library`}/> : null}
        {this.state.redirect ? <Redirect to={`/courses/`}/> : null}
          <SingleCoursesWrap>
            <Link to='/courses-library' className='closeButton'>
              <img src={close} alt="icon"/>
            </Link>
            <div className="course-banner">
              <CourseCover course={template} medium={true}/>
              <div className="nameBlock">
                <div className={'name'}>{template.name}</div>
              </div>
            </div>

            <InfoBlock>
              <div className="infoItem">
                <div className="top">
                  <h4>{countLesson}</h4>
                  <p>{countLessonString}</p>
                </div>
                <div className="bottom">Количество тренировок</div>
              </div>

              <div className="infoItem">
                <div className="top">
                  <h4>{template.level}</h4>
                </div>
                <div className="bottom">Уровень</div>
              </div>
              <div className="infoItem">
                <div className="top">
                  <h4>{template.price}  ₽</h4>
                </div>
                <div className="bottom">Цена курса</div>
              </div>
            </InfoBlock>
            <ProgramSection>
              <h3>Программа</h3>
              <div className="programs">
                {programList}
              </div>
            </ProgramSection>
            <div className='startBtn' onClick={this.coursePay}>{this.state.template.free? 'Добавить курс' : 'Приобрести курс'}</div>
          </SingleCoursesWrap>
        {
          programModal
            ? <ProgramModal
              title={this.state.taskModalTitle}
              sections={this.state.sections}
              closed={this.closeModal}
            />
            : null
        }
      </WithOutHeaderContainer>
    )
  }
}

const mapStateToProps = ({ users, order}) => {
  return {
    user: users.user,
    order: order.order,
  }
};

const mapDispatchToProps = (dispatch) => ({
  payCourse: (id) => dispatch(payCourse(id)),
  getPaymentLink: (id) => dispatch(getPaymentLink(id)),
  getAllCourses: (userType) => dispatch(getAllCourses(userType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SingleCourse);
