import React, {Component} from "react";

import InputName from './personalDateModal/inputName';
import InputEmail from './personalDateModal/inputEmail';
import InputDay from './personalDateModal/inputDay';
import InputMonth from './personalDateModal/inputMonth';
import InputYear from './personalDateModal/inputYear';
//styled and media
import close from '../../assets/media/icon/close.svg';
import {PersonalDataWrap, PersonalDataBody, Form,DeleteBtn, ChangeBtn, SaveChange} from './personalDataStyled';
import ava from '../../assets/media/icon/avatar.svg';
import {connect} from "react-redux";
import {updateUser} from "../../store/actions/usersAction";

class PersonalData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      imagePreviewUrl: this.props.user.photo ? this.props.user.photo : ava
    }
    this.file = React.createRef();
  }

  // изминения данных пользователя
  changeData = async (e) => {
    e.preventDefault();
    // дата рождения
    let date = `${e.target.year.value}-${e.target.month.value}-${e.target.day.value}`;
    // проверяем правильно ли введена дата
    if(isNaN(parseInt(e.target.year.value)) || isNaN(parseInt(e.target.month.value)) || isNaN(parseInt(e.target.day.value))) {
      date = null
    }

    const data = new FormData();
    data.set("username", e.target.login.value);
    data.set("email", e.target.email.value);
    if(date) {
      data.set('birth_date', date)
    }

    if(this.state.file || this.state.file === '') {
      data.set("photo", this.state.file);
    }
    this.props.updateUser(this.props.user.id, data, this.props.closed)
  }

  // замена фото
  _handleImageChange(e) {
    e.preventDefault();
    // открываем чтение файлов
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result
      });
    }
    reader.readAsDataURL(file)
  }

  _handleImageDelete = () => {
    this.setState({
      file: "",
      imagePreviewUrl: ava
    });
  }

  render() {
    const {closed} = this.props;
    const date = this.props.user.birth_date ? this.props.user.birth_date.split('-') : ['', '', ''];

    return (
      <PersonalDataWrap>
        <PersonalDataBody>
          <img onClick={closed} className={'close'} src={close} alt="icon"/>
          <h2>Личные данные</h2>
          <Form onSubmit={(e) => this.changeData(e)}>
            <div className={"inputsContainer"}>
              <InputName user={this.props.user}/>
              <InputEmail user={this.props.user}/>
              <div className="dateBlock">
                <h3>Дата рождения</h3>
                <div className="dateSelect">
                  <InputDay defaultDay={date[2]}/>
                  <InputMonth defaultMonth={date[1]}/>
                  <InputYear defaultYear={date[0]}/>
                </div>
              </div>
              <div className="photoSection">
                <h3>Фотография</h3>
                <div className="photoWrap">
                  <img src={this.state.imagePreviewUrl} alt="" className={'avatar'}/>
                  <input
                    ref={this.file}
                    accept="image/*"
                    className={'hidden-input'}
                    id="contained-button-file"
                    name={'photo'}
                    onChange={(e) => this._handleImageChange(e)}
                    type={'file'}
                  />
                  <div>
                    <ChangeBtn htmlFor={'contained-button-file'}>Заменить</ChangeBtn>
                    <DeleteBtn type={"button"} onClick={this._handleImageDelete}>Удалить</DeleteBtn>
                  </div>
                </div>
              </div>
            </div>
            <SaveChange>Сохранить изменения</SaveChange>
          </Form>
        </PersonalDataBody>
      </PersonalDataWrap>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.users.user
  }
};

const mapDispatchToProps = {
  updateUser
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonalData);
