import React, { useRef, useEffect } from "react";

const MediaPlayer = ({ videoTrack, audioTrack , your}) => {
  const container = useRef(null);
  useEffect(() => {
    if (!container.current) return;
    videoTrack?.play(container.current);
    return () => {
      videoTrack?.stop();
    };
  }, [container, videoTrack]);
  useEffect(() => {
    if(audioTrack){
      audioTrack?.play();
    }
    return () => {
      audioTrack?.stop();
    };
  }, [audioTrack]);

  return (
    <>
      <div ref={container}  className={`video-player ${your ? "your_video": ""}`}></div>
    </>
    );
}

export default MediaPlayer;
