import React, {useState, useEffect, useRef} from "react";
import {useHistory} from "react-router";

import MainButton from "../../../components/buttons/mainButton/mainButton";
import ModalButtons from "./modalButtons/modalButtons";

import {ModalCardWrap, Caption, InfoBlock} from './styled';

import closed from '../../../assets/media/icon/close.svg';
import editor from './media/edit.svg'
import ava from "../../../assets/media/icon/avatar.svg";

import {useDispatch} from "react-redux";
import {deleteSchedule} from "../../../store/actions/schedulesAction";
import {getBeforeLessonTimeStart} from "../../../service/timePrettier";

const ModalCard = ({close, event, update}) => {
  const dispatch = useDispatch();
  const modalEl = useRef(null);
  const [buttonsModal, setButtonsModal] = useState(false);
  const history = useHistory();

  useEffect(() => {
    document.addEventListener("click", closeModalButtons);
    return () => {
      document.removeEventListener("click", closeModalButtons);
    }
  }, []);

  const startLesson = async () => {
    history.push(`/class-room/${event.id}`)
  }


  const deleteLesson = () => {
    dispatch(deleteSchedule(event.id))
  }
  const [time, setTime] = useState(null);
  setInterval(() => {
    // получаем текущее время по москве
    const getCurrentTime = () => {
      const time = new Date(Date.now());
      const year = time.getUTCFullYear();
      const month = time.getUTCMonth();
      const day = time.getUTCDate();
      const second = time.getUTCSeconds();
      const hour = time.getUTCHours();
      const minute = time.getUTCMinutes();

      const needTime = new Date(Date.UTC(year, month, day, hour, minute, second));
      return needTime.getTime();
    }

    const currentTime = getCurrentTime();

    // получаем время начала тренировки
    const getStartTime = () => {
      return (new Date(event.start_time)).getTime()
    }

    const startTime = getStartTime();
    // получаем разницу во времени
    const timeToStart = startTime - currentTime;

    let timeString = getBeforeLessonTimeStart(timeToStart);
    if (timeToStart < 0) {
      timeString = false;
    }
    setTime(timeString)
  }, 1000)

  const openModalButtons = (e) => {
    e.stopPropagation();
    setButtonsModal(true);
  }

  const closeModalButtons = (e) => {
    e.stopPropagation();
    if (modalEl.current === e.target && !modalEl.current.contains(e.target)) {
      setButtonsModal(false);
    }
  }

  return (
    <ModalCardWrap className={'card'}>
      <div className="right">

        {/*модалка "редактировать/удалить"*/}
        {
          buttonsModal && event.status !== 'COMPLETE' && event.status !== 'OVERDUE' && <ModalButtons update={update} event={event} onDelete={deleteLesson}/>
        }

        <Caption>
          <div className="title_block">
            <img src={event.student && event.student.user.photo ? event.student.user.photo : ava} alt="" className="photo"/>
            <div className="info-block">
              <div className="title">{event.student && (event.student.user.username || event.student.user.email)}</div>
              <div className="date">{event.sortTime}</div>
            </div>
          </div>
          <div className="btn_block">
            <button onClick={event.status !== 'COMPLETE' && event.status !== 'OVERDUE' && openModalButtons}>{event.status !== 'COMPLETE' && event.status !== 'OVERDUE' && <img src={editor} alt="icon"/>}</button>
            <button
              onClick={close}
            ><img src={closed} alt="icon"/></button>
          </div>
        </Caption>
        <InfoBlock>
          <div className="info">
            <span>Курс</span>
            <div className="course_name">{event.course.course_template?.name}</div>
          </div>
          <div className="info info--last">
            <span>Событие</span>
            <div className="student_name">
              <div
                className="name">{event.lesson.name}</div>
            </div>
          </div>
          {event.status === 'ACTIVE' && (
            <div className={'timer-block'}>
              <div className="timer-block__text">{time ? 'Начнется через' : 'Скоро начнется'}</div>
              <div className="timer-block__info">
                <div className="timer">{time}</div>
                <MainButton
                  text={'Начать'}
                  func={startLesson}
                />
              </div>
            </div>
          )}
          {event.status === 'PROCESS' && (
            <div className={'timer-block'}>
              <div className="timer-block__text">Тренировка началась!</div>
              <div className="timer-block__info">
                <MainButton
                  text={'Войти'}
                  func={startLesson}
                />
              </div>
            </div>
          )}
          {event.status === 'INACTIVE' && (
            <div className={'timer-block overdue'}>
              <div className="timer-block__text">Неактивен. Приобретите тренировки.</div>
            </div>
          )}
          {event.status === 'COMPLETE' && (
            <div className={'timer-block'}>
              <div className="timer-block__text">Завершен</div>
              <div className="timer-block__info">
                <MainButton
                  text={'Войти'}
                  func={startLesson}
                />
              </div>
            </div>
          )}
          {event.status === 'OVERDUE' && (
            <div className={'timer-block overdue'}>
              <div className="timer-block__text">Просрочено</div>
            </div>
          )}
        </InfoBlock>
      </div>
    </ModalCardWrap>
  )
}

export default ModalCard;
