import React from "react";

import * as Style from './styled'

const CourseCover = (props) => {
  const {
      course, isInactive, userType, small,
      template, extraSmall, medium, type, courseTemplate
  } = props;

  const label = course.free && userType === 'TEACHER'
      ? 'Бесплатно'
      : isInactive
      ? 'Неактивен'
      : null;

  return (
    <Style.CoverBlock
      medium={medium}
      small={small}
      extraSmall={extraSmall}
      type={type}
      bg={
        template
          ? course.background
          : isInactive
          ? 'linear-gradient( 32deg ,#333 -2%,#ccc 104%);'
          : course.background
      }
      bgType={course.bg_type}
      bgImage={course.background_image}
      courseTemplate={courseTemplate}
    >
      {
          !!label
            ? <div className="unActive_label">{label}</div>
            : null
      }

      <div className="firstLetter">{course.name && course.name.substr(0, 1)}</div>
      <h3 className={'first'}>{course.name}</h3>
    </Style.CoverBlock>
  )
}

export default CourseCover;
