import styled from 'styled-components';
import {baseTheme} from "../../../baseTheme";


const TestWrap = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  padding-top: 7px;

  @media ${baseTheme.media.medium}, ${baseTheme.media.heightMedium}  {
    align-items: flex-start;
    flex-grow: 1;
    overflow: auto;
    padding: 0 16px;
  }
  
  .test__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    
    .test__question{
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: -0.01em;
      color: #111111;
      margin-bottom: 11px;
    }
  }
  
`

const CheckboxWrap = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    //margin-left: 50px;
    margin-bottom: 11px;
    .word {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: -0.01em;
      color: #111111;
    }
    
    .checkbox {
      width: 22px;
      height: 22px;
      margin-right: 16px;
    }
    
`

export {TestWrap, CheckboxWrap}
