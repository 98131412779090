import React, {useEffect, useState} from 'react';
import ReactPlayer from "react-player";
import {connect} from "react-redux";

import CheckedBlock from "../checkedBlock/checkedBlock";

import {VideoWrap} from './mediaVideoStyled';

const MediaVideo = ({data, handleCheckTask, userType, tasks}) => {
    const [completed, setCompleted] = useState(data.completed?.completed ? '#24A1481A' : false);

    useEffect(() => {
        const taskChecked = tasks.find(i => i.task_id === data.id)
        if (taskChecked) setCompleted(taskChecked.completed ? '#24A1481A' : '#DA1E281A');
    }, [tasks]);

    const handleCheck = () => {
        handleCheckTask("", data.id)
    };

    const handleFalseCloseChecked = () => setCompleted(false);

    return (
        <>
            <VideoWrap>
                <div className="video__text">{data.name_video}</div>
                <div className="video__container">

                    {data.type_video === 'FILE' && data.video_file === null ?
                        <>
                            <div className="video__no-downloaded">
                                <p>Идет процесс загрузки видео на сервер.{"\n"}Потребуется обновить страницу</p>
                            </div>
                        </>
                        :
                        <ReactPlayer
                            className='react-player'
                            url={data.type_video === 'FILE' ? data.video_file : data.link}
                            controls={true}
                            width={'100%'}
                        />
                    }
                </div>
            </VideoWrap>
            {handleCheckTask &&
            <CheckedBlock
                userType={userType}
                completed={completed}
                handleCheck={handleCheck}
                closeFalseChecked={handleFalseCloseChecked}
                isSimple
            />}
        </>
    )
};

const mapStateToProps = ({users, tasksChecked}) => {
    return {
        userType: users.userType,
        tasks: tasksChecked.tasks
    }
};

export default connect(mapStateToProps)(MediaVideo);
