import styled from "styled-components";

export const ResetBtn = styled.button`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.01em;
  background: #DA1E28;
  border: 1px solid #DDE1E6;
  color: #FFFFFF;
  padding: 8px 14px;
  border-radius: 6px;
  cursor: pointer;
  margin-right: 10px;
`
