import styled from "styled-components";
import {baseTheme} from "../../../baseTheme";

export const InfoWrap = styled.div`
  background-color: #fff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.16), 0px 0.5px 0.5px rgba(0, 0, 0, 0.1), 0px 0.1px 0.1px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  width: 100%;
  padding: 16px 0;
  margin-bottom: 24px;

  @media ${baseTheme.media.extraLarge} {
    margin-bottom: 16px;
  }
`

export const InfoIcon = styled.div`
  background-image: ${props => `url(${props.src})`};
  background-size: 18px;
  background-position: center;
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  min-width: 24px;
  cursor: pointer;
`

export const InfoTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #111111;
  padding: 0 16px 16px;
  border-bottom: 1px solid #DDE1E6;
  display: flex;
  justify-content: space-between;
`

export const InfoList = styled.ul`
  padding: 16px 16px 0;
  list-style: none;
`

export const InfoItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
  border-bottom: 1px solid #DDE1E6;
  position: relative;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border: none;
    padding-bottom: 0;
  }

  &.schedule {
    flex-direction: column;
    align-items: flex-start;

    .name {
      font-size: 16px;
      line-height: 22px;
      color: ${baseTheme.colors.textBlack};
      margin-bottom: 5px;
      max-width: calc(100% - 165px);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow-x: hidden;
    }

    .status {
      position: absolute;
      padding: 4px 10px;
      border: 2px solid  ${baseTheme.colors.bgError};
      background-color: ${baseTheme.colors.bgError};
      border-radius: 1000px;
      color: ${baseTheme.colors.textWhite};
      top: 40px;
      right: 58px;
      transform: translateY(-50%);

      &.complete {
        background-color: transparent;
        border-color: ${baseTheme.colors.bgSuccess};
        color: ${baseTheme.colors.textSuccess};
      }
      
      &.verify {
        border-color: ${baseTheme.colors.bgSuccess};
        background-color: ${baseTheme.colors.bgSuccess};
      }
    }
    
    .icon {
      position: absolute;
      top: 40px;
      right: 0;
      transform: translateY(-50%);
      
      &_delete {
        right: 30px;
      }
    } 
    
    &:first-child {
      .status,.icon {
        top: 24px;
      }
    }
  }

  &.user {
    justify-content: flex-start;
    padding: 0 0 10px;
    border: none;

    &:last-child {
      padding-bottom: 0;
    }

    .name {
      font-size: 16px;
      line-height: 22px;
      color: ${baseTheme.colors.textBlack};
    }
  }
  
  &.more-info {
    justify-content: center;
    font-weight: 600;
    cursor: pointer;
    color: ${baseTheme.colors.textBlue};

    img {
      width: 24px;
      min-width: 24px;
      height: 24px;
      object-fit: contain;
      object-position: center;
      margin-left: 8px;
      transition: all .3s;
      transform: ${props => props.isMore ? 'rotate(180deg)' : 'rotate(0)'}
    }
  }

  .avatar {
    width: 40px;
    height: 40px;
    min-width: 40px;
    border-radius: 50%;
    border: 1px dashed #DDE1E6;
    margin-right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 32px;
      height: 32px;
      min-width: 32px;
      border-radius: 50%;
    }
  }

  .name {
    font-size: 16px;
    line-height: 22px;
    color: ${baseTheme.colors.textGrey};
    letter-spacing: -0.01em;

    @media ${baseTheme.media.extraLarge} {
      font-size: 14px;
    }
    @media ${baseTheme.media.large} {
      font-size: 16px;
    }
  }

  .desc {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: ${baseTheme.colors.textBlack};
    letter-spacing: -0.01em;

    @media ${baseTheme.media.extraLarge} {
      font-size: 14px;
    }
    @media ${baseTheme.media.large} {
      font-size: 16px;
    }
  }

  .time {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: ${baseTheme.colors.textGrey};
  }
`

export const InfoNoItem = styled.li`
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.01em;
  color: #697077;
  opacity: 0.72;
`
