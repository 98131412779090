import styled from "styled-components";
import {baseTheme} from "../../../baseTheme";

const Wrapper = styled.div`
  background: rgba(79, 127, 255, 0.1);
  border: 1px solid rgba(79, 127, 255, 0.1);
  border-radius: 12px;
  padding: 16px;
  width: calc(100% - 32px);
  margin-bottom: 8px;
  
  .title {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.01em;
    color: #697077;
  }
  
  .count {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    letter-spacing: -0.01em;
    color: #111111;
    margin-top: 4px;
    margin-bottom: 16px;
  }

  @media ${baseTheme.media.extraLarge} {
    width: 100%;
    background: transparent;
    padding: 8px;
    
    button {
      width: 100%;
    }
    
    .count {
      margin-bottom: 12px;
    }
  }

  @media ${baseTheme.media.small} {

    button {
      margin: 0 auto;
      max-width: calc(100% - 8px);
      padding: 10px 20px;
    }

    .count {
      font-size: 18px;
      line-height: 22px;
    }
  }
`

export {
  Wrapper
}
