import React, {useState, useEffect} from 'react';
import {connect} from "react-redux";

import CheckedBlock from "../checkedBlock/checkedBlock";
import Checkbox from './Checkbox';

import {TestWrap} from './mediaTestStyled';

const MediaTest = ({data, handleCheckTask, userType, tasks}) => {
  const [questionsList, setQuestionsList] = useState([]);
  const [answer, setAnswer] = useState({});
  const [completed, setCompleted] = useState(data.completed?.completed ? '#24A1481A' : false);

  useEffect(() => {
    if (!data.id) return;
    if (data?.completed?.have_answer) {
      setQuestionsList(data.completed.answer);
    } else {
      setQuestionsList(data.completed?.task || data.test_false || data.question || data.task);
    }
  }, [data]);

  useEffect(() => {
    const taskChecked = tasks.find(i => i.task_id === data.id);
    if (taskChecked) {
      setCompleted(taskChecked.completed ? '#24A1481A' : '#DA1E281A');
      if (answer !== taskChecked.answer) {
        setAnswer(taskChecked.answer);
        setQuestionsList(taskChecked.answer);
      }
    }
  }, [tasks]);

  const handleCheck = () => {
    const answer = questionsList;
    handleCheckTask(answer, data.id);
  }

  const handleFalseCloseChecked = () => setCompleted(false);

  const handleToggleCheckbox = (e, questionId, answerId) => {
    const newQuestionList = questionsList.map(item => item.id !== questionId ? item : (
      {
        ...item, answers: item.answers.map(answer => answer.id !== answerId ? answer : (
          {
            ...answer, result: e.target.checked
          })
        )
      }
    ));

    setQuestionsList(newQuestionList);
    handleFalseCloseChecked();
  }

  const questionsRender = questionsList.map(item => (
    <div key={item.id}>
      <h5 className="test__question">{item.value}</h5>
      {item.answers.length > 0 && item.answers.map(answer => (
        <Checkbox
          key={answer.id}
          data={answer}
          handleChange={(e) => completed !== '#24A1481A' && handleToggleCheckbox(e, item.id, answer.id)}/>
      ))}
    </div>
  ));

  return (
    <>
      <TestWrap>
        <div className="test__container">
          {questionsRender}
        </div>
      </TestWrap>
      {handleCheckTask &&
        <CheckedBlock
          userType={userType}
          completed={completed}
          handleCheck={handleCheck}
          closeFalseChecked={handleFalseCloseChecked}
        />}
    </>
  )
};

const mapStateToProps = ({users, tasksChecked}) => {
  return {
    userType: users.userType,
    tasks: tasksChecked.tasks
  }
};

export default connect(mapStateToProps)(MediaTest);
