import React from 'react';

import PlansListItem from "./planListItem/planListItem";
import { getLessonTimeString } from "../../../service/timePrettier";

const PlanList = ({ openModal, course, openCalendar, isStudentCourse, isInactiveCourse}) => {
  const elements = course.course_template.lessons.map((lesson, key) => {
    let timeString;
    if(course.schedules) {
      const schedule = course.schedules.find(l => parseInt(l.lesson) === lesson.id);
      if(schedule) {
        timeString = getLessonTimeString(schedule.start_time);
      } else {
        timeString = '';
      }
    }
    const isComplete = course.completed_lessons?.includes(lesson.id);
    return (
      <PlansListItem
        complete={isComplete}
        isInactiveCourse={isInactiveCourse}
        isStudentCourse={isStudentCourse}
        openCalendar={openCalendar}
        time={timeString}
        open={openModal}
        key={lesson.id}
        number={key}
        lesson={lesson}
      />
    )
  });

  return (
    <div>
      {elements}
    </div>
  )
}

export default PlanList;
