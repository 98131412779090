import React from "react";
import {useHistory} from "react-router-dom";
import classNames from "classnames";

import {getCreateTimeString} from "../../../service/timePrettier";

import {HomeWorkItemWrap, HomeWorkBtnContainer, HomeWorkBtn, Name, Desc} from "./styled";

import play from '../../../assets/media/icon/playColor.svg';
import ava from "../../../assets/media/icon/avatar.svg";

const HomeWorkItem = ({data, userType, editFunc, deleteFunc}) => {
  const history = useHistory();
  const handleGoToRoom = () => history.push(`/homework-room/${data.id}`);

  return (
    <HomeWorkItemWrap onClick={handleGoToRoom} className={classNames({teacher: userType === 'teacher'})}>
      <div className="left">
        <Name className="number">{data.template?.lesson?.name}</Name>
        <Desc className="desc">Назначен {getCreateTimeString(data.time_created)}</Desc>
      </div>
      <div className="user">
        <Name className="label">{userType === 'student' ? 'Тренер' : 'Клиент'}</Name>
        <Desc className="info">
          <img src={(userType !== 'student' ? data.student?.user?.photo : data.teacher?.user?.photo) || ava} alt=""/>
          <p
            className="name">{userType !== 'student' ? data.student?.user?.username : data.teacher?.user?.username}</p>
        </Desc>
      </div>
      <div>
        <Name className="label">Курс</Name>
        <Desc className="info">
          <p className="name">{data.course?.course_template?.name}</p>
        </Desc>
      </div>
      <div className="right">
        {data.status === 'ASSIGNED' && (
          userType === 'student'
            ? <p className="status start"><img src={play} alt="icon"/>Начать выполнение</p>
            : <p className="status">Ожидает выполнения</p>
        )}
        {data.status === 'COMPLETE' && (
          userType === 'student'
            ? <p className="status complete">Ожидает проверки</p>
            : <p className="status start"><img src={play} alt="icon"/>Начать проверку</p>
        )}
        {data.status === 'VERIFIED' && (
          userType === 'student'
            ? <p className="status checked">Проверено</p>
            : <p className="status checked">Проверено</p>
        )}
      </div>
      {userType === 'teacher' && (
        <HomeWorkBtnContainer>
          {!!editFunc && <HomeWorkBtn className={'edit'} onClick={editFunc}/>}
          {!!deleteFunc && <HomeWorkBtn className={'delete'}onClick={deleteFunc}/>}
        </HomeWorkBtnContainer>
      )}
    </HomeWorkItemWrap>
  )
}
export default HomeWorkItem;
