import styled from "styled-components";
import {baseTheme} from "../../../baseTheme";


const QuestionWrap = styled.div`
  margin-top: 16px;

@media ${baseTheme.media.medium}, ${baseTheme.media.heightMedium}  {
  margin-top: 0;
  flex-grow: 1;
  overflow: auto;
  padding: 0 16px;
}
  
`

const QuestionLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #DDE1E6;
  min-height: 62px;
  
  p {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.01em;
    color: #111111;
  }

  @media ${baseTheme.media.small} {
    p {
      padding: 8px 0;
    }
  }
`

const SwitchBlock = styled.div`
  border: 1px solid #DDE1E6;
  border-radius: 4px;
  background: rgba(221, 225, 230, 0.6);
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const SwitchItem = styled.div`
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.01em;
  color: ${props => ((props.result === 'True' && props.true) ||  (props.result === 'False' && props.false) || (props.result === 'Not Stated' && props.notStated)) ? '#4F7FFF' : 'rgba(105, 112, 119, 0.8)'};
  height: 32px;
  width: ${props => !props.false && !props.true ? '95px' : '60px'};
  margin: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props => ((props.result === 'True' && props.true) ||  (props.result === 'False' && props.false) || (props.result === 'Not Stated' && props.notStated)) ? '#fff' : 'transparent'};
  box-shadow: ${props => ((props.result === 'True' && props.true) ||  (props.result === 'False' && props.false) || (props.result === 'Not Stated' && props.notStated)) ? '0px 0px 2px rgba(105, 112, 119, 0.06), 0px 2px 2px rgba(105, 112, 119, 0.12)' : 'none'};
  cursor: pointer;
  text-align: center;
  border-radius: 4px;
  

  @media ${baseTheme.media.small} {
    font-size: 12px;
    line-height: 14px;
    width: ${props => !props.false && !props.true ? '50px' : '40px'};
  }
`

export {QuestionWrap, QuestionLine, SwitchBlock, SwitchItem}
