import styled from "styled-components";
import {baseTheme} from "../../baseTheme";

const ProgramModalWrap = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  background-color: rgba(0, 0, 0, .2);
  z-index: 11;

  @media ${baseTheme.media.medium} {
    z-index: 102;
  }

`

const ProgramModalBody = styled.div`
  box-shadow: 0px 0px 6px rgba(105, 112, 119, 0.08), 0px 4px 8px rgba(105, 112, 119, 0.1);
  background-color: #fff;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-width: 600px;
  max-width: 600px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  padding: 0;

  .close {
    width: 24px;
    height: 24px;
    object-fit: contain;
    object-position: center;
    position: fixed;
    top: 24px;
    right: 24px;
    background-color: transparent;
    cursor: pointer;
    z-index: 11;
  }

  @media ${baseTheme.media.medium} {
    min-width: unset;

    .close {
      top: 16px;
      right: 16px;
    }
  }
`

const Caption = styled.div`
  padding: 24px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #111111;
  border-bottom: 1px solid #DDE1E6;
  background-color: #fff;

  @media ${baseTheme.media.medium} {
    padding: 16px;
  }

`

const Content = styled.div`
  padding: 0 24px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  overflow: auto;
  background-color: transparent;

  &::-webkit-scrollbar {
    display: none;
  }

  .sectionOverlay {
    width: 100%;
  }

  .sectionWrapper {
    margin-bottom: 24px;

    &:nth-child(1) {
      margin-bottom: 16px !important;
    }

  }

  & .sectionWrapper:last-child {
    margin-bottom: 0;
  }

  // для видео
  .react-player {
    max-width: 552px;
  }
`

const Title = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #111111;
  background-color: transparent;
  margin: 24px 0;

  @media (max-width: 575px) {
    font-size: 18px;
    line-height: 24px;
  }

`

const SmallTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.01em;
  color: #111111;
  margin-bottom: 8px;
  background-color: transparent;

  @media (max-width: 575px) {
    font-size: 16px;
    line-height: 22px;
  }

`

const TaskWrapper = styled.div`
  & * {
    padding-left: ${props => props.taskStyled === 'TEXT' && '0'};
    padding-right: ${props => props.taskStyled === 'TEXT' && '0'};
  }

  margin-top: 12px;
  border: ${props => props.taskStyled === 'DOCUMENT' || props.taskStyled === 'audio' ? '1px solid #DDE1E6' : 'none'};
  border-radius: ${props => props.taskStyled === 'DOCUMENT' || props.taskStyled === 'audio' ? '8px' : 'none'};
  padding: ${props => props.taskStyled === 'DOCUMENT' || props.taskStyled === 'audio' ? '12px' : '0'};

  .test__container {
    & * {
      margin-left: 0;
    }
  }

`

export {
  ProgramModalWrap,
  ProgramModalBody,
  Caption,
  Content,
  Title,
  SmallTitle,
  TaskWrapper
}
