import React from 'react'
import {connect} from "react-redux";
import {NavLink} from "react-router-dom";

import CourseCover from "../../../../components/courseCover/courseCover";

import * as Style from './styled.js'

import {getLessonTimeString} from "../../../../service/timePrettier";
import AddCourseButton from "../../../../components/buttons/addCourseButton/addCourseButton";

const TabCourses = ({data, studentId, handleOpenAddCourseModal, user}) => {

  return (
    // all courses wrapper
    <Style.CourseWrapper>
      {/*add course block*/}
      <AddCourseButton clickHandle={handleOpenAddCourseModal} text={'Добавить курс'}/>

      {/*выводим все курсы*/}
      {
        data?.user?.courses.map(course => {
          return (
            <NavLink
              key={course.id} to={`${studentId ? `/students/${studentId}` : ''}/course/${course.id}`}
              className='course-wrapper'
            >
              <CourseCover
                type={'student-courses'}
                course={course.course_template}
                userType={user.type}
                isInactive={course.status === 'Inactive'}
              />

              <h4 className="course-title">{course.course_template.name}</h4>
              <div className="course-next-lesson">
                <p>{course.schedule && 'Ближайшее событие:'}</p>
                <p>{course.schedule && `${getLessonTimeString(course.schedule.start_time)}`}</p>
              </div>
            </NavLink>
          )
        })
      }
    </Style.CourseWrapper>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.users.user
  }
}

export default connect(mapStateToProps)(TabCourses);
