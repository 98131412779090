import React from "react";

import {IndicatorWrap} from './styled';

const Indicator = ({complete}) => {
  return (
    <IndicatorWrap complete={complete} />
  )
}

export default Indicator;
