import React from "react";
import {ResetBtn} from "./styled";

const ResetProgress = ({handleReset}) => {
  return (
    <ResetBtn onClick={handleReset}>
      Сбросить прогресс
    </ResetBtn>
  )
}

export default ResetProgress;
