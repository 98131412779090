const initState = {
  scheduleList: [],
  schedule: {}
}

export const GET_ALL_SCHEDULES = 'GET_ALL_SCHEDULES';
export const GET_SCHEDULE = 'GET_SCHEDULE';
export const NEW_SCHEDULE = 'NEW_SCHEDULE';
export const DELETE_SCHEDULE = 'DELETE_SCHEDULE';

const schedulesReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_ALL_SCHEDULES:
      return {
        ...state,
        scheduleList: action.payload
      }

    case GET_SCHEDULE:
      return {
        ...state,
        schedule: action.payload
      }

    case NEW_SCHEDULE:
      return {
        ...state,
        scheduleList: [...state.scheduleList, action.payload]
      }

    case DELETE_SCHEDULE:
      return {
        ...state,
        scheduleList: state.scheduleList.filter(schedule => schedule.id !== action.payload)
      }

    default:
      return state
  }
}

export default schedulesReducer;
