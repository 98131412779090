import styled from "styled-components";
import {baseTheme} from "../../baseTheme";

const Wrapper = styled.div`
  padding: 124px 48px 24px;
  display: flex;
  min-height: calc(100% - 90px);
  max-width: 1442px;

  .left {
    width: 420px;
    min-width: 420px;
  }

  .right {
    width: 100%;
    padding-left: 48px;
  }

  @media ${baseTheme.media.extraLarge} {
    padding-top: 112px;
    padding-left: 24px;
    padding-right: 24px;

    .left {
      width: 308px;
      min-width: 308px;
    }

    .right {
      padding-left: 32px;
    }
  }

  @media ${baseTheme.media.large} {
    flex-direction: column;

    .left {
      width: 100%;
      min-width: auto;
      padding-bottom: 16px;
    }

    .right {
      padding-left: 0;
    }
  }

  @media ${baseTheme.media.small} {
    min-height: calc(100% - 174px);
    padding: 72px 16px 24px;
  }
`

export {
  Wrapper
}
