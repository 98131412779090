const initState = {
  text: '',
  isOpenPopup: false,
  isSuccess: true
}

export const SET_SUCCESS_INFO_TEXT = 'SET_SUCCESS_INFO_POPUP';
export const SET_ERROR_INFO_TEXT = 'SET_ERROR_INFO_POPUP';
export const OPEN_POPUP = 'OPEN_POPUP';
export const CLOSE_POPUP = 'CLOSE_POPUP';

const infoPopupReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_SUCCESS_INFO_TEXT:
      return {
        ...state,
        text: action.payload,
        isSuccess: true
      }

    case SET_ERROR_INFO_TEXT:
      return {
        ...state,
        text: action.payload,
        isSuccess: false
      }

    case OPEN_POPUP:
      return {
        ...state,
        isOpenPopup: true
      }

    case CLOSE_POPUP:
      return {
        ...state,
        isOpenPopup: false,
        text: "",
        isSuccess: true
      }

    default:
      return state
  }
}

export default infoPopupReducer;
