import React from 'react';
import {Link} from "react-router-dom";

import {getLessonTimeString} from "../../../service/timePrettier";
import editIcon from "../../../assets/media/icon/edit_white.svg";

import CourseCover from "../../../components/courseCover/courseCover";

import * as Style from './styled';
import ava from "../../../assets/media/icon/avatar.svg";


const CourseItem = (props) => {
  const {course, user} = props;
  const {schedule, course_template, id, status, teacher} = course

  return (
    <Style.CoursesItem to={'/course/' + id} id={id}>
      {(!course_template.general && user.type !== 'STUDENT') && <Link className={'course-edit'} to={`/course-edit/${course_template.id}`}><img src={editIcon} alt="Отредактировать курс"/></Link>}
      <CourseCover course={course_template} userType={user.type} isInactive={status === 'Inactive'}/>
      <h4 className={`title`}>{course_template.name}</h4>
      {user.type === 'STUDENT' && (
        <>
          <div className="info">
            <img className="ava" src={teacher.user.photo ? teacher.user.photo : ava} alt=""/>
            <p className="desc">{teacher.user.username ? teacher.user.username : teacher.user.email}</p>
          </div>
        </>
      )}
      {(schedule && user.type === 'STUDENT')&& (
        <>
          <p className='desc'>Ближайшее событие:</p>
          <p className='desc'>{getLessonTimeString(schedule.start_time)}</p>
        </>
      )}
    </Style.CoursesItem>
  )
}

export default CourseItem;
