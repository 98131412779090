import styled from "styled-components";
import {baseTheme} from "../../../../baseTheme";

export const ListLessonWrap = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 0px 2px rgba(105, 112, 119, 0.06), 0px 2px 2px rgba(105, 112, 119, 0.12);
  border-radius: 8px;
  padding: 0 16px;
  flex-direction: column;
  overflow-y: auto;

  @media ${baseTheme.media.large} {
    padding: 16px;
    flex-grow: 1;
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, .5) rgba(221, 225, 230, 0.5);


    &::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    &::-webkit-scrollbar:vertical {
      width: 10px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      border: 2px solid white;
      background-color: rgba(0, 0, 0, .5);
    }

    &::-webkit-scrollbar-track {
      border-radius: 8px;
      background-color: rgba(221, 225, 230, 0.5);
    }
  }
`
