import styled from "styled-components";
import {baseTheme} from "../../../baseTheme";

export const CheckedWrap = styled.div`
  width: calc(100% + 40px);
  height: 69px;
  background-color: ${props => props.status ? props.status : '#f8f9fb'};
  border: 1px solid #DDE1E6;
  margin: 23px -24px -16px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 0 0 8px 8px;

  .icon {
    width: 36px;
    height: 36px;
    margin-right: 16px;
  }

  .text {
    color: ${props => props.status === '#24A1481A' ? '#24A148' : '#DA1E28'};
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.01em;
  }

  .closed {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
  }


  .btn__check {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.01em;
    background-color: #4F7FFF;
    color: #FFFFFF;
    padding: 8px 14px;
    border-radius: 6px;
    border: none;
    cursor: pointer;
  }

  @media ${baseTheme.media.medium}, ${baseTheme.media.heightMedium}  {
    border-radius: 0;
    border-left: none;
    border-right: none;
    border-bottom: none;
    width: 100vw;
    margin: 0;
    padding: 8px 4px;
    min-height: 57px;
    height: 57px;
    max-height: 57px;

    button {
      font-size: 14px!important;
      padding: 8px 12px !important;
    }

    .icon {
      width: 32px;
      height: 32px;
      margin-right: 8px;
    }

    .text {
      font-size: 14px;
      line-height: 18px;
    }
  }

  @media ${baseTheme.media.extraSmall} {
    button {
      padding: 8px !important;
    }
  }
`
