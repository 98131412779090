import React, {useState} from "react";

import {StartTask, Times} from "../../styled";

import clock from "../../../../assets/media/icon/clock.svg";
import {getBeforeLessonTimeStart} from "../../../../service/timePrettier";

const Timer = ({event, type, children}) => {
  const [time, setTime] = useState(null);
  // запускаем таймер
  setInterval(() => {
    // получаем текущее время по москве
    const getCurrentTime = () => {
      const time = new Date(Date.now());
      const year = time.getUTCFullYear();
      const month = time.getUTCMonth();
      const day = time.getUTCDate();
      const second = time.getUTCSeconds();
      const hour = time.getUTCHours();
      const minute = time.getUTCMinutes();

      const needTime = new Date(Date.UTC(year, month, day, hour, minute, second));
      return needTime.getTime();
    }

    const currentTime = getCurrentTime();

    // получаем время начала тренировки
    const getStartTime = () => {
     return (new Date(event.start_time)).getTime();
    }

    const startTime = getStartTime();

    // получаем разницу во времени
    const timeToStart = startTime - currentTime;

    let timeString = `Начнется через ${getBeforeLessonTimeStart(timeToStart)}`;
    if (timeToStart < 0) {
      timeString = 'Скоро начнется';
    }
    setTime(timeString)
  }, 1000)
  return (
    <td className="right">
      <div className="td-wrapper td-wrapper--end">
        <StartTask className="startTask" type={type}>
          <img src={clock} alt="icon"/>
          <Times>{time}</Times>
        </StartTask>
        {children}
      </div>
    </td>
  )
}

export default Timer;
