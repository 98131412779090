import React, {Component} from 'react';
import {connect} from "react-redux";

import {loginUser} from "../../store/actions/usersAction";

import StudentItem from "./studentItem/studentItem";
import Note from "./note/Note";
import StudentEmpty from "./studentEmpty/studentEmpy";
import MainButton from "../../components/buttons/mainButton/mainButton";
import AddNewStudent from "./addNewStudent/addNewStudent";

import {
  Caption,
  CoursesList,
  StudentsWrap,
  DropDown,
  Input,
  SearchBlock,
  SortBlock,
  Title,
  FilterBtn
} from "./styled";

import search from "../../assets/media/icon/search.svg";
import arrow from "../../assets/media/icon/arrow.svg";
import sort from "../../assets/media/icon/sort.svg";
import filter from "../../assets/media/icon/filter.svg";

import axiosInstance from "../../service/iTeacherApi";
import { getAllStudents } from "../../store/actions/studentsAction";
import {openInfoPopup, setErrorInfoText, setSuccessInfoText} from "../../store/actions/infoPopupAction";

class Students extends Component {

  constructor(props) {
    super(props);
    this.state = {
      notes: false,
      courses: [],
      activeLink: null,
      idStudent: null,
      data: [], // notations
      modalAddStudent: false,
      isStudentInvited: false
    };
    //document.body.addEventListener('click', (e) => this.closeBody(e));
  }

  componentDidMount() {
    this.getListStudents();
    this.updateCourses();
    // this.setNotationsToState();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps && JSON.stringify(prevProps.user) !== JSON.stringify(this.props.user)) {
      this.getListStudents();
      this.updateCourses();
    }

    if (prevProps && JSON.stringify(prevProps.notations) !== JSON.stringify(this.props.notations)) {
      // this.setNotationsToState();
    }
  }

  // передаем кусры из стора
  updateCourses = async () => {
    if (this.props.user) {
      const courses = await axiosInstance.get("/teacher/courses/")
          .then(res => res.data)
      this.setState({courses});
    }
  }

  // получаем список студентов
  getListStudents = async () => {
    if (!this.props.user) {
      return
    }


  }

  setNotationsToState = (id) => {
    if (!this.props.notations || this.props.notations.length < 1) {
      return
    }

    return this._filterNotations(this.props.notations, id);
  }

  _filterNotations = (notations, id) => {
    // teacher
    const teacherNotations = notations.filter(note => note.teacher.toString() === this.props.user.id.toString());

    // students
    return teacherNotations.filter(note => note.student.toString() === id.toString());
  }

  _filterStudents = (students) => {
    let needStudents = [];
    students.forEach(student => {
      this.props.user.courses.forEach(id => {
        if (student.courses[0] === id) {
          const course = this.props.courses.find(c => c.id.toString() === id.toString());
          const newStudent = {
            ...student,
            course
          }
          needStudents.push(newStudent);
        }
      })
    })

    return needStudents;
  }

// close modal
  closeModal = () => {
    this.setState(() => {
      return {
        ...this.state,
        notes: false
      }
    });
  }

  // open notes section
  openNote = (id) => {
    this.setState({
      idStudent: id,
      data: this.setNotationsToState(id),
      notes: true
    });
  }

  // close modal on click body
  // closeBody = (e) => {
  //   const block = document.querySelector('.note');
  //   if(block === null) {
  //     return
  //   }
  //   const button = document.querySelectorAll('.openNotes');
  //   const status = e.target === block || block.contains(e.target);
  //   const statusModal = e.target === button;
  //   if (!status && !statusModal) {
  //     this.setState(() => {
  //       return {
  //         ...this.state,
  //         notes: false
  //       }
  //     });
  //   }
  // }

  setIsStudentInvited = (value) => {
    this.setState({isStudentInvited: value})
  };

  // создание учениника
  createNewStudent = async (e) => {
    e.preventDefault();

    const email = e.target.email.value.toLowerCase();
    try {
      const data = await axiosInstance.post('/teacher/invite/', {
        'email': email
      })
      this.props.getAllStudents();
      this.setState({modalAddStudent: false})
      this.props.setSuccessInfoText(`Клиент зарегистрирован в системе, на почту ${email} было выслано приглашение`);
    } catch (err) {
      if (err.response?.data?.info === "Данный студент уже есть у тренера") {
        this.setIsStudentInvited(true);
        this.props.setErrorInfoText(`Клиент зарегистрирован в системе, на почту ${email} было выслано приглашение`);
      } else if (err.response?.data?.info === "Невозможно добавить этого пользователя") {
        this.props.setErrorInfoText(`Невозможно добавить пользователя c почтой ${email}`);
      } else {
        this.props.setErrorInfoText('Произошла ошибка, попробуйте добавить клиента позже');
      }
    } finally {
      this.props.openInfoPopup();
    }
  }

  handleRepeatInviteSend = async (e, email) => {
    e.stopPropagation();
    try {
      await axiosInstance.post('/teacher/invite-repeat/', {
        'email': email
      });
      this.props.setSuccessInfoText('Повторное приглашение успешно отправлено');
    } catch (err) {
      this.props.setErrorInfoText('Произошла ошибка, попробуйте отправить приглашение позже');
      console.log(err.response?.data?.info);
    } finally {
      this.props.openInfoPopup();
    }
  }

  render() {
    return (
      <StudentsWrap>
        {
          this.state.modalAddStudent &&
          <AddNewStudent isInvited={this.state.isStudentInvited} setIsInvated={this.setIsStudentInvited} add={this.createNewStudent} close={() => this.setState({modalAddStudent: false})}/>
        }

        <div className='container'>
          <Caption>
            <Title>Клиенты</Title>

            <MainButton text={'Добавить клиента'} func={() => this.setState({modalAddStudent: true})}/>
            {/*<FilterBtn>*/}
            {/*  <img src={filter} alt="icon"/>*/}
            {/*</FilterBtn>*/}
          </Caption>
          {/*<Caption>*/}
            {/*<SearchBlock>*/}
            {/*  <Input>*/}
            {/*    <img src={search} alt="icon"/>*/}
            {/*    <input type="text" placeholder="Поиск"/>*/}
            {/*  </Input>*/}
            {/*  <DropDown>*/}
            {/*    <p>Курс</p>*/}
            {/*    <img src={arrow} alt="icon"/>*/}
            {/*  </DropDown>*/}
            {/*</SearchBlock>*/}
            {/*<SortBlock>*/}
            {/*  <img src={sort} alt="icon"/>*/}
            {/*  <p>Сортировать по</p>*/}
            {/*  <p><b>алфавиту</b></p>*/}
            {/*  <img src={arrow} alt="icon"/>*/}
            {/*</SortBlock>*/}
          {/*</Caption>*/}
          {/*ADD students LIST*/}
          <CoursesList>
            {
              this.props.students && this.props.students.map(student => {
                // получаем ближайшее события студента
                return (
                  <StudentItem
                    key={student.id}
                    data={student}
                    onRepeatInviteSend={this.handleRepeatInviteSend}
                    note={this.openNote}
                  />
                )
              })
            }
            {/*ADD NOTES BLOCK*/}
            {
              this.state.notes
                ? <Note
                  data={this.state.data}
                  closed={this.closeModal}
                />
                : null
            }
          </CoursesList>
        </div>
      </StudentsWrap>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    students: state.students.students,
    courses: state.users.courses,
    user: state.users.user,
    notations: state.users.notations
  }
};

const mapDispatchToProps = {
  loginUser,
  getAllStudents,
  setSuccessInfoText,
  setErrorInfoText,
  openInfoPopup
};

export default connect(mapStateToProps, mapDispatchToProps)(Students);
