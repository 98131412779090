import React from 'react';
import InfoItem from "../InfoItem";
import InfoBlock from "../infoBlock";

import iconPan from "../../../../assets/media/icon/pan.svg";
import iconArrow from "../../../../assets/media/icon/arrow-blue-down.svg";

const StudentBlock = ({data}) => {
  return (
    <InfoBlock
      title={'Информация'}
      iconSrc={iconPan}
    >
      {data.map(item => (
        <InfoItem
          name={item.name}
          desc={item.value}
        />
      ))}
    </InfoBlock>
  );
};

export default StudentBlock;
