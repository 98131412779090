import React, {Component} from "react";
import CommonTabs from "./CommonTabs";

import {TabsBody} from "./addCoursesStyle";

import axiosInstance from "../../service/iTeacherApi";

class CourseTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      courses: []
    }
  }

  componentDidMount() {
    this.getTemplates();
  }

  getTemplates = async () => {
    await axiosInstance.get(`/teacher/courses-library/`)
      .then(res => {
        this.setState({courses: res.data});
      }).catch(error => console.log(error));
  }

  render() {
    const {courses} = this.state;
    return (
      <>
        <TabsBody>
          <CommonTabs
            className={"templateItem"}
            courses={courses}
          />
        </TabsBody>
      </>
    )
  }
}

export default CourseTabs;
