import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";

import MainInput from "../../inputs/mainInput/mainInput";
import CheckedBlock from "../checkedBlock/checkedBlock";

import {DragWordsWrap} from '../mediaDragWords/dragWordsStyled';
import * as Style from '../mediaSentence/style'
import {Sentence} from "../mediaTransfer/style";

const regexp = /\[(\\.|[^\]\\])*\]/g;

const MediaWrite = ({data, handleCheckTask, userType, tasks}) => {
  const [sentences, setSentences] = useState([]);
  const [completed, setCompleted] = useState(data.completed?.completed ? '#24A1481A' : false);

  useEffect(() => {
    if (!data.id) return;
    if (data.completed?.additional_info) {
      setSentences(JSON.parse(data.completed.additional_info));
    } else {
      if (data.completed?.completed) {
        displayingTask(data.completed?.task, true);
      } else {
        displayingTask(data.completed?.task || data.desc || data.task, false)
      }
    }
  }, [data]);

  const displayingTask = (task, isCompleted) => {
    let newSentences;
    let sentenceCounter = 1;
    let wordCounter = 1;
    const returnWordsWithValue = (word) => {
      const words = word.replace('_', ' ').replace(/[\[\]]/g, '').split('/');
      return {
        word: words.slice(1),
        value: words[0],
        id: wordCounter++
      }
    }
    newSentences = (task).split('\n').map(item => ({
      words: item.replace(regexp, str => str.replace(/\s/g, '_')).split(' ').map(word => (word.includes('[') && word.includes(']')) ? returnWordsWithValue(word) : ({
        word,
        id: wordCounter++
      })),
      id: sentenceCounter++
    }));
    setSentences(newSentences);
  }

  useEffect(() => {
    const taskChecked = tasks.find(i => i.task_id === data.id);
    if (taskChecked) {
      setCompleted(taskChecked.completed ? '#24A1481A' : '#DA1E281A');
      if (JSON.stringify(sentences) !== taskChecked.additional_info) {
        if (!!taskChecked.additional_info) setSentences(JSON.parse(taskChecked.additional_info));
      }
    }
  }, [tasks]);


  const handleChangeInput = (e, sentenceId, wordId) => {
    const newSentences = sentences.map(sentense => sentense.id === sentenceId ? ({
      ...sentense,
      words: sentense.words.map(word => word.id === wordId ? ({
        ...word,
        value: e.target.value
      }) : word)
    }) : sentense);
    setSentences(newSentences);
    handleFalseCloseChecked();
  }

  const handleCheck = () => {
    const answer = sentences.map(sentence => sentence.words.map(word => word.value ? `[${word.value}]` : word.word).join(' ')).join('\n');
    const additionalInfo = JSON.stringify(sentences);
    handleCheckTask(answer, data.id, additionalInfo);
  }

  const handleFalseCloseChecked = () => setCompleted(false);

  const sentencesRender = sentences.map(sentence => (
      <Sentence key={sentence.id}>
        {sentence.words.map(item => {
          const {word, value, id} = item
          if (value !== undefined) {
            return (
              <MainInput
                key={id}
                //label={word.length > 1 && word.join('/')}
                name={`word_${id}`}
                defaultValue={value}
                type={'text'}
                width={'auto'}
                task={true}
                updateData={(e) => completed !== '#24A1481A' && handleChangeInput(e, sentence.id, id)}
              />
            )
          }
          return <p key={id}>{word}</p>
        })}
      </Sentence>
    )
  );

  return (
    <>
      <DragWordsWrap>
        <Style.EmptyBlock sentence>
          {sentencesRender}
        </Style.EmptyBlock>
      </DragWordsWrap>
      {handleCheckTask &&
        <CheckedBlock
          userType={userType}
          completed={completed}
          handleCheck={handleCheck}
          closeFalseChecked={handleFalseCloseChecked}
        />}
    </>
  )
};

const mapStateToProps = ({users, tasksChecked}) => {
  return {
    userType: users.userType,
    tasks: tasksChecked.tasks
  }
};

export default connect(mapStateToProps)(MediaWrite);
