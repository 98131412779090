const initState = {
  courses: [],
  course: {
    course_template: {
      lessons: []
    },
    shedules: []
  }
}

export const GET_ALL_COURSES = 'GET_ALL_COURSES';
export const GET_COURSE = 'GET_COURSE';
export const CLEAR_COURSE = 'CLEAR_COURSE';

const coursesReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_ALL_COURSES:
      return {
        ...state,
        courses: action.payload
      }

    case GET_COURSE:
      return {
        ...state,
        course: action.payload
      }

    case CLEAR_COURSE:
      return {
        ...state,
        course: {
          course_template: {
            lessons: []
          },
          schedules: []
        }
      }

    default:
      return state
  }
}

export default coursesReducer;
