import React, {useState, useEffect} from "react";

import LessonItem from "./lessonItem/lessonItem";

import {ListLessonWrap} from './styled';


const LessonBlock = ({data, activeSection}) => {
  const [list, setList] = useState([])

  useEffect(() => {
    if (data.length > 0) {
      const sectionsList = data.map((section, key) => {
        if(key === activeSection) {
          return {...section, active: true, done: null}
        } else {
          if (key < activeSection) {
            return {...section, active: false, done: true}
          } else {
            return {...section, active: false, done: false}
          }
        }
      })
      setList(sectionsList);
    } else {
      setList([]);
    }
  }, [data, activeSection]);

  const sections = list.map((section, key)=> {
    return <LessonItem key={key} data={section}/>
  })

  return (
    <ListLessonWrap>
      {sections}
    </ListLessonWrap>
  )
}

export default LessonBlock;
