import React, {useState, useEffect} from 'react';
import {connect} from "react-redux";

import CheckedBlock from "../checkedBlock/checkedBlock";

import * as Style from './style'

const MediaTF = ({data, handleCheckTask, userType, tasks}) => {
  const [questionsData, setQuestions] = useState([]);
  const [answer, setAnswer] = useState({});
  const [completed, setCompleted] = useState(data.completed?.completed ? '#24A1481A' : false);

  useEffect(() => {
    if (!data.id) return;
    if (data.completed?.answer?.length) {
      setQuestions(data.completed?.answer);
    } else {
      setQuestions(data.completed?.task || data.tf_not_stated || data.list_tf_question || data.task);
    }
  }, [data]);

  useEffect(() => {
    const taskChecked = tasks.find(i => i.task_id === data.id)
    if (taskChecked) {
      if (answer !== taskChecked.answer) {
        setCompleted(taskChecked.completed ? '#24A1481A' : '#DA1E281A');
        setAnswer(taskChecked.answer);
        setQuestions(taskChecked.answer);
      }
    }
  }, [tasks]);

  const changeResult = (e, selectedQuestion, key) => {
    const value = e.target.textContent;
    if (value === 'TrueNot StatedFalse') return;
    const newList = questionsData.map((question, index) => {
      if (question.question === selectedQuestion.question && index === key) {
        return {...question, result: value};
      } else {
        return question;
      }
    })
    setQuestions(newList);
    handleFalseCloseChecked();
  }

  const handleCheck = () => handleCheckTask(questionsData, data.id);

  const handleFalseCloseChecked = () => setCompleted(false);

  const questions = questionsData.map((question, key) => {
    return (
      <Style.QuestionLine key={question.question + key}>
        <p>{question.question}</p>
        <Style.SwitchBlock onClick={(e) => completed !== '#24A1481A' && changeResult(e, question, key)}>
          <Style.SwitchItem true result={question.result}>True</Style.SwitchItem>
          <Style.SwitchItem notStated result={question.result}>Not Stated</Style.SwitchItem>
          <Style.SwitchItem false result={question.result}>False</Style.SwitchItem>
        </Style.SwitchBlock>
      </Style.QuestionLine>
    )
  })

  return (
    <>
      <Style.QuestionWrap>
        {questions}
      </Style.QuestionWrap>
      {handleCheckTask &&
      <CheckedBlock
        userType={userType}
        completed={completed}
        handleCheck={handleCheck}
        closeFalseChecked={handleFalseCloseChecked}
      />}
    </>
  )
};

const mapStateToProps = ({users, tasksChecked}) => {
  return {
    userType: users.userType,
    tasks: tasksChecked.tasks
  }
};

export default connect(mapStateToProps)(MediaTF);
