import styled from "styled-components";
import {baseTheme} from "../../../../baseTheme";

export const Video = styled.div`
  overflow: hidden;
  position: relative;
  min-width: 318px;
  width: 318px;
  height: 238px;
  max-height: 238px;
  background: linear-gradient(0deg, #697077, #697077);
  box-shadow: 0px 0px 2px rgba(105, 112, 119, 0.06), 0px 2px 2px rgba(105, 112, 119, 0.12);
  min-height: 238px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 8px;
  
  .video-player {
    width: 100%;
    height: 100%;
    position: absolute;
    right: 0px;
    top: 0px;
    overflow: hidden;
    border-radius: 8px;
    div {
      overflow: hidden;
      border-radius: 8px;
      video {
        overflow: hidden;
        border-radius: 8px;
      }
    }
  }
  
  .your_video {
    position: absolute;
    top: 16px;
    right: 16px;
    box-sizing: border-box;
    z-index: 10;
    width: 96px; 
    height: 72px;
  }

  img {
    width: 96px;
    height: 96px;
    object-fit: contain;
  }

  .video-btn {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    .micro,
    .cam {
      cursor: pointer;
      width: 40px;
      height: 40px;
      background: rgba(0, 0, 0, 0.8);
      backdrop-filter: blur(16px);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;

      img {
        width: 24px;
        height: 24px;
        object-fit: contain;
        background-color: transparent;
      }
    }

    .micro {
      margin-right: 24px;
    }
  }

  @media ${baseTheme.media.medium}, ${baseTheme.media.heightMedium} {
    &.video-container {
      min-width: auto;
      width: 100%;
      height: unset;
      max-height: unset;
    }

    .your_video {
      top: 8px;
      right: 8px;
    }
  }
`
