import React, {useState, useEffect} from "react";

import AdminModalTask from "../../../adminModalTask/adminModalTask";
import MainInput from "../../../../inputs/mainInput/mainInput";
import MainButton from "../../../../buttons/mainButton/mainButton";
import ColumnBlock from "./columnBlock/columnBlock";

import plus from '../../../../../assets/media/icon/plus-blue.svg'

import {
  FormAddBtn,
  FormBtnContainer,
  FormHeadContainer,
  FormMainContainer,
  FormWrap,
  PopupLine
} from "../../../styled";

const WordColl = ({
                    edit,
                    back,
                    close,
                    addTask,
                    editTask
                  }) => {
  const [type, setType] = useState('add');
  const [taskTitle, setTaskTitle] = useState(null);
  const [taskId, setTaskId] = useState(0);
  const [columns, setColumns] = useState([{name: '', words: '', id: 1}]);
  const [columnId, setColumnId] = useState(2);

  useEffect(() => {
    if (!!edit.id) {
      setType('edit');
      setColumns(edit.list_column.map(column => ({...column, words: column.words.join(', ')})));
      setTaskId(edit.id)
      setTaskTitle(edit.title)
      setColumnId(edit.list_column[edit.list_column.length - 1].id + 1);
    }
  }, [])

  const addMoreColumn = () => {
    setColumns([...columns, {name: '', words: '', id: columnId}]);
    setColumnId(columnId + 1);
  }

  const deleteColumn = (id) => {
    setColumns(columns.filter(item => item.id !== id));
  }

  const changeColumnName = (e, id) => {
    setColumns(columns.map(item => item.id === id ? ({
      ...item,
      name: e.target.value
    }) : item));
  }

  const changeColumnWords = (e, id) => {
    setColumns(columns.map(item => item.id === id ? ({
      ...item,
      words: e.target.value
    }) : item))
  }

  const handleCreateTask = (e) => {
    e.preventDefault();
    let listColumns = columns.map(column => ({...column, words: column.words.split(',').map(word => word.trim())}));
    const task = {
      title: taskTitle,
      list_column: listColumns,
      task_type: 'LIST_WORD_COLL',
    };

    addTask(task);
  }

  const handleEditTask = (e) => {
    e.preventDefault();
    let listColumns = columns.map(column => ({...column, words: column.words.split(',').map(word => word.trim())}));
    const task = {
      title: taskTitle,
      list_column: listColumns,
      task_type: 'LIST_WORD_COLL',
    };

    editTask(taskId, task);
  }

  const columnRender = columns.map((item) => {
    return <ColumnBlock
      key={item.id} col={item}
      onChangeName={(e) => changeColumnName(e, item.id)}
      onChangeWords={(e) => changeColumnWords(e, item.id)}
      onDelete={() => deleteColumn(item.id)}
    />
  })

  return (
    <AdminModalTask
      type={type}
      back={back}
      title={'Слова по колонкам'}
      close={close}
    >
      <FormWrap
        onSubmit={(e) => {
          type === 'add' ? handleCreateTask(e) : handleEditTask(e)
        }}
      >
        <FormHeadContainer>
          <MainInput
            className={'task-input_head'}
            type={'text'}
            label={'Условия упражнения'}
            name={'title'}
            required={true}
            defaultValue={taskTitle}
            updateData={e => setTaskTitle(e.target.value)}
          />
        </FormHeadContainer>
        <PopupLine/>
        <FormMainContainer>
          {columnRender}
        </FormMainContainer>
        <PopupLine/>
        <FormBtnContainer>
          <FormAddBtn
            onClick={addMoreColumn}
            type={'button'}
          >
            <img src={plus} alt="+"/>
            <p>Добавить колонку</p>
          </FormAddBtn>
        </FormBtnContainer>
        <PopupLine/>
        <FormBtnContainer>
          <MainButton
            text={type === 'add' ? 'Добавить' : 'Сохранить'}
            width={'full'}
            type={'submit'}
          />
        </FormBtnContainer>
      </FormWrap>
    </AdminModalTask>
  )
}

export default WordColl;
