import React, {useEffect, useState} from "react";

import AdminModalTask from "../../adminModalTask/adminModalTask";
import MainInput from "../../../inputs/mainInput/mainInput";
import MainButton from "../../../buttons/mainButton/mainButton";
import SimpleTextArea from "../../../inputs/simpleTextArea/simpleTextArea";

import * as Style from "../sentenceOfWords/style";
import {FormBtnContainer, FormHeadContainer, FormMainContainer, FormWrap} from "../../styled";

const WriteWord = ({
                     edit,
                     back,
                     close,
                     addTask,
                     editTask
                   }) => {

  const [type, setType] = useState('add');
  const [taskTitle, setTaskTitle] = useState('');
  const [taskText, setTaskText] = useState('');
  const [taskId, setTaskId] = useState(null);

  useEffect(() => {
    if (!!edit.id) {
      setType('edit');
      setTaskText(edit.desc);
      setTaskId(edit.id);
      setTaskTitle(edit.title);
    }
  }, [])

  // создание задания
  const handleCreateTask = (e) => {
    e.preventDefault();
    const task = {
      title: taskTitle,
      desc: e.target.text.value,
      task_type: 'WRITE_WORD',
    };

    addTask(task);
  }

  const handleEditTask = (e) => {
    e.preventDefault();
    const task = {
      title: taskTitle,
      desc: e.target.text.value,
      task_type: 'WRITE_WORD',
    };

    editTask(taskId, task);
  }

  return (
    <AdminModalTask
      type={type}
      back={back}
      title={'Заполнить пропуски'}
      close={close}
    >
      <FormWrap
        onSubmit={(e) => {
          type === 'add' ? handleCreateTask(e) : handleEditTask(e)
        }}
      >
        <FormHeadContainer>
          <MainInput
            className={'task-input_head'}
            type={'text'}
            label={'Условия упражнения'}
            name={'title'}
            required={true}
            defaultValue={taskTitle}
            updateData={e => setTaskTitle(e.target.value)}
          />
          <Style.TextBlock>
            <div className="title">Текст задания</div>
            <div className="desc">
              Запишите в квадратных скобках один или несколько правильных ответов с подсказкой или без.

              Возможны следующие варианты выполнения:
              <ul>
                <li>- видно начальную форму слова (подсказку): [начальная форма/правильный ответ]</li>
                <li>- один правильный вариант без подсказки: [/правильный ответ]</li>
                <li>- несколько правильных вариантов без подсказки: [/правильный ответ/правильный ответ]</li>
              </ul>
            </div>
          </Style.TextBlock>
        </FormHeadContainer>
        <FormMainContainer className={'padding noTopBotPadding'}>
          <SimpleTextArea
            name={'text'}
            required={true}
            placeholder={'Пример: I [/to play/am playing] tennis.'}
            defaultValue={taskText}
          />
        </FormMainContainer>
        <FormBtnContainer>
          <MainButton
            text={type === 'add' ? 'Добавить' : 'Сохранить'}
            width={'full'}
            type={'submit'}
          />
        </FormBtnContainer>
      </FormWrap>
    </AdminModalTask>
  )
}

export default WriteWord;
