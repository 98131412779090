import axiosInstance from "../../service/iTeacherApi";
import {openInfoPopup, setErrorInfoText} from "./infoPopupAction";

export const signIn = () =>{
  return async (dispatch) => {
    try {
      const userResponse = await axiosInstance.get("/users/me/");
      const userData = userResponse.data;
      if (JSON.stringify(userData) !== localStorage.getItem("user")) {
        localStorage.setItem("user", JSON.stringify(userData));
        dispatch ({
          type: 'LOGIN_USER',
          user: userData
        })
      }
    }
    catch (error) {
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('user');
      axiosInstance.defaults.headers['Authorization'] = null;
      window.location.assign('/');
    }
  }
}

export const updateUser = (id, data, closed) =>{
  return async (dispatch) => {
    try {
      await axiosInstance.put(`/users/${id}/`, data)
      const userResponse = await axiosInstance.get("/users/me/");
      const userData = userResponse.data;
      localStorage.setItem("user", JSON.stringify(userData));
      dispatch ({
        type: 'LOGIN_USER',
        user: userData
      })
      closed();
    }
    catch (error) {
      if(error.response.data.email) {
        dispatch(setErrorInfoText(error.response.data.email[0]));
      } else {
        dispatch(setErrorInfoText('Введена некорректная дата рождения.'));
      }
      dispatch(openInfoPopup());
    }
  }
}

const getAllUsers = (users) => {
  return {
    type: 'GET_ALL_USERS',
    users
  }
}

const loginUser = (user) => {
  return {
    type: 'LOGIN_USER',
    user
  }
}

const setTopAlertText = (text) => {
  return {type: 'SHOW_TOP_ALERT', text}
}

export {
  getAllUsers,
  loginUser,
  setTopAlertText
}
