import React, {useEffect, useState} from "react";
import classNames from "classnames"

import VideoBlock from "./videoBlock/videoBlock";
import LessonBlock from "./lessonBlock/lessonBlock";

import {LeftSideBarWrap, CompanionWrap} from './styled';
import ConnectBlock from "./connectBlock/connectBlock";
import MainButton from "../../../components/buttons/mainButton/mainButton";

const LeftSideBar = ({data, user, companion, activeSection, localVideoTrack, remoteUsers, toggleLocalTracksMute}) => {
  const [isOpenModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const [transformBlock, setTransformBlock] = useState({top: 0, left: 0});
  const [touchStart, setTouchStart] = useState({top: 0, left: 0});
  const [windowWidth, setWindowWidth] = useState(0);
  const [isDragging, setDragging] = useState(false);

  useEffect(() => {
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, [])

  useEffect(() => {
    if (windowWidth > 720) {
      setTransformBlock({top: 0, left: 0});
      setOpenModal(false);
    } else {
      setTransformBlock({top: 80, left: 20});
    }
  }, [windowWidth])

  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth)
  }

  const handleTouchStart = (e) => {
    setTouchStart({
      top: e.targetTouches[0].clientY - transformBlock.top,
      left: e.targetTouches[0].clientX - transformBlock.left
    });
    setDragging(true);
  }
  const handleTouchMove = (e) => {
    setTransformBlock({
      top: e.changedTouches[0].clientY - touchStart.top,
      left: e.changedTouches[0].clientX - touchStart.left
    })
  }

  const handleTouchEnd = () => {
    setTouchStart({top: 0, left: 0});
    setDragging(false);
  }

  const handleDragStart = (e) => {
    setTouchStart({
      top: e.clientY - transformBlock.top,
      left: e.clientX - transformBlock.left
    });
    setDragging(true);
  }

  const handleDragEnd = (e) => {
    setTransformBlock({
      top: e.clientY - touchStart.top,
      left: e.clientX - touchStart.left
    });
    setTouchStart({top: 0, left: 0});
    setDragging(false);
  }

  return (
    <LeftSideBarWrap
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onDragStart = {handleDragStart}
      onDragEnd = {handleDragEnd}
      onTouchEnd={handleTouchEnd}
      className={ classNames({
        open: isOpenModal,
        drag: isDragging
      })}
      transformBlock={transformBlock}
      style={!isOpenModal ? {
        transform: `translate3d(${transformBlock.left}px, ${transformBlock.top}px, 0px)`
      } : {}
      }
      draggable={windowWidth <= 720}
    >

      <VideoBlock
        onOpenModal={handleOpenModal}
        companion={companion}
        localVideoTrack={localVideoTrack}
        remoteUsers={remoteUsers}
        toggleLocalTracksMute={toggleLocalTracksMute}
      />
      <div className={"lesson__info-container"}>
        <CompanionWrap>{companion.username}</CompanionWrap>
        <LessonBlock data={data} activeSection={activeSection}/>
        {user.type === "TEACHER" && <ConnectBlock/>}
        <MainButton
          func={handleCloseModal}
          text={'Закрыть'}
        />
      </div>
    </LeftSideBarWrap>
  )
}

export default LeftSideBar;
