import React from 'react';
import PopupCourse from '../popupCourse';

import axiosInstance from "../../../../service/iTeacherApi";
import {useDispatch} from "react-redux";
import {getAllCourses} from "../../../../store/actions/coursesAction";

const PopupEditCourse = ({ userType, course, close, setCourse}) => {
  const dispatch = useDispatch();
  async function editCourse (newCourse) {
    try {
      const data = new FormData();
      data.set("data", JSON.stringify(newCourse));
      const response = await axiosInstance.patch(`/${userType}/courses-library/${course.id}/`, data);
      setCourse(response.data);
      close();
      dispatch(getAllCourses(userType));
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <PopupCourse
      type={'edit'}
      func={editCourse}
      close={close}
      course={course}
    />
  );
}

export default PopupEditCourse;
