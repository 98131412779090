import React from 'react';
import {Navigation, Pagination} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";

import MediaModals from "../../../components/mediaModals/mediaModals";

const TasksContent = ({data, handleCheckTask}) => {

  const renderTaskList = data.map(task => (
      <SwiperSlide key={task.id} className={"taskItem"} tasktype={task.task_type}>
        <div className="section__title">{task.title}</div>
        <MediaModals
          handleCheckTask={handleCheckTask}
          type={task.task_type}
          data={task}
          roomType={'homework'}
        />
      </SwiperSlide>
    )
  )

  return (
    <Swiper
      className={"tasksList"}
      scrollbar={false}
      modules={[Navigation, Pagination]}
      navigation={true}
      allowTouchMove={false}
      pagination={{
        "type": "fraction"
      }}

    >
      {renderTaskList}
    </Swiper>
  );
};

export default TasksContent;
