import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {useHistory} from "react-router";

import Preloader from "../../components/preloader/preloader";
import MainButton from "../../components/buttons/mainButton/mainButton";
import TasksContent from "./tasksContent/tasksContent";

import axiosInstance from "../../service/iTeacherApi";
import {clearHomework, getHomework} from "../../store/actions/homeworksAction";
import {addCheckedTask} from "../../store/actions/tasksCheckedAction";

import {RoomBackLink, RoomBody, RoomHeader, RoomNav, RoomWrap} from "../../components/ui/room/styled";

const HomeworkRoom = (
  {
    id,
    user,
    userType,
    homework,
    getHomework,
    clearHomework,
    addCheckedTask
  }) => {
  console.log(homework)
  const history = useHistory();

  useEffect(() => {
    if (homework.id) clearHomework();
    getHomework(userType, id, redirectHomeworksPage);
    return () => {
      clearHomework();
    };
  }, []);

  const redirectHomeworksPage = () => history.push('/homework');

  const handleCheckTask = async (answer, taskId, additionalInfo) => {
    try {
      const result = {
        answer,
        additional_info: additionalInfo || null,
        homework_id: Number(id)
      };
      const {data} = await axiosInstance.post(`student/homework/${taskId}/check-task/`, result);
      addCheckedTask(data)
    } catch (e) {
      console.log(e);
    }
  }

  const handleCompleteHomework = async () => {
    try {
      await axiosInstance.get(`student/homework/${id}/completed-homework/`);
      redirectHomeworksPage();
    } catch (e) {
      console.log(e);
    }
  }

  const handleVerifyHomework = async () => {
    try {
      await axiosInstance.get(`teacher/homework/${id}/verified-homework/`);
      redirectHomeworksPage();
    } catch (e) {
      console.log(e);
    }
  }

  if (homework.id === undefined) return (
    <RoomWrap>
      <Preloader/>
    </RoomWrap>
  )

  const btnText = homework.status === 'ASSIGNED' && userType === 'student' ? 'Отправить на проверку '
    : homework.status === 'COMPLETE' && userType === 'teacher' ? 'Закончить проверку'
      : 'Закрыть'

  return (
    <RoomWrap>
      <RoomHeader className="homework">
        <div className="titleBlock">
          <RoomBackLink onClick={history.goBack} className="header__arrow"/>
          <p>
            {homework.template.lesson.name}
          </p>
        </div>
        <RoomNav>
          <MainButton
            text={btnText}
            type={'button'}
            width='full'
            func={userType === "teacher" ? handleVerifyHomework : handleCompleteHomework}
          />
        </RoomNav>
      </RoomHeader>
      <RoomBody
        className={'container'}
      >
        <TasksContent
          data={homework.tasks}
          handleCheckTask={handleCheckTask}
        />
      </RoomBody>
    </RoomWrap>
  );
};

const mapStateToProps = ({users, homeworks}) => ({
  user: users.user,
  userType: users.userType,
  homework: homeworks.homework,
});

const mapDispatchToProps = (dispatch) => ({
  getHomework: (userType, id, catchFunc) => dispatch(getHomework(userType, id, catchFunc)),
  clearHomework: () => dispatch(clearHomework()),
  addCheckedTask: (data) => dispatch(addCheckedTask(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeworkRoom);
