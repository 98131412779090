import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";

import Calendar from './calendar/calendar';
import MainDropList from "../../components/inputs/mainDropList/mainDropList";
import AddEventModal from "./addEventModal";
import EditEventModal from "./editEventModal";

import {CalendarWrap, AddEventBtn, SubCaptionBlock, SearchBlock, InputItem, FilterBtn, SelectTodayBtn} from './styled';

import plus from "../../assets/media/icon/plusW.svg";
import search from "../../assets/media/icon/search.svg";
import close from '../../assets/media/icon/close.svg';
import filter from "../../assets/media/icon/filter.svg";

const CalendarSchedule = ({ courses, scheduleList}) => {

  const [isOpenAddEventModal, setIsOpenAddEventModal] = useState(false);
  const [isOpenEditEventModal, setIsOpenEditEventModal] = useState(false);
  const [scheduleData, setScheduleData] = useState(false);

/*  const [calendarType, setCalendarType] = useState([]);
  // получаем вибраный тип календаря
  const selectCalendarType = (value) => {
    setCalendarType(value);
  }

  // передаем список значений в дропдаун
  const dropListName = [
    {name: 'Неделя', value: 'week'},
    {name: 'День', value: 'day'},
    {name: 'Месяц', value: 'month'}
  ]*/

  // открытия модалки на редактирование

  const openAddEventModal = () => {
    setIsOpenAddEventModal(true)
  }

  const updateSchedule = (schedule) => {
    if(schedule.status === 'process') {return};
    setScheduleData(schedule);
    setIsOpenEditEventModal(true);
  }

  const closeEventModal = () => {
    setIsOpenAddEventModal(false);
    setIsOpenEditEventModal(false);
  }

  return (
    <>
      <CalendarWrap>

        <div className="caption">
          <h1>Расписание</h1>

          <div className={'btn_section'}>
            {/*<SelectTodayBtn>Сегодня</SelectTodayBtn>*/}

            <AddEventBtn
              type={'button'}
              onClick={openAddEventModal} >
              Добавить событие
              <img src={plus} alt="icon"/>
            </AddEventBtn>
          </div>

          <FilterBtn>
            <img src={filter} alt="icon"/>
          </FilterBtn>

        </div>

        {/*<SubCaptionBlock>*/}

        {/*  <div className="arrow"/>*/}

        {/*  <SearchBlock>*/}
        {/*    <img src={search} alt="icon"/>*/}
        {/*    <input type="text" placeholder="Поиск"/>*/}

        {/*    <InputItem>*/}
        {/*      Brandon Garcia*/}
        {/*      <img src={close} alt="icon"/>*/}
        {/*    </InputItem>*/}

        {/*  </SearchBlock>*/}

        {/*  <MainDropList*/}
        {/*    name={'calendar'}*/}
        {/*    type={'text'}*/}
        {/*    options={dropListName}*/}
        {/*    onChange={selectCalendarType}*/}
        {/*  />*/}

        {/*</SubCaptionBlock>*/}

        <Calendar
          update={updateSchedule}
          courses={courses}
          schedules={scheduleList}
        />

      </CalendarWrap>

      {
        isOpenAddEventModal && (
          <AddEventModal
            close={closeEventModal}
          />
        )
      }

      {
        isOpenEditEventModal && (
          <EditEventModal
            close={closeEventModal}
            schedule={scheduleData}
          />
        )
      }
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    courses: state.courses.courses,
    scheduleList: state.schedules.scheduleList
  }
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CalendarSchedule);
