import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";

import CheckedBlock from "../checkedBlock/checkedBlock";

import * as Style from './styled'

const MediaText = ({data, userType, handleCheckTask, tasks}) => {
  const [completed, setCompleted] = useState(data.completed?.completed ? '#24A1481A' : false);

  useEffect(() => {
    const taskChecked = tasks.find(i => i.task_id === data.id)
    if (taskChecked) setCompleted(taskChecked.completed ? '#24A1481A' : '#DA1E281A');
  }, [tasks]);

  const handleCheck = () => {
    handleCheckTask(data.completed?.task || data.task || data.desc, data.id)
  };

  const handleFalseCloseChecked = () => setCompleted(false);

  return (
    <>
      <Style.SpanText
        dangerouslySetInnerHTML={{
          __html: (data.completed?.task || data.task || data.desc)
        }}
      />
      {handleCheckTask &&
        <CheckedBlock
          userType={userType}
          completed={completed}
          handleCheck={handleCheck}
          closeFalseChecked={handleFalseCloseChecked}
          isSimple
        />}
    </>
  )
};

const mapStateToProps = ({users, tasksChecked}) => {
  return {
    userType: users.userType,
    tasks: tasksChecked.tasks
  }
};

export default connect(mapStateToProps)(MediaText);
