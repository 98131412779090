import React from 'react'

import * as Style from './styled'

const List = (props) => {
	const {price} = props;
	return (
		<>
		  <Style.Item>Одна тренировка стоит всего {price} рублей!</Style.Item>
		  <Style.Item>Оплаченные тренировки не сгорят в течение года</Style.Item>
		  <Style.Item>Количество клиентов не ограничено</Style.Item>
		  <Style.Item>Используйте готовую библиотеку курсов</Style.Item>
		  <Style.Item>Создавайте свои учебные материалы на платформе ZaSportom</Style.Item>
		</>
	)
}

export default List
