import styled from "styled-components";
import {baseTheme} from "../../../baseTheme";

export const MediaPreviewWrap = styled.div`
  width: 100%;
  position: relative;
  border: 1px solid #DDE1E6;
  box-shadow: 0px 0px 2px rgba(105, 112, 119, 0.06), 0px 2px 2px rgba(105, 112, 119, 0.12);
  border-radius: 8px;
  padding: 19px 24px;

  &.video {
    min-height: 342px;
    height: 100%;
  }

  .bannerVisible {
    width: 100%;
    min-height: 342px;
    max-height: 342px;
    object-fit: cover;
    border-radius: 8px;
  }

  .hidden-input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: none;
    z-index: 3;
    cursor: pointer;
    opacity: 0;
  }

  @media ${baseTheme.media.small} {
    flex-grow: 1;
    overflow: auto;

    .bannerVisible {
      min-height: auto;
      max-height: unset;
    }
  }
`

export const MediaVideoContainer = styled.div`
  margin-bottom: 10px;
  
  & video {
    border-radius: 8px; 
  }
`

export const MediaInfoContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-right: auto;
  position: relative;
  
  .name {
    overflow-wrap: anywhere;
  }
  .desc {
    font-size: 14px;
    line-height: 20px;
    color: #697077;
  }
  
  .delete_btn {
    border: none;
    background-color: transparent;
    cursor: pointer;
    z-index: 5;
    width: 24px;
    min-width: 24px;
    height: 24px;
    padding: 0;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);

    img {
      width: 24px;
      height: 24px;
      min-width: 24px;
      background-color: transparent;
      object-fit: contain;
    }
  }
`
