import React, {Component} from "react";
import Dropzone from "react-dropzone";

import AdminModalTask from "../../adminModalTask/adminModalTask";
import MainButton from "../../../buttons/mainButton/mainButton";
import MainInput from "../../../inputs/mainInput/mainInput";
import Tabs from "../../../ui/tab/tab";
import Button from "../../../buttons/button/button";
import MediaPreview from "../../../ui/mediaPreview/mediaPreview";

import {
    FormBtnContainer,
    FormHeadContainer,
    FormMainContainer,
    FormWrap
} from "../../styled";
import Preloader from "../../../preloader/preloader";
import {ModalLoader} from "../../../ui/modal/styled";

export default class VideoModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            text: '',
            name_video: '',
            type_video: 'LINK',
            link: '',
            video_file: '',
            videoPreviewUrl: '',
            file_size: '',
            type: 'add',
            taskId: '',
            isDownloading: false,
        };
        this.videoTypes = [{id: 1, value: 'LINK', desc: 'Добавить по ссылке'}, {
            id: 2,
            value: 'FILE',
            desc: 'Загрузить файл'
        }];
    }

    componentDidMount() {
        setTimeout(() => {
            this.file = React.createRef();
        }, 1000)
        this.setTypeModal();
    }

    setTypeModal = () => {
        const {edit} = this.props;
        if (!!edit.id) {
            this.setState({
                type: 'edit',
                text: edit.title,
                name_video: edit.name_video,
                type_video: !!edit.link ? "LINK" : edit.type_video,
                video_file: edit.video_file,
                file_size: edit.file_size,
                link: edit.link,
                taskId: edit.id,
            });
        }
    }

    handleSetDownloading = (value) => {
        this.setState({
            isDownloading: value
        })
    }

    createTask = (e) => {
        e.preventDefault();

        const data = new FormData();
        data.set("task_type", "VIDEO");
        data.set("title", this.state.text);
        data.set("name_video", this.state.name_video);

        if (this.state.type_video === "LINK") {
            data.set("link", this.state.link);
        } else {
            data.set("type_video", "FILE");
            data.set("video_file", this.state.video_file);
            data.set("file_size", this.state.file_size);
        }

        this.props.addTask(data, this.handleSetDownloading);
    }

    editTask = (e) => {
        e.preventDefault();

        const data = new FormData();
        data.set("title", this.state.text);
        data.set("name_video", this.state.name_video);

        if (this.state.type_video === "LINK") {
            data.set("link", this.state.link);
        } else {
            if (this.props.edit.video_file !== this.state.video_file) {
                data.set("type_video", "FILE");
                data.set("video_file", this.state.video_file);
                data.set("file_size", this.state.file_size);
            }
        }

        this.props.editTask(this.state.taskId, data, this.handleSetDownloading);
    }

    handleChangeTypeVideo = (type) => {
        this.setState({type_video: type});
    };

    handleVideoChange(e) {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        reader.onloadend = () => {
            this.setState({
                video_file: file,
                videoPreviewUrl: reader.result,
                file_size: Math.round(file.size / 1024 / 1024) + "mb",
            });
        }
        reader.readAsDataURL(file)
    }

    uploadVideo(video) {
        let reader = new FileReader();
        let file = video[0];
        reader.onloadend = () => {
            this.setState({
                video_file: file,
                videoPreviewUrl: reader.result,
                file_size: Math.round(file.size / 1024 / 1024) + "mb",
            });
        }
        reader.readAsDataURL(file);
    }

    deleteVideoItem = () => {
        this.setState({
            video_file: '',
            videoPreviewUrl: '',
            file_size: '',
        });
    }

    render() {
        const {back, close} = this.props;
        const {
            type,
            type_video,
            video_file,
            file_size,
            videoPreviewUrl,
            isDownloading
        } = this.state;

        return (
            <AdminModalTask
                type={type}
                back={back}
                title={'Видео'}
                close={close}
            >
                {isDownloading && (
                    <ModalLoader>
                        <Preloader/>
                    </ModalLoader>
                )}
                <FormWrap onSubmit={(e) => {
                    type === 'add' ? this.createTask(e) : this.editTask(e)
                }}>
                    <FormHeadContainer>
                        <MainInput
                            className={'task-input_head'}
                            type={'text'}
                            label={'Название упражнения'}
                            name={'title'}
                            required={true}
                            defaultValue={this.state.text}
                            updateData={e => this.setState({text: e.target.value})}
                        />
                        <MainInput
                            className={'task-input_name'}
                            type={'text'}
                            label={'Условия упражнения'}
                            name={'name_video'}
                            defaultValue={this.state.name_video}
                            updateData={e => this.setState({name_video: e.target.value})}
                        />
                    </FormHeadContainer>
                    <Tabs
                        value={type_video}
                        items={this.videoTypes}
                        onChangeItem={this.handleChangeTypeVideo}
                    />
                    <FormMainContainer className={'padding noTopBotPadding'}>
                        {type_video === 'LINK' && (
                            <>
                                <MainInput
                                    type={'text'}
                                    label={'Ссылка на видео'}
                                    name={'link'}
                                    required={true}
                                    defaultValue={this.state.link}
                                    updateData={e => this.setState({link: e.target.value})}
                                />
                                <p>Вставьте ссылку на видео из Youtube, Google Drive,
                                    Яндекс.Диск, Vkontakte или Vimeo.</p>
                            </>
                        )}
                        {type_video === 'FILE' && (
                            videoPreviewUrl || video_file ?
                                <MediaPreview
                                    previewUrl={videoPreviewUrl}
                                    file={video_file}
                                    file_size={file_size}
                                    type={'video'}
                                    onClickHandler={this.deleteVideoItem}
                                /> : (
                                    <Dropzone onDrop={acceptedFile => this.uploadVideo(acceptedFile)}
                                        multiple={false}>
                                        {({getRootProps, getInputProps, isDragActive}) => (
                                            <div {...getRootProps()}
                                                 className={`cover__row cover__row_3`}>
                                                {isDragActive ?
                                                    <span
                                                        className="cover__title">Загрузить!</span> :
                                                    <>
                                                        <span className="cover__title">Перетащите файл сюда</span>
                                                        <span
                                                            className="cover__subtitle">или</span>
                                                        <Button
                                                            text="Выбрать файл на устройстве"
                                                            type="cover"
                                                        />
                                                    </>}
                                                <input
                                                    ref={this.file}
                                                    {...getInputProps()}
                                                    accept=".mp4, .webm, .ogg, .ogv"
                                                    className={'hidden-input'}
                                                    id="contained-button-file"
                                                    name={'banner'}
                                                    onChange={(e) => this.handleVideoChange(e)}
                                                />
                                            </div>
                                        )}
                                    </Dropzone>
                                )
                        )}
                        <p className="format-support">Поддерживаются следующие форматы
                            видео: mp4, webm, ogg и ogv</p>
                    </FormMainContainer>
                    <FormBtnContainer>
                        <MainButton
                            text={type === 'add' ? 'Добавить' : 'Сохранить'}
                            width={'full'}
                            type={'submit'}
                            disabled={isDownloading}
                        />
                    </FormBtnContainer>
                </FormWrap>
            </AdminModalTask>
        )
    }

}
