import React, {useState, useEffect} from 'react';
import {connect} from "react-redux";

import Button from "../../components/buttons/button/button";
import ItemCourse from "./itemCourse/itemCourse";

import {
  CoursesListStyleWrap,
  Row1,
  Row2,
  TitleWrap,
  Title,
  ArrowDown,
  SortWrap,
  SortTitle,
  SortSelect,
} from './adminCoursesListStyled';
import {openAddCoursePopup} from "../../store/actions/addCoursePopupAction";

const AdminCoursesList = ({courses, openAddCoursePopup}) => {
  const [sort, setSort] = useState('price');

  // сортировка, ее нужно переделать или починить
  useEffect(() => {
    // sort list
    switch (sort) {

      case 'date':
        sortByDate();
        break;

      case 'popularity':
        sortByPopulatity();
        break;

      case 'price':
        sortByPrice();
        break;

      default:
        break;

    }

  }, [sort]);


  function sortByDate() {

    const updated = [...courses].sort((a, b) => {

      const _a = parseInt(Date.parse(new Date(a.dateOfCreate.replace('.', '-'))));
      const _b = parseInt(Date.parse(new Date(b.dateOfCreate.replace('.', '-'))));

      if (_a < _b) return -1;
      if (_a > _b) return 1;
      return 0;

    });

    // props.getCoursesList(updated);

  }

  function sortByPopulatity() {

    const updated = [...courses].sort((a, b) => {

      if (parseInt(a.counter) > parseInt(b.counter)) return -1;
      if (parseInt(a.counter) < parseInt(b.counter)) return 1;
      return 0;

    });

    // props.getCoursesList(updated);

  }

  function sortByPrice() {

    const updated = [...courses].sort((a, b) => {

      if (parseInt(a.price) < parseInt(b.price)) return -1;
      if (parseInt(a.price) > parseInt(b.price)) return 1;
      return 0;

    });

    // props.getCoursesList(updated);

  }

  return (
    <CoursesListStyleWrap>
      <div className="container">
        <Row1>
          <TitleWrap>
            <Title>Курсы</Title>
            <ArrowDown/>
          </TitleWrap>
          {/*<SortWrap>
            <SortTitle>Сортировать по</SortTitle>
            <SortSelect onChange={(e) => setSort(e.target.value)} value={sort} name="sort-course">
            позже нужно будет включить сортировку
            <SortSelect value={'date'} name="sort-course" readOnly={true}>
              <option className="sort__option" value="date">Дате создания</option>
              <option className="sort__option" value="popularity">Популярности</option>
              <option className="sort__option" value="price">Cтоимости</option>
            </SortSelect>
          </SortWrap>*/}

          <Button
            type="add"
            text="Создать курс"
            func={openAddCoursePopup}
          />

        </Row1>
        <Row2>
          {courses.reverse().map(course =>
            <ItemCourse
              key={course.id}
              course={course}
            />
          )}
        </Row2>
      </div>
    </CoursesListStyleWrap>
  );
};

const mapStateToProps = ({courses}) => {
  return {
    courses: courses.courses
  }
};

const mapDispatchToProps = (dispatch) => ({
  openAddCoursePopup: () => dispatch(openAddCoursePopup()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminCoursesList);
