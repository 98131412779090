import styled from "styled-components";
import {baseTheme} from "../../../baseTheme";

const Form = styled.form`
  width: 100%;
  padding: 0 48px;
  background-color: ${baseTheme.colors.bgWhite};

  .submit {
    background-color: ${props => props.disabledBtn.length <= 0 ? '#DDE1E6' : props.disabledBtn === true ? '#DDE1E6' : '#4F7FFF'}; 
    box-shadow: 0px 0px 1px rgba(105, 112, 119, 0.12), 0px 1px 2px rgba(105, 112, 119, 0.2);
    border-radius: 6px;
    width: 100%;
    padding: 14px 0;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: ${props => props.disabledBtn.length <= 0 ? '#A2A9B0' : props.disabledBtn === true ? '#A2A9B0' : '#fff'};
    border: none;
    cursor: pointer;
    margin-bottom: 0;
  }

  .forget {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 48px;
    background-color: ${baseTheme.colors.bgWhite};

    p {
      background-color: ${baseTheme.colors.bgWhite};
      margin-right: 8px;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: -0.01em;
      color: ${baseTheme.colors.textBlack};
    }

    a {
      font-style: normal;
      font-weight: normal!important;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: -0.01em;
      text-decoration-line: underline;
      color: ${baseTheme.colors.linkBlue};
    }
  }
  
  @media ${baseTheme.media.medium} {
    padding: 0;

    .forget {
      flex-direction: column;
      margin-bottom: 24px;
    }
  }
`

const SocialBLock = styled.div`
  display: flex;
  align-items: center;
  background-color: ${baseTheme.colors.bgWhite};

  a {
    background-color: ${baseTheme.colors.bgWhite};
    border: 1px solid #DDE1E6;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-right: 24px;

    img {
      width: 20px;
      height: 20px;
    }
  }

  & a:last-child {
    margin-right: 0;
  }
`

export {
  SocialBLock,
  Form
}
