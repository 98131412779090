import React from 'react';
import ReactPlayer from "react-player";
import classNames from "classnames";
import {MediaInfoContainer, MediaPreviewWrap, MediaVideoContainer} from "./styled";

import dlt from "../../../assets/media/icon/trash_basket.svg";

const MediaPreview = ({type, previewUrl, file, file_size, onClickHandler}) => {
  return (
    <MediaPreviewWrap className={classNames({video: type === 'video'})}>
      {type === 'video' && (
        <MediaVideoContainer>
          <ReactPlayer
            className='react-player'
            url={previewUrl || file}
            controls={true}
            width={'100%'}
            height={'100%'}
          />
        </MediaVideoContainer>
      )}
      <MediaInfoContainer>
        <div className="name">
          {previewUrl ? file.name : decodeURI(file.split('/')[file.split('/').length - 1])}
        </div>
        <div className="desc">
          {previewUrl ?
            file.type.split('application/vnd.openxmlformats-officedocument.wordprocessingml.')
            : file.split('.')[file.split('.').length - 1]
          }, {file_size}
        </div>
        <button
          onClick={onClickHandler}
          className={'delete_btn'}
        ><img src={dlt} alt="icon"/>
        </button>
      </MediaInfoContainer>
    </MediaPreviewWrap>
  );
};

export default MediaPreview;
