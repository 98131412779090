import styled from "styled-components";
import delIcon from '../../../../../../assets/media/icon/trash_basket.svg'
import {baseTheme} from "../../../../../../baseTheme";

const Wrapper = styled.div`
  width: 100%;
  padding: 24px;
  position: relative;
  border-bottom: 1px solid #DDE1E6;
  
  &:last-child {
    border: none;
  }

  .delete {
    width: 24px;
    min-width: 24px;
    height: 24px;
    background-image: url(${delIcon});
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
    margin-left: 16px;
    margin-bottom: 23px;
    transition: all .3s;
    opacity: 1;
    position: absolute;
    top: 32px;
    right: 24px;
  }

  @media ${baseTheme.media.medium} {
    padding: 16px;

    .delete {
      top: 16px;
      right: 16px;
    }
  }
`

const Title = styled.h3`
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.01em;
  color: #111111;
  margin-bottom: 16px;
`

export {Wrapper, Title}
