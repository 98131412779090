import React from 'react';
import classNames from "classnames";

import {TabContainer, TabItem} from "./styled";


const Tabs = ({value, items, onChangeItem}) => {

  const renderItems = items.map(item =>(
    <TabItem className={classNames({active: item.value === value})} onClick={() => onChangeItem(item.value)} key={item.id}>{item.desc}</TabItem>
  ))

  return (
    <TabContainer>
      {renderItems}
    </TabContainer>
  );
};

export default Tabs;
