import React, {useEffect, useState} from 'react';
import ReactPlayer from "react-player";
import {connect} from "react-redux";
import classNames from "classnames";

import {AudioWrap} from './mediaAudioStyled';
import CheckedBlock from "../checkedBlock/checkedBlock";

const MediaAudio = ({data, handleCheckTask, userType, tasks}) => {
    const [isPlay, setPlay] = useState(false);
    const [completed, setCompleted] = useState(data.completed?.completed ? '#24A1481A' : false);
    const [progress, setProgress] = useState(0)

    useEffect(() => {
        const taskChecked = tasks.find(i => i.task_id === data.id)
        if (taskChecked) setCompleted(taskChecked.completed ? '#24A1481A' : '#DA1E281A');
    }, [tasks]);

    const handleCheck = () => {
        handleCheckTask("", data.id)
    };

    const handleFalseCloseChecked = () => setCompleted(false);


    const handleTogglePlay = () => setPlay(!isPlay);

    return (
        <>
            <AudioWrap>
                <div className="video__wrap video__wrap_1">
                    <ReactPlayer
                        className='react-player'
                        url={data.file}
                        controls
                        width="100%"
                        height="100%"
                        playing={isPlay}
                        onProgress={(data) => setProgress(data.played)}
                    />

                    <span style={{margin: "10px"}}>
                        {data.name_video}

                        <span className="video__subtext">
                            {data.file.split('.')[data.file.split('.').length - 1]}, {data.file_size}
                        </span>
                    </span>

                </div>
            </AudioWrap>
            {handleCheckTask &&
            <CheckedBlock
                userType={userType}
                completed={completed}
                handleCheck={handleCheck}
                closeFalseChecked={handleFalseCloseChecked}
                isSimple
            />}
        </>
    )
}


const mapStateToProps = ({users, tasksChecked}) => {
    return {
        userType: users.userType,
        tasks: tasksChecked.tasks
    }
};

export default connect(mapStateToProps)(MediaAudio);
