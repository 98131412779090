import styled from "styled-components";
import {baseTheme} from "../../../baseTheme";

export const TabContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid #DDE1E6;
  margin-bottom: 24px;
  width: 100%;
  padding: 0 0px 0 24px;


  @media ${baseTheme.media.medium} {
    padding: 0 16px;
  }
`

export const TabItem = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #697077;
  padding-bottom: 20px;
  cursor: pointer;
  background-color: #fff;
  margin-right: 32px;

  &.active {
    color: #000;
    padding-bottom: 19px;
    border-bottom: 2px solid #4F7FFF;
  }
  
  &:last-child {
    margin-right: 0;
  }

  @media ${baseTheme.media.medium} {
    text-align: center;
    margin-right: 16px;
    padding-bottom: 8px;

    &.active {
      padding-bottom: 9px;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`
