import React from 'react';

import * as Style from './styled'

const InfoNoItem = ({children}) => {
  return (
    <Style.InfoNoItem>
      <p>
        {children}
      </p>
    </Style.InfoNoItem>
  );
};

export default InfoNoItem;
