import React, {useState, useEffect} from 'react';
import {Link} from "react-router-dom";
import {connect} from "react-redux";

import {NonoCoursesWrap, AddCourse} from './styled';
import {CoursesList} from "../styled";

import square from '../../../assets/media/image/shedule_empty_zasport.svg'
import library from '../../../assets/media/icon/library.svg';
import AddCourseButton from "../../../components/buttons/addCourseButton/addCourseButton";
import {openAddCoursePopup} from "../../../store/actions/addCoursePopupAction";

const NoneCourses = ({user, openAddCoursePopup}) => {

    return (
        <NonoCoursesWrap>

            <div className="caption">
                <div className="BigTitle">Курсы</div>

                {/*кнопку покупки курсов показываем только тренеру*/}
                {
                    user && user.type === 'TEACHER' && (
                        <AddCourse>
                            <Link to='/courses-library'>Магазин курсов
                                <img src={library} alt="icon"/>
                            </Link>
                        </AddCourse>
                    )
                }

            </div>
            {user.type === 'TEACHER' && (
                <CoursesList>
                    <AddCourseButton clickHandle={openAddCoursePopup}
                                     text={'Создать курс'}/>
                </CoursesList>
            )}

                <img style={{width:"200px",height:"200px"}} src={square} alt="image"/>

            <p>У вас еще нет курсов</p>


            {/*выбрать еще курсы может только тренер*/}

            {
                user && user.type === 'TEACHER' &&
                <div className="small_title">Выберите необходимый курс в библиотеке</div>
            }

        </NonoCoursesWrap>
    )
}

const mapStateToProps = ({users}) => {
    return {
        user: users.user
    }
}

const mapDispatchToProps = (dispatch) => ({
    openAddCoursePopup: () => dispatch(openAddCoursePopup()),
})

export default connect(mapStateToProps, mapDispatchToProps)(NoneCourses);
