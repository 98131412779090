import React from "react";

const Error = () => {
  return (
    <div>
      <h1>Some Error</h1>
      <h2>Sorry</h2>
    </div>
  )
}

export default Error;