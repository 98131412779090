import styled from "styled-components";
import {baseTheme} from "../../baseTheme";

const AddCoursesWrap = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const TitleSection = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 888px;
  padding: 24px 48px;
  justify-content: space-between;

  @media ${baseTheme.media.extraLarge} {
    padding: 24px;
  }

  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 36px;
  }

  @media ${baseTheme.media.medium} {
    justify-content: flex-start;
    padding: 16px;
    
    h1 {
      font-size: ${baseTheme.fz.titlePageSmall};
      line-height: ${baseTheme.lh.titlePageSmall};
    }
  }
`

const TabsHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid #DDE1E6;
  margin-bottom: 32px;
`

const TabsHeadNav = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #697077;
  padding-bottom: 20px;
  cursor: pointer;

  &.tabs-active {
    color: #000;
    padding-bottom: 18px;
    border-bottom: 2px solid #4F7FFF;
  }

  &:first-child {
    margin-right: 32px;
  }
`

const TabsBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-grow: 1;
  overflow-y: auto;
`

const CommonWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 204px);
  width: 100%;
  gap: 32px 24px;
  justify-content: space-between;
  max-width: 888px;
  padding: 8px 48px 24px;

  @media ${baseTheme.media.extraLarge} {
    padding: 8px 24px 24px;
  }
  
  @media ${baseTheme.media.medium} {
    grid-template-columns: repeat(auto-fit, 156px);
    gap: 16px;
    padding: 8px 16px 16px;
  }

  @media ${baseTheme.media.small} {
    justify-content: center;
  }
`

const BgWrap = styled.div`
  position: relative;
  width: 204px;
  height: 204px;
  background: ${props => props.bgType === 'image' ? `url(${props.bgImage})` : props.bg};
  border-radius: 8px;
  padding: 15px;
  display: flex;
  align-items: flex-end;
  margin-bottom: 16px;

  h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 32px;
    letter-spacing: -0.01em;
    color: #fff;
    background-color: transparent;
  }

  .firstLetter {
    font-style: normal;
    font-weight: 800;
    font-size: 220px;
    line-height: 100%;
    background: transparent;
    position: absolute;
    bottom: -38px;
    left: -7px;
    letter-spacing: -0.01em;
    color: rgba(255, 255, 255, .1);
  }

  @media ${baseTheme.media.medium} {
    height: 156px;
    width: 156px;
    min-height: auto;

    h3 {
      font-size: ${baseTheme.fz.titlePopupSmall};
      line-height: ${baseTheme.lh.titlePopupSmall};
    }

    .firstLetter {
      font-size: 156px;
    }
  }
`

const TemplateItemWrap = styled.div`
  width: 100%;
  height: 100%;

  a {
    display: block;
    text-decoration: none;
  }

  .title {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: #111111;
  }

  .desc {
    color: #697077;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.01em;
  }

  .price {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: -0.01em;
    color: #111111;
    margin-top: 8px;
  }


  @media ${baseTheme.media.medium} {
    .price {
      font-size: 18px;
      line-height: 24px;
    }
  }
`

const Caption = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`

const AddCourse = styled.button`
  background: #4F7FFF;
  box-shadow: 0px 0px 1px rgba(105, 112, 119, 0.12), 0px 1px 2px rgba(105, 112, 119, 0.2);
  border-radius: 6px;
  border: none;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  cursor: pointer;

  img {
    background-color: transparent;
    width: 24px;
    height: 24px;
    object-fit: contain;
    object-position: center;
    margin-right: 8px;
  }

  p {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    text-decoration: none;
    background-color: transparent;
    align-items: center;
    display: flex;
    flex-direction: row-reverse;
    padding: 14px 20px;
  }

  @media ${baseTheme.media.medium} {
    display: none;
  }
`

const AddCourseMobile = styled.button`
  background: transparent;
  border: none;
  position: relative;
  display: none;
  align-items: center;
  flex-direction: row-reverse;
  cursor: pointer;
  margin-left: 10px;

  img {
    background-color: transparent;
    width: 22px;
    height: 22px;
    object-fit: contain;
    object-position: center;
  }

  a {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    text-decoration: none;
    background-color: transparent;
    align-items: center;
    display: flex;
    flex-direction: row-reverse;
  }

  @media ${baseTheme.media.medium} {
    display: flex;
  }
`

export {
  AddCourse,
  Caption,
  AddCoursesWrap,
  TitleSection,
  TabsHead,
  TabsHeadNav,
  TabsBody,
  CommonWrap,
  BgWrap,
  TemplateItemWrap,
  AddCourseMobile
}
