import React, {Component} from "react";
import './react-draft-wysiwyg.css';
import {Editor} from "react-draft-wysiwyg";
import {EditorState, ContentState, convertFromHTML} from "draft-js";
import {stateToHTML} from "draft-js-export-html";

import bold from "../../../../assets/media/icon/bold.svg";
import italic from "../../../../assets/media/icon/italic.svg";
import underline from "../../../../assets/media/icon/underline.svg";
import unordered from "../../../../assets/media/icon/mark_list.svg";
import link from "../../../../assets/media/icon/link.svg";
import image from "../../../../assets/media/icon/photo.svg";

import {uploadFile} from "../../../../service/iTeacherApi";
import {
    FormBtnContainer,
    FormHeadContainer,
    FormMainContainer,
    FormWrap
} from "../../styled";
import AdminModalTask from "../../adminModalTask/adminModalTask";
import MainButton from "../../../buttons/mainButton/mainButton";
import MainInput from "../../../inputs/mainInput/mainInput";
import NoteInput from "../../../inputs/noteInput/noteInput";

export default class TextModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            text: '',
            type: 'add',
            taskId: 0,
            editorState: EditorState.createEmpty(),
        }
    }

    componentDidMount() {
        this.setTypeModal();
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
            editorContentHtml: stateToHTML(editorState.getCurrentContent()),
        });
    };

    setTypeModal = () => {
        const {edit} = this.props;
        if (!!edit.id) {
            const editorState = EditorState.createWithContent(
                ContentState.createFromBlockArray(
                    convertFromHTML(edit.desc)
                )
            )
            this.setState({
                type: 'edit',
                text: edit.title,
                taskId: edit.id,
                editorState
            });
        }
    }

    createTask = (e) => {
        e.preventDefault();
        const task = {
            title: this.state.text,
            desc: this.state.editorContentHtml,
            task_type: 'TEXT',
        };
        this.props.addTask(task);
    }

    editTask = (e) => {
        e.preventDefault();
        const task = {
            title: this.state.text,
            desc: this.state.editorContentHtml,
            task_type: 'TEXT',
        };
        this.props.editTask(this.state.taskId, task);
    }

    render() {
        const {back, close} = this.props;
        const {type, editorState} = this.state;

        return (
            <AdminModalTask
                type={type}
                back={back}
                title={'Текст'}
                close={close}
            >
                <FormWrap onSubmit={(e) => {
                    type === 'add' ? this.createTask(e) : this.editTask(e)
                }}>
                    <FormHeadContainer>
                        <MainInput
                            className={'task-input_head'}
                            type={'text'}
                            label={'Название упражнения'}
                            name={'title'}
                            required={true}
                            defaultValue={this.state.text}
                            updateData={e => this.setState({text: e.target.value})}
                        />
                    </FormHeadContainer>

                    <FormMainContainer className={'padding noTopBotPadding'}>
                        <Editor
                            defaultEditorState={editorState}
                            editorState={editorState}
                            wrapperClassName="wrapper-class"
                            editorClassName="editor-class"
                            toolbarClassName="toolbar-class"
                            onEditorStateChange={this.onEditorStateChange}
                            toolbar={{
                                options: ['inline', 'image', 'link', 'list',],
                                inline: {
                                    inDropdown: false,
                                    className: 'media icon_font',
                                    component: undefined,
                                    dropdownClassName: undefined,
                                    options: ['bold', 'italic', 'underline'],
                                    bold: {icon: bold, className: undefined},
                                    italic: {icon: italic, className: undefined},
                                    underline: {icon: underline, className: undefined},
                                },
                                list: {
                                    inDropdown: false,
                                    className: 'media icon_list',
                                    component: undefined,
                                    dropdownClassName: undefined,
                                    options: ['unordered', 'ordered'],
                                    unordered: {icon: unordered, className: undefined},
                                    //ordered: { icon: ordered, className: undefined },
                                },
                                // textAlign: {
                                //   inDropdown: false,
                                //   className: 'media icon_text',
                                //   component: undefined,
                                //   dropdownClassName: undefined,
                                //   options: ['left', 'center', 'right'],
                                //   left: {icon: left, className: undefined},
                                //   center: {icon: center, className: undefined},
                                //   right: {icon: right, className: undefined},
                                //   //justify: { icon: justify, className: undefined },
                                // },
                                link: {
                                    inDropdown: false,
                                    className: 'media icon_link',
                                    component: undefined,
                                    popupClassName: undefined,
                                    dropdownClassName: undefined,
                                    showOpenOptionOnHover: true,
                                    defaultTargetOption: '_self',
                                    options: ['link'],
                                    link: {icon: link, className: undefined},
                                    //unlink: { icon: unlink, className: undefined },
                                    linkCallback: undefined
                                },
                                image: {
                                    icon: image,
                                    className: 'media',
                                    component: undefined,
                                    popupClassName: undefined,
                                    urlEnabled: true,
                                    uploadEnabled: true,
                                    alignmentEnabled: false,
                                    uploadCallback: uploadFile,
                                    alt: {present: true, mandatory: false},
                                    previewImage: true,
                                    inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                                    defaultSize: {
                                        height: 'auto',
                                        width: 'auto',
                                    },
                                },
                            }}
                        />
                    </FormMainContainer>
                    <FormBtnContainer>
                        <MainButton
                            text={type === 'add' ? 'Добавить' : 'Сохранить'}
                            width={'full'}
                            type={'submit'}
                        />
                    </FormBtnContainer>
                </FormWrap>
            </AdminModalTask>
        )
    }
}
