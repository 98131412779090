import React, {useEffect, useState} from "react";

import AdminModalTask from "../../adminModalTask/adminModalTask";
import MainInput from "../../../inputs/mainInput/mainInput";
import MainButton from "../../../buttons/mainButton/mainButton";
import SimpleTextArea from "../../../inputs/simpleTextArea/simpleTextArea";
import * as Style from "../sentenceOfWords/style";

import {FormBtnContainer, FormHeadContainer, FormMainContainer, FormWrap} from "../../styled";

const TransferWords = ({
                         edit,
                         back,
                         close,
                         addTask,
                         editTask
                       }) => {
  const [type, setType] = useState('add');
  const [taskTitle, setTaskTitle] = useState('');
  const [taskText, setTaskText] = useState('');
  const [taskId, setTaskId] = useState(null);

  useEffect(() => {
    if (!!edit.id) {
      setType('edit');
      setTaskText(edit.desc);
      setTaskId(edit.id);
      setTaskTitle(edit.title);
    }
  }, [])

  const handleCreateTask = (e) => {
    e.preventDefault();
    const task = {
      title: taskTitle,
      desc: e.target.text.value,
      task_type: 'TRANSFER_WORDS',
    };

    addTask(task);
  }

  const handleEditTask = (e) => {
    e.preventDefault();
    const task = {
      title: taskTitle,
      desc: e.target.text.value,
      task_type: 'TRANSFER_WORDS',
    };

    editTask(taskId, task);
  }

  return (
    <AdminModalTask
      type={type}
      back={back}
      title={'Перенести слова'}
      close={close}
    >
      <FormWrap
        onSubmit={(e) => {
          type === 'add' ? handleCreateTask(e) : handleEditTask(e)
        }}
      >
        <FormHeadContainer>
          <MainInput
            className={'task-input_head'}
            type={'text'}
            label={'Условия упражнения'}
            name={'title'}
            required={true}
            defaultValue={taskTitle}
            updateData={e => setTaskTitle(e.target.value)}
          />
          <Style.TextBlock>
            <div className="title">Текст задания</div>
            <div className="desc">Напишите текст. Слова и фразы, которые нужно вставить из рамочки, заключите в
              квадратные
              скобки. В конце каждого предложения ставьте точку или запятую.
            </div>
          </Style.TextBlock>
        </FormHeadContainer>
        <FormMainContainer className={'padding noTopBotPadding'}>
          <SimpleTextArea
            name={'text'}
            required={true}
            placeholder={'Пример: I like [walking] in the park in the morning.'}
            defaultValue={taskText}
          />
        </FormMainContainer>
        <FormBtnContainer>
          <MainButton
            text={type === 'add' ? 'Добавить' : 'Сохранить'}
            width={'full'}
            type={'submit'}
          />
        </FormBtnContainer>
      </FormWrap>
      {/*children*/}
    </AdminModalTask>
  )
}

export default TransferWords;
