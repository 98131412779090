import React from "react";
import {useDispatch, useSelector} from "react-redux";

import {closeInfoPopup} from "../../store/actions/infoPopupAction";

import {InfoPopupWrap, InfoPopupSuccessBody, InfoPopupSuccessBtn} from './infoPopupStyled';
import successIcon from "../../assets/media/icon/succes-icon.svg"
import errorIcon from "../../assets/media/icon/error-icon.svg"

const InfoPopup = () => {
  const {text, isSuccess} = useSelector(state => state.infoPopup);
  const dispatch = useDispatch();

  const handleClosePopup = () => {
    dispatch(closeInfoPopup());
  }

  return(
    <InfoPopupWrap>
      <InfoPopupSuccessBody>
        <img src={isSuccess? successIcon : errorIcon} alt="Иконка статуса выполнения действия"/>
        <h2>{text}</h2>
        <InfoPopupSuccessBtn
          onClick={handleClosePopup}
          className={'changePass'}>Ок</InfoPopupSuccessBtn>
      </InfoPopupSuccessBody>
    </InfoPopupWrap>
  )
}
export default InfoPopup;
