import React, {useEffect, useState} from 'react';

import {ModalContainer, ModalFieldset, ModalForm, ModalWrapper} from "../../ui/modal/styled";

import MainButton from "../../buttons/mainButton/mainButton";
import MainDropList from "../../inputs/mainDropList/mainDropList";

import closed from "../../../assets/media/icon/close.svg";

const AddHomeworkModal = ({data, courses, lessons, onNextForm, onSelectCourse, onSelectLesson, closeModal}) => {
  const [courseOptions, setCourseOptions] = useState([]);
  useEffect(() => {
    if (courses?.length > 0) setCourseOptions(courses?.filter(i => i?.status === 'Active'));
  }, [courses])

  return (
    <ModalWrapper zIndex={20}>
      <ModalContainer>
        <img onClick={closeModal} className={'closed'} src={closed} alt="icon"/>
        <div className="caption">
          <h2 className={'title'}>Назначить домашнее задание</h2>
        </div>
        <ModalForm onSubmit={onNextForm}>
          <ModalFieldset>
            <MainDropList
              label={'Выберите курс'}
              name={'course'}
              required={true}
              options={courseOptions}
              onChange={onSelectCourse}
              defaultId={data.course}
            />
            <MainDropList
              label={'Выберите занятие'}
              name={'lesson'}
              type={'text'}
              options={lessons}
              defaultId={data.lesson}
              onChange={onSelectLesson}
            />
          </ModalFieldset>
          <MainButton
            text='Далее'
            type='submit'
            width='full'
            disabled={!data.course || !data.lesson}
          />
        </ModalForm>
      </ModalContainer>
    </ModalWrapper>
  );
};

export default AddHomeworkModal;
