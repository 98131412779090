import React, {useEffect, useRef, useState} from 'react';

import ModalCard from './ModalCard';
import {SmallCardWrap} from "./styled";
import ava from '../../../assets/media/icon/avatar.svg'

import axiosInstance from "../../../service/iTeacherApi";
import {useSelector} from "react-redux";

const SmallCard = ({update, event}) => {
  const [course, setCourse] = useState({});
  const {user} = useSelector(state => state.users);
  const [modalCard, setModalCard] = useState(false);
  const selectListEl = useRef({});

  // ширина эмейла студента
  const [titleW, setTitleW] = useState(0);

  useEffect(() => {
    setTitleWidthOnResize();
    window.addEventListener('resize', setTitleWidthOnResize);
  }, [])

  const setTitleWidthOnResize = () => {
    try {
      if (window.innerWidth > 991) {
        const k = window.innerWidth < 1260 ? 64 : 68;
        const width = selectListEl.current.parentNode.parentNode.parentNode.parentNode.childNodes[0].childNodes[0].childNodes[1].clientWidth - k;
        setTitleW(width)
      }
    } catch (e) {
      console.error(e);
    }
  }

  const closeCardModal = () => {
    setModalCard(false)
  }

  const closeOutsideClick = (e) => {
    if (e.target !== selectListEl.current && !selectListEl?.current?.contains(e.target)) {
      setModalCard(false);
    }
  }

  useEffect(() => {
    document.addEventListener("click", closeOutsideClick);
    return () => {
      document.removeEventListener("click", closeOutsideClick);
    }
  }, []);

  return (
    <>
      {
        event.status !== 'deleted' && (
          <>
            <SmallCardWrap
              titleW={titleW}
              id={event.id}
              ref={selectListEl}
              onClick={() => setModalCard(true)}
            >
              {/*<div className="indicator"/>*/}
              <img src={event.student && event.student.user.photo ? event.student.user.photo : ava} alt=""
                   className="photo"/>
              <div className="title">{event.student && (event.student.user.username || event.student.user.email)}</div>
            </SmallCardWrap>

            {
              modalCard && (
                <ModalCard
                  update={update}
                  event={event}
                  close={closeCardModal}
                />
              )
            }
          </>
        )
      }

    </>
  )
}

export default SmallCard;
