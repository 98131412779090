import React, {useEffect, useState} from "react";

import AdminModalTask from "../../adminModalTask/adminModalTask";
import MainInput from "../../../inputs/mainInput/mainInput";
import MainButton from "../../../buttons/mainButton/mainButton";
import SimpleTextArea from "../../../inputs/simpleTextArea/simpleTextArea";
import * as Style from "./style";
import {FormBtnContainer, FormHeadContainer, FormMainContainer, FormWrap} from "../../styled";

const SentenceOfWords = ({
                           edit,
                           back,
                           close,
                           taskType,
                           addTask,
                           editTask
                         }) => {
  const [type, setType] = useState('add');
  const [taskTitle, setTaskTitle] = useState('');
  const [taskText, setTaskText] = useState('');
  const [taskId, setTaskId] = useState(null);

  useEffect(() => {
    if (!!edit.id) {
      setType('edit');
      setTaskText(edit.desc);
      setTaskId(edit.id);
      setTaskTitle(edit.title);
    }
  }, [])

  const handleCreateTask = (e) => {
    e.preventDefault();
    const task = {
      title: taskTitle,
      desc: e.target.text.value,
      task_type: taskType === 'text' ? 'COLLECT_TEXT' : 'SENTENCE',
    };

    addTask(task);
  }

  const handleEditTask = (e) => {
    e.preventDefault();
    const task = {
      title: taskTitle,
      desc: e.target.text.value,
      task_type: taskType === 'text' ? 'COLLECT_TEXT' : 'SENTENCE',
    };

    editTask(taskId, task);
  }

  return (
    <AdminModalTask
      type={type}
      back={back}
      title={taskType === 'text' ? 'Составить текст' : 'Составить предложение'}
      close={close}
    >
      {/*children*/}
      <FormWrap
        onSubmit={(e) => {
          type === 'add' ? handleCreateTask(e) : handleEditTask(e)
        }}
      >
        <FormHeadContainer>
          <MainInput
            className={'task-input_head'}
            type={'text'}
            label={'Условия упражнения'}
            name={'title'}
            required={true}
            defaultValue={taskTitle}
            updateData={e => setTaskTitle(e.target.value)}
          />
          <Style.TextBlock>
            <div className="title">Текст задания</div>
            <div className="desc">
              {taskType === 'text' ?
                'Напишите предложения и разделите их слэшем. Предложения автоматически перемешаются после сохранения.' :
                'Напишите предложения и разделите слова слэшем. Слова автоматически перемешаются после сохранения.'}
            </div>
          </Style.TextBlock>
        </FormHeadContainer>
        <FormMainContainer className={'padding noTopBotPadding'}>
          <SimpleTextArea
            name={'text'}
            required={true}
            placeholder={`Example: ${taskType === 'text' ? 'We moved to California last summer./ We are not going to move anymore.' : 'We/moved/to/California/last/summer'}`}
            defaultValue={type === 'add' ? "" : taskText}
          />
        </FormMainContainer>
        <FormBtnContainer>
          <MainButton
            text={type === 'add' ? 'Добавить' : 'Сохранить'}
            width={'full'}
            type={'submit'}
          />
        </FormBtnContainer>
      </FormWrap>
      {/*children*/}
    </AdminModalTask>
  )
}

export default SentenceOfWords;
