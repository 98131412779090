import {monthNames} from "./monthes";

export const getLessonTimeString = (dateValue, isShort) => {
  const date = new Date(dateValue);
  date.setMinutes(0);
  date.setSeconds(0);
  const dateArray = date.toLocaleDateString().split('.');
  const dateDay = dateArray[0]
  const dateMonth = monthNames[dateArray[1] - 1]
  const timeArray = date.toLocaleTimeString().split(':');
  return `${dateDay} ${dateMonth}, ${timeArray[0]}:${timeArray[1]}${!isShort ? ` - ${(Number(timeArray[0]) + 1) < 10? `0${(Number(timeArray[0]) + 1)}`: (Number(timeArray[0]) + 1) < 24 ? Number(timeArray[0]) + 1 : '00'}:00` : ''}`
}

export const getCreateTimeString = (dateValue) => {
  const date = new Date(dateValue);
  date.setSeconds(0);
  const dateArray = date.toLocaleDateString().split('.');
  const dateDay = dateArray[0]
  const dateMonth = monthNames[dateArray[1] - 1]
  const timeArray = date.toLocaleTimeString().split(':');
  return `${dateDay} ${dateMonth}, ${timeArray[0]}:${timeArray[1]}`
}

export const getLessonTime = (dateValue, timeValue) => {
  const date = new Date(dateValue);
  date.setHours((timeValue.split(':'))[0]);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date
}

export const getBeforeLessonTimeStart = (timeToStart) => {
  const addZero = (number) => {
    return parseInt(number) < 10 ? `0${number}` : number;
  }

  function addDayWord(number) {
    const cases = [2, 0, 1, 1, 1, 2];
    const titles = ['день', 'дня', 'дней'];
    return `${number} ${titles[ (number%100>4 && number%100<20)? 2 : cases[(number%10<5)?number%10:5] ]} `;
  }

  let day = timeToStart / (3600000 * 24);
  day = day.toString().split('.')[0];

  let hour = timeToStart / 3600000;
  hour = hour - (parseInt(day) * 24)
  hour = hour.toString().split('.')[0];

  let minute = timeToStart / 60000;
  minute = minute - (parseInt(hour) * 60) - (parseInt(day) * 24 * 60)
  minute = minute.toString().split('.')[0];

  let second = timeToStart / 1000;
  // отинмаем минуты из секунд
  second = second - (parseInt(timeToStart / 60000) * 60);
  second = second.toString().split('.')[0];
  return `${day !== '0' ? addDayWord(day) : ''}${addZero(hour)}:${addZero(minute)}:${addZero(second)}`
}
