import axiosInstance from "../../service/iTeacherApi";
import {CLEAR_STUDENT, GET_ALL_STUDENTS, GET_STUDENT} from "../reducers/studentsReducer";

export const getAllStudents = () => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get('/teacher/students/');
      dispatch({type: GET_ALL_STUDENTS, payload: response.data});
    } catch (error) {
      console.log(error)
    }
  }
}

export const getStudent = (id) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(`/teacher/students/${id}/`);
      dispatch({type: GET_STUDENT, payload: response.data});
    } catch (error) {
      console.log(error)
    }
  }
}


export const clearStudent = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_STUDENT })
  }
}
