import styled from "styled-components";

import closeIcon from './media/close.svg'
import {baseTheme} from "../../../baseTheme";

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  z-index: 5000000;
  background-color: rgba(0, 0, 0, .24);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;

  @media ${baseTheme.media.medium} {
    padding: 16px;
  }
`

const Body = styled.form`
  padding: 24px 0;
  background: #FFFFFF;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.18), 0 3px 5.5px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  z-index: 500;
  position: relative;
  max-height: 100%;
  overflow: hidden;
  width: 100%;
  max-width: 660px;
  display: flex;
  flex-direction: column;
  
  .body-container {
    overflow-y: auto;
    padding: 0 24px;
  }

  button {
    width: calc(100% - 48px);
    margin: 16px auto 0;
  }

  @media ${baseTheme.media.medium} {
    padding: 16px 0;

    .body-container {
      padding: 0 16px;
    }
    
    button {
      width: calc(100% - 32px);
      font-size: 16px;
      line-height: 20px;
      padding: 14px 20px;
    }
  }
`

const Close = styled.div`
  background-image: url(${closeIcon});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  width: 24px;
  height: 24px;
  top: 24px;
  right: 24px;
  cursor: pointer;

  @media ${baseTheme.media.medium} {
    top: 16px;
    right: 16px;
  }
`

export {
  Wrapper,
  Body,
  Close
}
