import React from "react";

import {CheckboxWrap} from "./mediaTestStyled";

const Checkbox = ({data, handleChange}) => {
  return (
    <CheckboxWrap>
      <input name={'test'} className={'checkbox'} type="checkbox" checked={data.result} onChange={handleChange}/>
      <label className="word">{data.value}</label>
    </CheckboxWrap>
  )
}

export default Checkbox;
