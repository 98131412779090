import React from "react";

import ScheduleItem from "./scheduleItem/scheduleItem";

import * as Style from './style'

const List = ({schedules, type}) => {

  // render список событий
  const renderList = schedules.map(event => <ScheduleItem key={event.id} type={type} event={event}/>)

  return (
    <Style.TableWrapper>
      <tbody>
      {renderList}
      </tbody>
    </Style.TableWrapper>
  )
}

export default List;
