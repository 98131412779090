import styled from "styled-components";
import {baseTheme} from "../../../../baseTheme";

const ImageZone = styled.div`
  width: 100%;
  position: relative;

  .bannerVisible {
    width: 100%;
    min-height: 342px;
    max-height: 342px;
    object-fit: cover;
    border-radius: 8px;
  }

  .hidden-input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: none;
    z-index: 3;
    cursor: pointer;
    opacity: 0;
  }

  @media ${baseTheme.media.small} {
    flex-grow: 1;
    overflow: auto;

    .bannerVisible {
      min-height: auto;
      max-height: unset;
    }
  }
`

export {ImageZone}
