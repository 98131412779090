import styled from "styled-components";
import {baseTheme} from "../../../baseTheme";

const EmptyBlock = styled.div`
  display: ${props => props.sentence ? 'block' : 'flex'};
  margin-top: ${props => props.sentence || props.mt ? '16px' : '0'};
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  flex-wrap: wrap;

  @media ${baseTheme.media.small} {
    justify-content: space-between;
  }
`

const EmptyItem = styled.div`
  width: ${props => props.taskType === 'text' ? '300px' : '107px'};
  height: 38px;
  background: #FFFFFF;
  border: 1px solid #DDE1E6;
  border-radius: 100px;
  margin-right: ${props => props.noneMargin ? '0' : '16px'};
  margin-bottom: ${props => props.noneMargin ? '0' : '16px'}; 
  
  ${props => props.isTransfer && {margin: '0 14px 16px'}};

  ${props => props.active
          && parseInt(props.active.task) === parseInt(props.task)
          && props.active.empty === props.empty
          && props.userType === 'teacher'
          && {
            border: '1px solid #885CFF'
          }}

  ${props => props.active
          && parseInt(props.active.task) === parseInt(props.task)
          && props.active.empty === props.empty
          && props.userType === 'student'
          && {
            border: '1px solid #4F7FFF'
          }}
  
  &:last-child {margin-right: 0;}

  @media ${baseTheme.media.small} {
    width: ${props => props.taskType === 'text' ? '100%' : '97px'};
    margin-right: 0;

    ${props => props.isTransfer && {
      height: '34px',
      margin: '0 7px 8px'
    }};
  }
`

export {EmptyBlock, EmptyItem}
