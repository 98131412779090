import React from 'react';
import {connect} from "react-redux";

import MediaText from "../templateMedia/mediaText/mediaText";
import MediaDocument from "../templateMedia/mediaDocument/mediaDocument";
import MediaAudio from "../templateMedia/mediaAudio/mediaAudio";
import MediaVideo from "../templateMedia/mediaVideo/mediaVideo";
import MediaImg from "../templateMedia/mediaImg/mediaImg";
import MediaWordColl from "../templateMedia/mediaWordColl/mediaWordColl";
import MediaTF from "../templateMedia/mediaTF/mediaTF";
import MediaConnects from "../templateMedia/mediaConnects/mediaConnects";
import MediaSentence from "../templateMedia/mediaSentence/mediaSentence";
import MediaTest from "../templateMedia/mediaTest/mediaTest";
import MediaWrite from "../templateMedia/mediaWrite/mediaWrite";
import MediaTransfer from "../templateMedia/mediaTransfer/meidaTransfer";
import MediaNote from "../templateMedia/mediaNote/mediaNote";
import MediaRecord from "../templateMedia/mediaRecord/mediaRecord";
import AudioPlace from "../popupsAdmin/lessonAddExModal/recordAudio/audioPlace/audioPlace";

const MediaModals = ({type, data, user, handleCheckTask, roomType }) => {
  switch (type) {
    case 'TEXT':
      return <MediaText data={data} handleCheckTask={roomType === 'homework' ? handleCheckTask : null}/>

    case 'VIDEO':
      return <MediaVideo data={data} handleCheckTask={roomType === 'homework' ? handleCheckTask : null}/>

    case 'PHOTO':
      return <MediaImg data={data} handleCheckTask={roomType === 'homework' ? handleCheckTask : null}/>

    case 'AUDIO':
      return <MediaAudio data={data} handleCheckTask={roomType === 'homework' ? handleCheckTask : null}/>

    case 'DOCUMENT':
      return <MediaDocument data={data} handleCheckTask={roomType === 'homework' ? handleCheckTask : null}/>

    case 'LIST_WORD_COLL':
      return <MediaWordColl data={data} handleCheckTask={handleCheckTask}/>

    case 'TF':
      return <MediaTF data={data} handleCheckTask={handleCheckTask}/>

    case 'WRITE_WORD':
      return <MediaWrite data={data} handleCheckTask={handleCheckTask}/>

    case 'TRANSFER_WORDS':
      return <MediaTransfer data={data} handleCheckTask={handleCheckTask}/>

    case 'SENTENCE':
      return <MediaSentence data={data} handleCheckTask={handleCheckTask}/>

    case 'COLLECT_TEXT':
      return <MediaSentence data={data} handleCheckTask={handleCheckTask} taskType={'text'}/>

    case 'CONNECT':
      return <MediaConnects data={data} handleCheckTask={handleCheckTask}/>

    case 'TEST':
      return <MediaTest data={data} handleCheckTask={handleCheckTask}/>

    case 'RECORD':
      return user.type === 'ADMIN' ? <AudioPlace/> : <MediaRecord data={data}/>

    case 'NOTE':
      return user.type !== 'STUDENT'? <MediaText data={data}/> : <div/>

    default:
      return <MediaText data={data}/>
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.users.user,
  }
};

export default connect(mapStateToProps)(MediaModals);
