import React from 'react';

import {InfoWrap, InfoTitle, InfoList, InfoIcon} from "./styled";

const InfoBlock = ({children, title, onItemFunc, iconSrc}) => {
  return (
    <InfoWrap>
      <InfoTitle><p>{title}</p> {!!onItemFunc && <InfoIcon src={iconSrc} onClick={onItemFunc}/>}</InfoTitle>
      <InfoList>
        {children}
      </InfoList>
    </InfoWrap>
  );
};

export default InfoBlock;
