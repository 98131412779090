import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";

import HomeWorkItem from './homeWorkItem/homeWorkItem';
import MainDropList from "../../components/inputs/mainDropList/mainDropList";
import ConfirmPopup from "../../components/confirmPopup/confirmPopup";

import {deleteHomework, getFilteredHomeworks} from "../../store/actions/homeworksAction";

import {
    PageFilterBlock,
    PageHeadWrap,
    PageNoItemsWrap,
    PageTitle,
    PageWrap
} from "../../components/ui/page/styled";
import {HomeworkAddInstruction} from "./styled";

import noHomeworksImg
    from '../../assets/media/image/schedule_empty.bc169989d5a5aeddc8c93ebfcb8fb489-2 1.png';

const HomeWork = (
    {
        userType,
        homeworkList,
        students,
        courses,
        getFilteredHomeworks,
        deleteHomework
    }) => {

    const [student, setStudent] = useState({});
    const [course, setCourse] = useState({});
    const [selectedHomework, setSelectedHomework] = useState({});
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
    const history = useHistory();

    useEffect(() => {
        getFilteredHomeworks(userType, `student=${student.id || ''}&course=${course.id || ''}`);
    }, [student, course]);

    const onChangeStudent = (value) => setStudent(value);

    const onChangeCourse = (value) => setCourse(value);

    const handleDeleteHomework = async () => {
        try {
            await deleteHomework(selectedHomework.id);
            getFilteredHomeworks(userType, `student=${student.id || ''}&course=${course.id || ''}`);
            closeAllModal();
        } catch (e) {
            console.log(e);
        }
    };

    const handleOpenDeleteHomeworkModal = (e, homework) => {
        e.stopPropagation();
        setSelectedHomework(homework);
        setIsOpenDeleteModal(true);
    };

    const handleEditHomeworkPush = (e, id) => {
        e.stopPropagation();
        history.push(`/homework/${id}`);
    }

    const closeAllModal = () => {
        setIsOpenDeleteModal(false);
        setSelectedHomework({});
    }
    console.log(homeworkList)
    return (
        <PageWrap>
            <div className='container'>
                <PageHeadWrap>
                    <PageTitle>Дополнительные материалы</PageTitle>
                    <PageFilterBlock>
                        {userType !== 'student' && (
                            <MainDropList
                                gray
                                label={'Клиент'}
                                name={'student'}
                                required={true}
                                options={students}
                                onChange={onChangeStudent}
                                classes={'task-input_head'}
                                allValue
                            />
                        )}
                        <MainDropList
                            label={'Курс'}
                            name={'course'}
                            required={true}
                            options={courses}
                            onChange={onChangeCourse}
                            classes={'task-input_head'}
                            withTeacher={userType === 'student'}
                            allValue
                        />
                    </PageFilterBlock>
                </PageHeadWrap>
                {homeworkList.length > 0
                    ? (
                        <div className="workList">
                            {homeworkList.map((homework) => (
                                <HomeWorkItem
                                    key={homework.id}
                                    data={homework}
                                    userType={userType}
                                    editFunc={userType === 'teacher' ? (e) => handleEditHomeworkPush(e, homework.id) : null}
                                    deleteFunc={userType === 'teacher' ? (e) => handleOpenDeleteHomeworkModal(e, homework) : null}
                                />
                            ))}
                            {userType === 'teacher' && (
                                <HomeworkAddInstruction>
                                    Чтобы добавить дополнительный материал, зайдите в
                                    карточку клиента и нажмите ПЛЮС в окошке с
                                    дополнительными материалами в левом нижнем углу
                                </HomeworkAddInstruction>
                            )}
                        </div>
                    ) : (
                        <PageNoItemsWrap>
                            {userType === 'teacher' && (
                                <HomeworkAddInstruction>
                                    Чтобы добавить дополнительный материал, зайдите в
                                    карточку клиента и нажмите ПЛЮС в окошке с
                                    дополнительными материалами в левом нижнем углу
                                </HomeworkAddInstruction>
                            )}
                            <div style={{display:"flex",justifyContent: "center"}}>
                                <img style={{width: "430px"}} src={noHomeworksImg} alt="image"/>
                            </div>
                            <p>У вас нет дополнительных материалов</p>
                        </PageNoItemsWrap>
                    )}
            </div>
            {isOpenDeleteModal && (
                <ConfirmPopup
                    onConfirm={handleDeleteHomework}
                    onClose={closeAllModal}
                    text={`Вы действительно хотите удалить\u00a0дополнительный материал\u00a0${selectedHomework.template?.lesson?.name}\u00a0?`}
                    successText={'Да, удалить'}
                />
            )}
        </PageWrap>
    )
}

const mapStateToProps = ({users, students, courses, homeworks}) => {
    return {
        user: users.user,
        userType: users.userType,
        students: students.students,
        courses: courses.courses,
        homeworkList: homeworks.homeworkFilteredList
    }
};

const mapDispatchToProps = (dispatch) => ({
    getFilteredHomeworks: (userType, query) => dispatch(getFilteredHomeworks(userType, query)),
    deleteHomework: (homeworkId, query, closeFunc) => dispatch(deleteHomework(homeworkId, query, closeFunc)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeWork);
