const initialState = {
    order: {
        id: null,
    },
    link: '',
    type: '',
    lessonPrice: '',
    orderPaid: false,
    isCheckedOrder: false,
}

export const CREATE_ORDER = 'CREATE_ORDER';
export const GET_PAYMENT_LINK = 'GET_PAYMENT_LINK';
export const GET_LESSON_PRICE = 'GET_LESSON_PRICE';
export const CHECK_ORDER = 'CHECK_ORDER';
export const RESET_ORDER = 'RESET_ORDER';

export const orderReducer = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_ORDER: {
            state = {
                ...state,
                order: action.payload,
            }
            break;
        }
        case GET_PAYMENT_LINK: {
            state = {
                ...state,
                link: action.payload?.PaymentURL,
                order: {
                    id: null,
                },
            }
            break;
        }
        case GET_LESSON_PRICE: {
            state = {
                ...state,
                lessonPrice: action.payload[0]?.price ? Number(action.payload[0]?.price) : null,
            }
            break;
        }
        case CHECK_ORDER: {
            state = {
                ...state,
                orderPaid: action.payload?.paid ,
                isCheckedOrder: true,
            }
            break;
        }
        case RESET_ORDER: {
            state = {...initialState};
            break;
        }
        default: {
            state = {...state}
        }
    }

    return state;
}
