import React from "react";
import { NavLink } from "react-router-dom";

import LoginTabs from "./loginTabs/loginTabs";

import * as Style from "./styled";

import logo from "../../assets/media/image/logo.png";

const Login = () => {
  return (
    <Style.WithOutHeaderContainer>
      <div className={"container"}>
        <Style.SignInModalWrapp>
          <Style.SignInModal>
            <img src={logo} alt="" className={"logo"} />
            <h4>Добро пожаловать 👋</h4>
            <Style.TabsBody>
              <LoginTabs />
            </Style.TabsBody>
          </Style.SignInModal>
          <div className={"registration_block"}>
            Еще не зарегистрированы?{" "}
            <NavLink to="/registration">Зарегистрироваться</NavLink>
          </div>
        </Style.SignInModalWrapp>
      </div>
    </Style.WithOutHeaderContainer>
  );
};

export default Login;
