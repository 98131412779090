import React, {useEffect, useRef, useState} from "react";
import AgoraRTC from "agora-rtc-sdk-ng";
import Centrifuge from "centrifuge";
import {connect} from "react-redux";

import {LessonHeader, LessonNav, LessonBody, LessonWrap} from './styled'
import IconDictionary from "../../assets/media/icon/dictionary.svg";
import MainButton from "../../components/buttons/mainButton/mainButton";
import LeftSideBar from "./leftSideBar/leftSideBar";
import MainContent from "./mainContent/mainContent";
import axiosInstance from "../../service/iTeacherApi";
import {useHistory} from "react-router";
import useAgora from "../../hooks/useAgora";
import {signIn} from "../../store/actions/usersAction";
import {addCheckedTask} from "../../store/actions/tasksCheckedAction";
import {getAllSchedules} from "../../store/actions/schedulesAction";
import Preloader from "../../components/preloader/preloader";
import {RoomBody, RoomHeader, RoomNav, RoomWrap} from "../../components/ui/room/styled";

const wsURL = `${process.env.REACT_APP_WS_URL}`;

const client = AgoraRTC.createClient({codec: 'h264', mode: 'rtc'});

const ClassRoom = ({id, user, signIn, addCheckedTask, getAllSchedules}) => {
    const [activeSection, setActiveSection] = useState(0);
    const [data, setData] = useState({});
    const history = useHistory();
    const [centrifugeRoom, setCentrifugeRoom] = useState({});
    const [channel, setChannel] = useState('');
    const {
        localVideoTrack, leave, join, remoteUsers, toggleLocalTracksMute
    } = useAgora(client);
    const sliderRef = useRef();

    useEffect(async () => {
        let classRoomId
        try {
            const {data} = await axiosInstance.get(`/classroom/connect/${id}/`);
            setData(data);
            setCentrifugeRoom(setConnection(data.name, user.chat_token));
            classRoomId = data.id
        } catch (e) {
            console.log(e);
            history.push('/schedule');
        }
        try {
            const response = await axiosInstance.get(`/agora/get-token/${classRoomId}/`);
            const {token, classroom} = response.data;
            setChannel(classroom);
            await join("1e5872894ddd4dfa821b3c5d86cff61e", classroom, token, user.id);
        } catch (e) {
            console.log(e);
        }
        window.addEventListener('beforeunload', handleCloseClassRoom);
        return () => {
            window.removeEventListener('beforeunload', handleCloseClassRoom);
            window.__ARTC__.__TRACK_LIST__.forEach(track => {
                track.stop();
                track.close();
            })
            handleCloseClassRoom();
        }
    }, []);

    const setConnection = (channel, jwt) => {
        const centrifuge = new Centrifuge(wsURL);
        centrifuge.setToken(jwt);
        centrifuge.subscribe(channel, (res) => handleCentrifugeChannel(res, centrifuge))
            .on("join", function (e) {
                console.log("join", e);
            })
            .on("leave", function (e) {
                console.log("leave", e);
            })
            .on("unsubscribe", function (e) {
                console.log("unsubscribe", e);
            })
            .on("subscribe", function (e) {
                console.log("subscribe", e);
            })
            .on("connect", function (data) {
                console.log("connect", data);
            })
            .on("message", function (data) {
                console.log("message", data);
            })
            .on("disconnect", function (data) {
                console.log("disconnect", data);
            });
        centrifuge.connect();
        return centrifuge
    }

    const handleCentrifugeChannel = ({data}, centrifuge) => {
        switch (data.type) {
            case "task": {
                addCheckedTask(data)
                break
            }
            case "changeSection": {
                setActiveSection(data.activeSection);
                sliderRef.current?.swiper.slideTo(0, 0);
                break
            }
            case "end": {
                if (user.type === 'STUDENT') {
                    window.__ARTC__.__TRACK_LIST__.forEach(track => {
                        track.stop();
                        track.close();
                    })
                    leave().then(() => {
                        centrifuge.disconnect();
                        history.goBack();
                        getAllSchedules();
                        signIn();
                        window.__ARTC__.__TRACK_LIST__.forEach(track => {
                            track.stop();
                            track.close();
                        })
                    })
                }
                break
            }
        }
    }

    const handleCloseClassRoom = () => {
        axiosInstance.get(`/classroom/disconnect/${id}/`);
        leave();
        centrifugeRoom.disconnect();
        signIn();
        history.goBack();
    }

    const handleCompleteClassRoom = () => {
        if (user.type === 'TEACHER') axiosInstance.get(`/classroom/complete-schedule/${id}/`);
        handleCloseClassRoom();
    }

    const handleCheckTask = async (answer, taskId, additionalInfo) => {
        try {
            const result = {
                answer,
                additional_info: additionalInfo || null,
                classroom_id: Number(id)
            };
            await axiosInstance.post(`classroom/check-task/${taskId}/`, result);
        } catch (e) {
            console.log(e);
        }
    }

    const handleChangeNextSection = () => {
        if (activeSection + 1 < data.lesson_id.sections.length) {
            centrifugeRoom.publish(channel, {
                type: 'changeSection',
                activeSection: activeSection + 1
            }).then(function (res) {
                console.log('successfully published', res);
            }, function (err) {
                console.log('publish error', err);
            });
        } else {
            console.log('Все секции пройдены')
        }
    }

    const handleChangePrevSection = () => {
        if (activeSection - 1 >= 0) {
            centrifugeRoom.publish(channel, {
                type: 'changeSection',
                activeSection: activeSection - 1
            }).then(function (res) {
                console.log('successfully published', res);
            }, function (err) {
                console.log('publish error', err);
            });
        } else {
            console.log('Вы на первой секции')
        }
    }

    if (data.id === undefined) return (
        <RoomWrap>
            <Preloader/>
        </RoomWrap>
    )

    return (
        <RoomWrap>
            <RoomHeader>
                <div className="titleBlock">{data.lesson_id.name}</div>
                <RoomNav>
                    <MainButton
                        text={user.type === "TEACHER" ? 'Завершить звонок' : 'Выйти'}
                        type={'button'}
                        width='full'
                        func={handleCompleteClassRoom}
                    />
                </RoomNav>
            </RoomHeader>
            <RoomBody
                className={'container'}
            >
                <LeftSideBar
                    data={data.lesson_id.sections}
                    user={user}
                    companion={user.type === "TEACHER" ? data.student.user : data.teacher.user}
                    activeSection={activeSection}
                    localVideoTrack={localVideoTrack}
                    remoteUsers={remoteUsers}
                    toggleLocalTracksMute={toggleLocalTracksMute}
                />
                <MainContent
                    sliderRefLink={sliderRef}
                    data={data.lesson_id.sections}
                    user={user}
                    activeSection={activeSection}
                    handleCheckTask={handleCheckTask}
                    nextSection={handleChangeNextSection}
                    prevSection={handleChangePrevSection}
                />
            </RoomBody>
        </RoomWrap>
    )
}

const mapStateToProps = ({users}) => {
    return {
        user: users.user
    }
};

const mapDispatchToProps = (dispatch) => ({
    signIn: () => dispatch(signIn()),
    getAllSchedules: () => dispatch(getAllSchedules('student')),
    addCheckedTask: (data) => dispatch(addCheckedTask(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(ClassRoom);
