import styled from "styled-components";
import {baseTheme} from "../../../baseTheme";

const ChooseTemplateModalOverlay = styled.div`
  background-color: rgba(1, 1, 1, .2);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 101;
`

const ChooseTemplateModalBody = styled.div`
  width: 100%;
  max-width: 660px;
  background: #FFFFFF;
  box-shadow: 0px 0px 16px rgba(105, 112, 119, 0.12), 0px 8px 16px rgba(105, 112, 119, 0.16);
  border-radius: 8px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  position: relative;
  padding: 24px 24px 0 24px;
  max-height: 95vh;

  .closed {
    width: 24px;
    height: 24px;
    object-fit: contain;
    cursor: pointer;
    position: absolute;
    top: 24px;
    right: 24px;
  }

  .title {
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 36px;
    letter-spacing: -0.01em;
    color: #000000;
    margin-bottom: 32px;
  }

  @media ${baseTheme.media.medium} {
    max-height: unset;
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 16px 16px 0;
    border-radius: 0;

    .closed {
      top: 16px;
      right: 16px;
    }

    .title {
      font-size: ${baseTheme.fz.titlePopupSmall};
      line-height: ${baseTheme.lh.titlePopupSmall};
      margin-bottom: 16px;
    }
  }
`

const ListBody = styled.div`
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }


  @media ${baseTheme.media.large} {
    justify-content: space-around;
  }
`

const ListItemWrap = styled.button`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: calc(100% / 3 - 18px);
  margin-right: 18px;
  margin-bottom: 24px;
  border: none;
  cursor: pointer;
  background-color: transparent;

  .bGround {
    width: 100%;
    background-color: #DDE1E6;
    border-radius: 8px;
    min-height: 144px;
    margin-bottom: 12px;
    background-image: url(${props => props.bg});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .title {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.01em;
    color: #111111;
    margin-bottom: 0;
  }

  &:nth-child(3n) {
    margin-right: 0px;
  }

  @media ${baseTheme.media.medium} {
    max-width: calc(100% / 2 - 12px);
    margin-right: 17px;

    &:nth-child(3n) {
      margin-right: 17px;
    }

    &:nth-child(2n) {
      margin-right: 0;
    }
    
    &:last-child {
      margin-right: 0;
    }
  }

  @media ${baseTheme.media.extraSmall} {
    max-width: calc(100%);
  }
`

export {ListItemWrap, ListBody, ChooseTemplateModalBody, ChooseTemplateModalOverlay}
