import styled from "styled-components";
import copyIcon from "../../../../assets/media/icon/copy.svg";
import {baseTheme} from "../../../../baseTheme";

export const ConnectWrap = styled.div`
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 0px 2px rgba(105, 112, 119, 0.06), 0px 2px 2px rgba(105, 112, 119, 0.12);
  border-radius: 8px;
  padding: 16px 10px 10px;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  .link-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    box-sizing: border-box;
    position: relative;

    .title {
      font-size: 12px;
      margin-bottom: 10px;
      padding: 0 2px;
      position: absolute;
      top: -9px;
      left: 14px;
      background-color: #FFFFFF;
    }
    
    .link__input {
      width: calc(100% - 35px);
      padding: 10px 8px;
      border: none;
      border-radius: 8px;
    }
    
    .link__copy-btn {
      width: 30px;
      height: 30px;
      padding: 5px;
      box-sizing: border-box;
      margin-right: 5px;
      background-color: transparent;
      background-image: url(${copyIcon});
      background-repeat: no-repeat;
      background-size: 17.5px 20px;
      background-position:  center;
      border: none;
      border-radius: 50%;
      
      :hover {
        background-color: rgba(0, 0, 0, 0.06);
      }
      
      :active {
        transform: scale(.9);
      }
      
      span {
        width: 17.5px;
        height: 20px;

        z-index: 2;
      }
    }
  }

  @media ${baseTheme.media.large} {
    margin-top: 8px;
    margin-bottom: 2px;
  }
`
