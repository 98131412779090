import React, {Component} from "react";

import AudioPlace from "./audioPlace/audioPlace";

import {FormBtnContainer, FormHeadContainer, FormMainContainer, FormWrap} from "../../styled";
import AdminModalTask from "../../adminModalTask/adminModalTask";
import MainInput from "../../../inputs/mainInput/mainInput";
import MainButton from "../../../buttons/mainButton/mainButton";

export default class RecordAudio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: '',
      name: '',
      type: 'add',
      indexTask: 0,
      indexSection: 0,
      indexLesson: 0,
      taskId: '',
    }
  }

  componentDidMount() {
    this.setTypeModal();
  }

  setTypeModal = () => {
    const {edit} = this.props;
    if (!!edit.id) {
      this.setState({
        type: 'edit',
        text: edit.title,
        taskId: edit.id
      });
    }
  }

  // создаем задание
  createTask = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.set("task_type", "RECORD");
    data.set("title", this.state.text);

    this.props.addTask(data);
  }

  editTask = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.set("task_type", "RECORD");
    data.set("title", this.state.text);

    this.props.editTask(this.state.taskId, data);
  }

  render() {
    const {back, close} = this.props;

    return (
      <AdminModalTask
        type={this.state.type}
        back={back}
        title={'Запись аудио'}
        close={close}
      >
        <FormWrap onSubmit={(e) => {
          this.state.type === 'add' ? this.createTask(e) : this.editTask(e)
        }}>
          <FormHeadContainer>
            <MainInput
              className={'task-input_head'}
              type={'text'}
              label={'Условия упражнения'}
              name={'title'}
              required={true}
              defaultValue={this.state.text}
              updateData={e => this.setState({text: e.target.value})}
            />
          </FormHeadContainer>
          <FormMainContainer className={'padding noTopBotPadding'}>
            <AudioPlace/>
          </FormMainContainer>
          <FormBtnContainer>
            <MainButton
              text={this.state.type === 'add' ? 'Добавить' : 'Сохранить'}
              width={'full'}
              type={'submit'}
            />
          </FormBtnContainer>
        </FormWrap>
      </AdminModalTask>
    )
  }
}
