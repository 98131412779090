import React, {useState, useRef, useEffect} from "react";
import classNames from "classnames";

import {DropLIstItems, Wrapper, Label} from "../mainInput/styled";

const MainDropList = ({
                        name,
                        required,
                        errorText,
                        validation,
                        width,
                        options = [{value: '', name: ''}],
                        onChange = () => null,
                        classes,
                        defaultId,
                        label = false,
                        allValue,
                        withTeacher
                      }) => {
  const [showStatus, setShowStatus] = useState(false);
  const [disabled, setDisabled] = useState(true);

  // ссылка на селект
  const selectEl = useRef(null);
  const [inputValue, setInputValue] = useState('')
  // ссылка на список селекта
  const selectListEl = useRef(null);

  useEffect(() => {
    if (options.length === 0) {
      setInputValue('');
      setShowStatus(false);
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [options]);

  useEffect(() => {
    if (defaultId) {
      const defaultValue = name === "complexity" ? options.find(el => el[0] === defaultId) : options.find(el => el.id === defaultId)
      if (defaultValue) {
        changeOptions(defaultValue);
      } else {
        setInputValue(name === 'result' ? defaultId : '');
        setShowStatus(false);
      }
    }
  }, [options]);

  const dropdownList = options.map((item, key) => {
    return (
      <li
        className={classNames('listItem', {teacher: withTeacher})}
        key={item.id || key}
        onClick={() => changeOptions(item)}
      >
        <p>
          {name === "student" && item.username}
          {name === "course" && item.course_template.name}
          {name === "lesson" && item.name}
          {name === "result" && item.name}
          {name === "complexity" && item[1]}
        </p>
          {name === "course" && withTeacher && (
            <p className="teacher__name">
              {item.teacher?.user?.username}
            </p>
          ) }
      </li>)
  })

  const showOptions = () => {
    setShowStatus(!showStatus)
  }

  const resetValue = () => {
    setInputValue('');
    onChange({});
    setShowStatus(false);
  }

  const changeOptions = (value) => {
    switch (name) {
      case "student": {
        setInputValue(value.username);
        break;
      }
      case "course": {
        setInputValue(value.course_template.name);
        break;
      }
      case "lesson": {
        setInputValue(value.name);
        break;
      }
      case "result": {
        setInputValue(value.name);
        break;
      }
      case "complexity": {
        setInputValue(value[0]);
        break
      }
      default: {
        setInputValue(value);
      }
    }
    onChange(value);
    setShowStatus(false);
  }

  // закрытие при клике вне списка селекта
  const closeOutsideClick = (e) => {
    // проверяем был ли клик по списку
    if (selectListEl.current && !selectListEl.current.contains(e.target)) {
      // проверяем был ли до клика открыт список и если он открыт то закрываем
      if (selectEl.current && !selectEl.current.contains(e.target)) {
        setShowStatus(false);
      }
    }
  }

  useEffect(() => {
    document.addEventListener("click", closeOutsideClick);
  }, []);


  return (
    <Wrapper
      className={classNames('open', classes, { valid: validation})}
      width={width}
      fakeBg={showStatus}
      label={label.toString()}
      disabled={disabled}
    >
      {
        label && (
          <Label className={` ${(defaultId || inputValue) && 'active'}`}>{label}</Label>
        )
      }
      <div className="select-arrow"/>
      <input
        onClick={showOptions}
        className={'input'}
        name={name}
        required={required}
        value={inputValue}
        ref={selectEl}
        readOnly={true}
      >
      </input>
      {
        showStatus && (
          <DropLIstItems
            ref={selectListEl}
            className={classNames('dropListWrap', {maxHeight: allValue})}
          >
            {allValue && (
              <li
                className={'listItem reset'}
                onClick={resetValue}
              >
                СБРОСИТЬ
              </li>
            )}
            {dropdownList}
          </DropLIstItems>
        )
      }
      {
        validation
          ? <div className={'errorText'}>{errorText}</div>
          : null
      }
    </Wrapper>
  )
}

export default MainDropList;
