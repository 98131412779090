import {SET_SUCCESS_INFO_TEXT, SET_ERROR_INFO_TEXT, OPEN_POPUP, CLOSE_POPUP} from "../reducers/infoPopupReducer";

export const setSuccessInfoText = (data) => {
  return {type: SET_SUCCESS_INFO_TEXT, payload: data}
}

export const setErrorInfoText = (data) => {
  return {type: SET_ERROR_INFO_TEXT, payload: data}
}

export const openInfoPopup = () => {
  return {type: OPEN_POPUP}
}

export const closeInfoPopup = () => {
    return (dispatch) => {
      dispatch({ type: CLOSE_POPUP })
    }
}
