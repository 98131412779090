import styled from "styled-components";
import {baseTheme} from "../../../../../baseTheme";

export const ListLessonDoneWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  background-color: transparent;
  margin: 10px 0;

  .indicator {
    width: 22px;
    min-width: 22px;
    height: 22px;
    border: 2px solid #DDE1E6;
    border-radius: 50%;
    background-color: transparent;
    margin-right: 18px;
  }

  .text {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: ${props => props.done ? '#697077' : props.inProgress ? '#4F7FFF' : '#111'};
    background-color: transparent;
    text-decoration: ${props => props.done ? 'line-through' : 'none'};
  }

  .indicatorDone {
    width: 22px;
    height: 22px;
    min-width: 22px;
    background: #4F7FFF;
    border: 2px solid #4F7FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: 18px;

    img {
      width: 18px;
      height: 18px;
      background-color: transparent;
    }
  }

  .indicatorInProgress {
    width: 24px;
    height: 24px;
    min-width: 24px;
    overflow: hidden;
    margin-right: 18px;
    position: relative;

    svg {
      width: 100%;
    }
  }

  .mask {
    position: absolute;
    background-color: #fff;
    width: 17px;
    height: 17px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }

  @media ${baseTheme.media.large} {
    margin: 0 0 10px;
  }
`
