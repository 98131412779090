import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";

import axiosInstance from "../../service/iTeacherApi";
import {getAllCourses} from "../../store/actions/coursesAction";

import {HeaderWrap, IconArrow, HeaderInput} from "./styled";

const ConstructorHeader = ({name, headerType, userType, courseId,lessonId, setCourse, children, returnModal}) => {
  const [headerName, setHeaderName] = useState(name || '');
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!!name) setHeaderName(name)
  }, [name]);

  const onChangeName = (e) => {
    setHeaderName(e.target.value);
  }

  const saveChangeName = async () => {
    if (headerName === name) return
    try {
      const data = new FormData();
      data.set("data", JSON.stringify({name: headerName}));
      const response = await axiosInstance.patch(`/${userType}/courses-library/${courseId}/`, data);
      setCourse(response.data);
      dispatch(getAllCourses(userType));
    } catch (e) {
      console.log(e);
    }
  }

  const saveChangeNameLesson = async () => {
    if (headerName === name) return
    try {
      const data = {name: headerName}
      await axiosInstance.patch(`/${userType}/lessons/${lessonId}/`, data);
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <HeaderWrap>
      <div className={"container-header"}>
        <IconArrow onClick={!!returnModal ? returnModal : history.goBack} className="header__arrow"/>
        <HeaderInput
          className="header__title"
          value={headerName}
          onChange={(e) => onChangeName(e)}
          onBlur={headerType === 'course' ? saveChangeName: saveChangeNameLesson}
          onKeyPress={(e) => e.key === 'Enter' && headerType === 'course' ? saveChangeName() : e.key === 'Enter' && headerType === 'lesson' ? saveChangeNameLesson() : null}
          title="Нажмите, чтобы редактировать"
        />
        {children}
      </div>
    </HeaderWrap>
  )
}

export default ConstructorHeader;
