import { useState, useCallback } from "react";

export default function useFormAndValidation() {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [isValid, setIsValid] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
    if (name === 'email') {
      if (e.target.validity.typeMismatch || e.target.validity.patternMismatch) {
        e.target.setCustomValidity("Введите Email в формате example@mail.ru");
      } else {
        e.target.setCustomValidity("");
      }
    }
    setErrors({ ...errors, [name]: e.target.validationMessage });

    setIsValid(e.target.closest("form").checkValidity());
    setIsSending(false);
  };

  const resetForm = useCallback(
    (newValues = {}, newErrors = {}, newIsValid = false) => {
      setValues(newValues);
      setErrors(newErrors);
      setIsValid(newIsValid);
    },
    [setValues, setErrors, setIsValid]
  );

  return {
    values,
    handleChange,
    errors,
    isValid,
    isSending,
    resetForm,
    setErrors,
    setValues,
    setIsValid,
    setIsSending,
  };
}
