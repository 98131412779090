import React, {Component} from "react";
import Dropzone from 'react-dropzone';

import Button from "../../../buttons/button/button";
import AdminModalTask from "../../adminModalTask/adminModalTask";
import MainButton from "../../../buttons/mainButton/mainButton";
import MainInput from "../../../inputs/mainInput/mainInput";

import {ImageZone} from './styled'
import {FormBtnContainer, FormHeadContainer, FormMainContainer, FormWrap} from "../../styled";

export default class AddImageModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: '',
      file: '',
      imagePreviewUrl: '',
      type: 'add',
      taskId: '',
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.file = React.createRef();
    }, 1000)
    this.setTypeModal();
  }

  setTypeModal = () => {
    const {edit} = this.props;
    if (!!edit.id) {
      this.setState({
        type: 'edit',
        text: edit.title,
        file: edit.file,
        taskId: edit.id,
        name: edit.name_video,
      });
    }
  }

  createTask = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.set("task_type", "PHOTO");
    data.set("title", this.state.text);
    data.set("file", this.state.file);
    data.set("desc_note",this.state.editorContentNoteHtml)

    this.props.addTask(data);
  }

  editTask = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.set("task_type", "PHOTO");
    data.set("title", this.state.text);

    if (this.props.edit.file !== this.state.file) data.set("file", this.state.file);

    this.props.editTask(this.state.taskId, data);
  }

  // add image function
  _handleImageChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result
      });
    }
    reader.readAsDataURL(file)
  }

  uploadImage(image) {
    let reader = new FileReader();
    let file = image[0];
    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result
      });
    }
    reader.readAsDataURL(file);
  }

  render() {
    const {back, close} = this.props;

    const {imagePreviewUrl, file} = this.state;
    let $imagePreview = null;

    if (imagePreviewUrl || file) {
      $imagePreview = (
        <ImageZone>
          <img alt={'img'} className={'bannerVisible'} src={imagePreviewUrl ? imagePreviewUrl : file}/>
          <input
            ref={this.file}
            accept="image/*"
            className={'hidden-input'}
            id="contained-button-file"
            name={'banner'}
            onChange={(e) => this._handleImageChange(e)}
            type={'file'}
          />
        </ImageZone>
      );
    } else {
      $imagePreview = (
        <Dropzone onDrop={acceptedFile => this.uploadImage(acceptedFile)}>
          {({getRootProps, getInputProps, isDragActive}) => (
            <div {...getRootProps()} className={`cover__row cover__row_3`}>
              {isDragActive
                ? <span className="cover__title">Загрузить!</span>
                : <>
                  <span className="cover__title">Перетащите файл сюда</span>
                  <span className="cover__subtitle">или</span>
                  <Button
                    text="Выбрать файл на устройстве"
                    type="cover"
                  />
                </>}
              <input
                ref={this.file}
                {...getInputProps()}
                accept="image/*"
                className={'hidden-input'}
                id="contained-button-file"
                name={'banner'}
                onChange={(e) => this._handleImageChange(e)}
              />
            </div>
          )}
        </Dropzone>
      );
    }

    return (
      <AdminModalTask
        type={this.state.type}
        back={back}
        title={'Изображение'}
        close={close}
      >
        <FormWrap onSubmit={(e) => {
          this.state.type === 'add' ? this.createTask(e) : this.editTask(e)
        }}>
          <FormHeadContainer>
            <MainInput
              className={'task-input_head'}
              type={'text'}
              label={'Название упражнения'}
              name={'title'}
              required={true}
              defaultValue={this.state.text}
              updateData={e => this.setState({text: e.target.value})}
            />

          </FormHeadContainer>
          <FormMainContainer className={'padding noTopBotPadding'}>
            {$imagePreview}
          </FormMainContainer>
          <FormBtnContainer>
            <MainButton
              text={this.state.type === 'add' ? 'Добавить' : 'Сохранить'}
              width={'full'}
              type={'submit'}
            />
          </FormBtnContainer>
        </FormWrap>
      </AdminModalTask>
    )
  }
}
