import styled from "styled-components";
import {baseTheme} from "../../../../baseTheme";

const HomeWorkItemWrap = styled.tr`
  width: 100%;
  box-shadow: 0 0 2px rgba(105, 112, 119, 0.06), 0px 2px 2px rgba(105, 112, 119, 0.12);
  border-radius: 8px;
  display: grid;
  grid-template-columns: 1fr 0.5fr 0.5fr 0.6fr 1.3fr;
  margin-bottom: 10px;
  align-items: center;
  background-color: #FFFFFF;
  overflow: hidden;
  padding: 0 8px;

  &:last-child {
    margin-bottom: 0;
  }

  td {
    background: #FFFFFF;

    .td-wrapper {
      display: flex;
      align-items: center;
      padding: 16px;

      .alert-text {
        margin-right: 24px;
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.01em;
        color: #4F7FFF;
      }

      .inactive {
        margin: 0;
        color: #DA1E28;
      }

      &--no-flex {
        display: block;
      }

      &--no-padding {
        padding: 0;
      }

      &--end {
        justify-content: flex-end;
      }
    }
  }

  .right {
    .attentionBlock {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      img {
        width: 24px;
        height: 24px;
        object-fit: contain;
        margin-right: 16px;
        background-color: transparent;
      }
    }

    .DoneBlock {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      background-color: transparent;
      margin-right: 32px;
      min-width: 272px;

      img {
        width: 24px;
        height: 24px;
        object-fit: contain;
        margin-right: 16px;
        background-color: transparent;
      }

      .done {
        color: #24A148;
      }
    }

    .StartBlock {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      background-color: transparent;
      flex-direction: row;

      p {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.01em;
        color: #4F7FFF;
        margin-right: 24px;
      }
    }
  }

  .right_new--lesson {
    justify-content: flex-start;
    flex: 1;

    .StartBlock {
      background: #DA1E28;
      border-radius: 100px;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: -0.01em;
      color: #FFFFFF;
      max-height: 36px;
      padding: 14px 20px;

      @media (max-width: 575px) {
        padding: 8px 15px;
        white-space: nowrap;
        font-size: 12px;
        line-height: 12px;
      }
    }
  }

  @media ${baseTheme.media.extraLarge} {
    td {
      .td-wrapper {
        padding: 16px 8px;
      }
    }
  }

  @media ${baseTheme.media.large} {
    padding: 8px;
    grid-template-columns: 1fr 0.5fr 0.5fr;
    position: relative;

    td {
      .td-wrapper {
        padding: 8px;
        width: 100%;

        &--no-flex {
          display: flex;
          flex-direction: column;
          align-items: end;
          
          .label {
            margin-bottom: 4px;
          }

          p {
            max-width: 160px;
            text-align: end;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            display: inline;
          }
        }

        &--end {
          justify-content: space-between;

          button {
            padding: 10px 20px;
          }
        }
      }
    }

    .right {
      &:last-child {
        grid-column: 1/4;
      }

      &_new--lesson {
        position: absolute;
        bottom: 8px;
        right: 180px;

        .StartBlock {
          font-size: 14px;
          line-height: 18px;
          padding: 8px 15px;
        }
      }
    }
  }

  @media ${baseTheme.media.medium} {
    .right {
      &_new--lesson {
        right: 110px;
      }
    }
  }

  @media ${baseTheme.media.small} {
    grid-template-columns: 1fr 1fr;
    padding: 8px 4px;

    td {
      .td-wrapper {
        padding: 8px;
        width: 100%;

        &--no-flex {
          display: flex;
          flex-direction: column;
          align-items: end;

          p {
            max-width: 140px;
          }
        }
      }
    }

    td:nth-child(2) {
      .td-wrapper {
        &--no-flex {
          align-items: start;
        }
      }
    }

    .right {
      &:last-child {
        grid-column: 1/3;
      }

      &_new--lesson {
        position: absolute;
        bottom: unset;
        top: 8px;
        right: 7px;

        .td-wrapper {
          padding: 0;

          .StartBlock {
            padding: 5px 8px;
            font-size: 11px;
            line-height: 12px;
          }
        }
      }
    }
  }

  @media ${baseTheme.media.extraSmall} {
    td {
      .td-wrapper {
        p {
          max-width: 120px;
        }
      }
    }
  }
}
`

const LeftTimes = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.01em;
  color: #DA1E28;
  background-color: transparent;
`

const TeacherCourse = styled.td`
  .label {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: #697077;
  }

  .info {
    display: flex;
    align-items: center;

    img {
      width: 20px;
      height: 20px;
      object-fit: cover;
      object-position: center center;
      border-radius: 50%;
      margin-right: 16px;
    }

    .name {
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.01em;
      color: #111111;
    }
  }
`

const NameCourse = styled.td`
  .label {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: #697077;
  }

  .name {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.01em;
    color: #111111;
  }
`

export {
  HomeWorkItemWrap,
  LeftTimes,
  TeacherCourse,
  NameCourse
}
